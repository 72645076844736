import Vue from 'vue';
import VueRouter from 'vue-router';

// import Analytics from '@views/dashboard/Analytics.vue';
import Insights from '@views/dashboard/Insights.vue';
import Dashboard from '@views/Dashboard.vue';
import firebase from '@database';
// import store from '../store';
/// /////////////////////////////////////////////////////////////
//
// >   V I E W S      V I E W S      V I E W S
//
/// /////////////////////////////////////////////////////////////
import Landing from '@views/Landing.vue';
import Login from '@views/authentication/Login.vue';
import ForgotPassword from '@views/authentication/ForgotPassword.vue';
import Register from '@views/authentication/Register.vue';
import TwoFactor from '@views/authentication/TwoFactor.vue';
import Projects from '@views/Projects.vue';
import ProjectsLayout from '@views/project-views/ProjectsLayout.vue';
import Project from '@views/Project.vue';
import Redirect from '@views/Redirect.vue';
import Images from '@views/Images.vue';
import Settings from '@views/Settings.vue';
import Company from '@views/Company.vue';
import EditProject from '@views/EditProject.vue';
import Billing from '@views/Billing.vue';
import Checkout from '@views/Checkout.vue';
import Help from '@views/Help.vue';
import PurchaseImagePlan from '@views/image-views/PurchaseImagePlan.vue';
import DashboardAccessPaused from '@views/analytics-views/DashboardAccessPaused.vue';
import ImageProcessPlanPaused from '@views/image_tabs/ImageProcessPlanPaused.vue';
import NoImages from '@views/NoImages.vue';
import Admin from '@views/admin/Admin.vue';
import NoAdminAccess from '@views/admin/NoAdminAccess.vue';
import Instances from '@views/admin/Instances.vue';

/// /////////////////////////////////////////////////////////////
//
// >   C H I L D  V I E W S      C H I L D  V I E W S
//
/// /////////////////////////////////////////////////////////////

import UserSettings from '@views/settings_tabs/UserSettings.vue';
import ResetPassword from '@views/settings_tabs/ResetPassword.vue';
import Employees from '@views/company_tabs/Employees.vue';
import Teams from '@views/company_tabs/Teams.vue';
import Faqs from '@views/help_tabs/Faqs.vue';
import ReportForm from '@views/help_tabs/ReportForm.vue';
import OriginalImages from '@views/image_tabs/OriginalImages.vue';
import ProcessedImages from '@views/image_tabs/ProcessedImages.vue';
import NoProcessedImages from '@views/image_tabs/NoProcessedImages.vue';
import NfInsights from '@views/dashboard/newfoundland/NfInsights.vue';
// import AnalyticsAccess from '@views/analytics-views/AnalyticsAccess.vue';
// import DashboardAccess from '@views/analytics-views/dashboard-settings/DashboardAccess.vue';
import NoDashboardAccess from '@views/analytics-views/NoDashboardAccess.vue';
// import AnalyticsPermissions from
// '@views/analytics-views/dashboard-settings/AnalyticsPermissions.vue';
import BillingOverview from '@views/billing_views/BillingOverview.vue';
import BillingReports from '@views/billing_views/BillingReports.vue';
import SubscriptionPlans from '@views/billing_views/SubscriptionPlans.vue';
import SwitchPlans from '@views/billing_views/subscription_plans/SwitchPlans.vue';
import PlanAccess from '@views/billing_views/subscription_plans/PlanAccess.vue';
import PaymentMethods from '@views/billing_views/PaymentMethods.vue';
import Invoices from '@views/billing_views/Invoices.vue';

import NoLocationImageList from '@components/images/map/NoLocationImageList.vue';
import EditImageForm from '@components/images/EditImageForm.vue';
import NoProcessingImageAccess from '@views/image_tabs/NoProcessingImageAccess.vue';
import ImagePlanSelect from '@views/billing_views/ImagePlanSelect.vue';
import HumanInTheLoop from '@views/annotations/HumanInTheLoop.vue';

import VerificationMethods from '@views/forgot-password/VerificationMethods.vue';
import VerifyUser from '@views/forgot-password/VerifyUser.vue';
import ResetPasswordForm from '@views/forgot-password/ResetPasswordForm.vue';
import PasswordResetComplete from '@views/forgot-password/PasswordResetComplete.vue';

/// /////////////////////////////////////////////////////////////
//
// >   V A L I D A T I O N S      V A L I D A T I O N S
//
/// /////////////////////////////////////////////////////////////
import validateProcessImagePlan from '../validation/access/processPlans';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  /*
    |-------------------------------------------------------|
    |                 G U E S T  R O U T E S                |
    |-------------------------------------------------------|
  */
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    meta: {
      requireGuest: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requireGuest: true,
    },
  },
  {
    path: '/register/:uid',
    name: 'Register',
    component: Register,
    meta: {
      requireGuest: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      requireGuest: true,
    },
    children: [
      {
        path: '/forgot-password/verification-select/:uid',
        name: 'VerificationMethods',
        component: VerificationMethods,
        meta: {
          requireGuest: true,
        },
        props: true,
      },
      {
        path: 'verify-user/:uid/:type',
        name: 'VerifyUser',
        component: VerifyUser,
        meta: {
          requireGuest: true,
        },
        props: true,
      },
      {
        path: '/forgot-password/reset/:uid',
        name: 'ResetPasswordForm',
        component: ResetPasswordForm,
        meta: {
          requireGuest: true,
        },
        props: true,
      },
      {
        path: '/forgot-password/complete',
        name: 'PasswordResetComplete',
        component: PasswordResetComplete,
        meta: {
          requireGuest: true,
        },
      },
    ],
    props: true,
  },
  /*
    |-------------------------------------------------------|
    |          F I R S T  F A C T O R  R O U T E S          |
    |-------------------------------------------------------|
  */
  {
    path: '/two-factor/:uid',
    name: 'TwoFactor',
    component: TwoFactor,
    meta: {
      requireAuth: true,
    },
  },
  /*
    |-------------------------------------------------------|
    |       S E C O N D  F A C T O R   R O U T E S          |
    |-------------------------------------------------------|
  */
  {
    path: '/projects/view/',
    name: 'Projects',
    component: Projects,
    props: true,
    meta: {
      requireTwoFactor: true,
    },
    children: [
      {
        path: '/projects/view/:view',
        name: 'ProjectsLayout',
        component: ProjectsLayout,
        meta: {
          requireTwoFactor: true,
        },
        props: true,
      },
    ],
  },
  {
    path: '/project/:pid',
    name: 'Project',
    component: Project,
    meta: {
      requireTwoFactor: true,
    },
    props: true,
  },
  {
    path: '/redirect/:pid',
    name: 'Redirect',
    component: Redirect,
    props: true,
  },
  {
    path: '/project/:pid/edit',
    name: 'EditProject',
    component: EditProject,
    meta: {
      requireTwoFactor: true,
    },
    props: true,
  },
  {
    path: '/settings/user/:uid/profile',
    name: 'Settings',
    component: Settings,
    meta: {
      requireTwoFactor: true,
    },
    props: true,
    children: [
      {
        path: '/settings/user/:uid/profile',
        name: 'UserSettings',
        component: UserSettings,
        meta: {
          requireTwoFactor: true,
        },
        props: true,
      },
      {
        path: '/settings/user/:uid/reset',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
          requireTwoFactor: true,
        },
        props: true,
      },
    ],
  },
  {
    path: '/company/:cid/employees',
    name: 'Company',
    component: Company,
    meta: {
      requireTwoFactor: true,
    },
    props: true,
    children: [
      {
        path: '/company/:cid/employees',
        name: 'Employees',
        component: Employees,
        meta: {
          requireTwoFactor: true,
        },
        props: true,
      },
      {
        path: '/company/:cid/teams',
        name: 'Teams',
        component: Teams,
        meta: {
          requireTwoFactor: true,
        },
        props: true,
      },
    ],
  },
  {
    path: '/images/ai/:subId/paused',
    name: 'ImageProcessPlanPaused',
    component: ImageProcessPlanPaused,
    meta: {
      requireTwoFactor: true,
    },
    props: true,
  },
  {
    path: '/project/:pid/images/original/no-images',
    name: 'NoImages',
    component: NoImages,
    meta: {
      requireTwoFactor: true,
    },
    props: true,
  },
  {
    path: '/project/:pid/images/:tab/:filename',
    name: 'Images',
    component: Images,
    meta: {
      requireTwoFactor: true,
    },
    props: true,
    children: [
      {
        path: '/project/:pid/images/:tab/:filename',
        name: 'OriginalImages',
        component: OriginalImages,
        meta: {
          requireTwoFactor: true,
        },
        props: true,
        children: [
          {
            path: '/project/:pid/images/:tab/:filename/:date/annotate',
            name: 'HumanInTheLoop',
            component: HumanInTheLoop,
            meta: {
              requireTwoFactor: true,
            },
            props: true,
          },
        ],
      },
      {
        path: '/project/:pid/images/ai/no-processed-images/processed',
        name: 'NoProcessedImages',
        component: NoProcessedImages,
        meta: {
          requireTwoFactor: true,
        },
        props: true,
      },
      {
        path: '/project/:pid/images/:tab/:filename/processed',
        name: 'ProcessedImages',
        component: ProcessedImages,
        meta: {
          requireTwoFactor: true,
          requireProcessPlan: true,
        },
        props: true,
        children: [
          {
            path: '/project/:pid/images/ai/add_locations',
            name: 'NoLocationImageList',
            component: NoLocationImageList,
            meta: {
              requireTwoFactor: true,
            },
            props: true,
          },
          {
            path: '/project/:pid/images/ai/add_locations/:id',
            name: 'EditImageForm',
            component: EditImageForm,
            meta: {
              requireTwoFactor: true,
            },
            props: true,
          },
          {
            path: '/project/:pid/images/:tab/:filename/:date/processed/edit',
            name: 'HumanInTheLoopEdit',
            component: HumanInTheLoop,
            meta: {
              requireTwoFactor: true,
              editMode: true,
            },
            props: true,
          },
        ],
      },
      {
        path: '/project/:pid/images/ai/no-access',
        name: 'NoProcessingImageAccess',
        component: NoProcessingImageAccess,
        meta: {
          requireTwoFactor: true,
        },
      },
    ],
  },
  {
    path: '/image-plan/:planName/:productId',
    name: 'PurchaseImagePlan',
    component: PurchaseImagePlan,
    meta: {
      requireTwoFactor: true,
    },
    props: true,
  },
  // {
  //   path: '/analytics/nf',
  //   name: 'NfAnalytics',
  //   component: NfAnalytics,
  //   meta: {
  //     requireTwoFactor: true,
  //   },
  //   children: [
  //     {
  //       path: '/analytics/nf/insights/:pid',
  //       name: 'NfInsights',
  //       component: NfInsights,
  //       meta: {
  //         requireTwoFactor: true,
  //       },
  //       props: true,
  //     },
  //   ],
  // },
  {
    path: '/analytics',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requireTwoFactor: true,
    },
    children: [
      {
        path: '/analytics/access/paused',
        name: 'DashboardAccessPaused',
        component: DashboardAccessPaused,
        meta: {
          requireTwoFactor: true,
        },
      },
      {
        path: '/analytics/no-access',
        name: 'NoDashboardAccess',
        component: NoDashboardAccess,
        meta: {
          requireTwoFactor: true,
        },
      },
      {
        path: '/analytics/insights/:pid',
        name: 'Insights',
        component: Insights,
        meta: {
          requireTwoFactor: true,
        },
        props: true,
      },
      {
        path: '/analytics/nf/insights/:pid',
        name: 'NfInsights',
        component: NfInsights,
        meta: {
          requireTwoFactor: true,
        },
        props: true,
      },
      // {
      //   path: '/analytics/insights/:pid',
      //   name: 'Insights',
      //   component: Insights,
      //   meta: {
      //     requireTwoFactor: true,
      //   },
      //   props: true,
      // },
      // {
      //   path: '/analytics/settings',
      //   name: 'AnalyticsAccess',
      //   component: AnalyticsAccess,
      //   meta: {
      //     requireTwoFactor: true,
      //   },
      //   children: [
      //     {
      //       path: '/analytics/settings/permissions',
      //       name: 'AnalyticsPermissions',
      //       component: AnalyticsPermissions,
      //       meta: {
      //         requireTwoFactor: true,
      //       },
      //     },
      //     {
      //       path: '/analytics/settings/access',
      //       name: 'DashboardAccess',
      //       component: DashboardAccess,
      //       meta: {
      //         requireTwoFactor: true,
      //       },
      //     },
      //   ],
      // },
      {
        path: '/analytics/insights/:pid/annotate/:image',
        name: 'HumanInTheLoopAnalytics',
        component: HumanInTheLoop,
        meta: {
          requireTwoFactor: true,
          editMode: true,
        },
        props: true,
      },
    ],
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing,
    children: [
      {
        path: '/billing/subscription-plans/access',
        name: 'SubscriptionPlans',
        component: SubscriptionPlans,
        children: [
          {
            path: '/billing/subscription-plans/modify',
            name: 'SwitchPlans',
            component: SwitchPlans,
          },
          {
            path: '/billing/subscription-plans/access',
            name: 'PlanAccess',
            component: PlanAccess,
          },
        ],
        beforeEnter: async (to, from, next) => {
          const subscription = await validateProcessImagePlan(firebase.auth().currentUser.uid);
          const { type } = subscription;
          if (type === 'error') {
            next({
              path: '/billing/subscription-plans/select',
              query: {
                redirect: to.fullPath,
              },
            });
          } else next();
        },
      },
      {
        path: '/billing/subscription-plans/select',
        name: 'ImagePlanSelect',
        component: ImagePlanSelect,
        meta: {
          requireTwoFactor: true,
        },
      },
      {
        path: '/billing/overview',
        name: 'BillingOverview',
        component: BillingOverview,
      },
      {
        path: '/billing/reports',
        name: 'BillingReports',
        component: BillingReports,
      },
      {
        path: '/billing/payment_methods',
        name: 'PaymentMethods',
        component: PaymentMethods,
      },
      {
        path: '/billing/invoices',
        name: 'Invoices',
        component: Invoices,
      },
    ],
    meta: {
      requireTwoFactor: true,
    },
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
    meta: {
      requireTwoFactor: true,
    },
    children: [
      {
        path: '/help/report',
        name: 'Report Issue',
        component: ReportForm,
        meta: {
          requireTwoFactor: true,
        },
      },
      {
        path: '/help/faqs',
        name: 'FAQs',
        component: Faqs,
        meta: {
          requireTwoFactor: true,
        },
      },
    ],
  },
  {
    path: '/checkout/:productId',
    name: 'Checkout',
    component: Checkout,
    meta: {
      requireTwoFactor: true,
    },
    children: [],
    props: true,
  },
  {
    path: '/admin/instances',
    name: 'Admin',
    component: Admin,
    meta: { requireTwoFactor: true },
    children: [
      {
        path: '/admin/instances',
        name: 'Instances',
        component: Instances,
        meta: { requireTwoFactor: true },
      },
      {
        path: '/admin/no-access',
        name: 'NoAdminAccess',
        component: NoAdminAccess,
        meta: { requireTwoFactor: true },
      },
    ],
  },
];

// New Vue Router Instance
const router = new VueRouter({ routes });

/*
  |-------------------------------------------------------|
  |           N A V I G A T I O N  G U A R D S            |
  |-------------------------------------------------------|
*/
router.beforeEach(async (to, from, next) => {
  const firstFactor = firebase.auth().currentUser;
  const secondFactor = localStorage.jwtToken;
  if (to.matched.some((record) => record.meta.requireTwoFactor)) {
    if (!secondFactor) {
      next({
        path: `/two-factor/${firebase.auth().currentUser.uid}`,
        query: {
          ...to.query,
          redirect: to.fullPath,
        },
      });
    } else if (!firstFactor) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requireAuth)) {
    if (firstFactor && secondFactor) {
      next({
        path: '/projects/view/grid',
        query: {
          redirect: to.fullPath,
        },
      });
    } else if (!firstFactor) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requireGuest)) {
    if (firstFactor && secondFactor) {
      next({
        path: '/projects/view/grid',
        query: {
          redirect: to.fullPath,
        },
      });
    } else if (firstFactor) {
      next({
        path: `/two-factor/${firebase.auth().currentUser.uid}`,
        query: {
          ...to.query,
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
