import axios from 'axios';
import { baseURL } from '@utils/endpoint';
import firebase from '@database';

export default {
  /*
    |-------------------------------------------|
    |   @method       getJwtToken               |
    |   @params       uid <String>              |
    |   @return       Promise <JwtToken>        |
    |   @descrtipion  Generates a Jwt Web token |
    |                 based on the user id that |
    |                 is provided by Firebase   |
    |                 Authentication            |
    |-------------------------------------------|
  */
  getJwtToken: (uid) => new Promise((resolve, reject) => {
    axios.get(`${baseURL}/api/auth/token/jwt/${uid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  }),

  getFirebaseUser: (query) => new Promise((resolve, reject) => {
    axios.get(`${baseURL}/api/user/?${query}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  }),

  getCustomToken: (uid) => new Promise((resolve, reject) => {
    axios.get(`${baseURL}/api/auth/firebase/custom-token/user/${uid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  }),

  customTokenAuthenticate: (token) => new Promise((resolve, reject) => {
    firebase.auth().signInWithCustomToken(token)
      .then(() => resolve())
      .catch((err) => reject(err));
  }),
};
