<template>
    <v-container fluid>
      <div class="headline text-center" color="primary">
        There are no images within this project. Upload your first images to get started.
      </div>
      <DirectImageUploader :pid="pid" :cid="currentProject.cid" v-if="directUploader"/>
      <div id="epri-uploader" v-if="companyHas('epriUploader')">
        <EPRIInputFolderUploader
        v-if="eNFUploader" :cid="currentUser.cid" :pid="currentProject.pid" />
        <v-btn
        color="primary"
        block
        @click="showENFUploader"
        v-if="!uploadingImages"
        class="mx-2 mt-5"
        id="upload-btn"
      >
        {{epriToggleButtonText}}
        <v-icon right dark>
          {{ bIcon }}
        </v-icon>
      </v-btn>
      </div>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DirectImageUploader from '@components/images/utilities/DirectImageUploader.vue';
import EPRIInputFolderUploader from '@components/images/utilities/EPRIInputFolderUploader.vue';

export default {
  name: 'NoImages',
  props: ['pid', 'tab'],
  components: {
    DirectImageUploader,
    EPRIInputFolderUploader,
  },
  data: () => ({
    class: [
      'h2',
      'There are no images within this project. Please upload your first images',
      '3.75rem',
      '300',
      '-0.03125.rem',
      0,
    ],
    noProcessedImages: false,
    eNFUploader: false,
    directUploader: true,
  }),
  methods: {
    ...mapActions(['getImagesByProject', 'getProject']),
    showENFUploader() {
      this.eNFUploader = !this.eNFUploader;
      this.directUploader = !this.eNFUploader;
    },
  },
  computed: {
    ...mapGetters(['allImages',
      'currentProject',
      'uploadingImages',
      'currentUser',
      'processingImage',
      'currentCompany',
      'companyHas',
    ]),
    epriToggleButtonText() {
      return this.eNFUploader ? 'Go Back' : 'Upload Images & Metadata';
    },
    bIcon() {
      return this.eNFUploader ? 'mdi-arrow-left-thick' : 'cloud_upload';
    },
  },
  watch: {
    allImages(newImageList, oldImageList) {
      const initialAmount = oldImageList.length;
      const newAmount = newImageList.length;
      // console.log('initialAmount: ', initialAmount, ' newAmount: ', newAmount);

      if (initialAmount === 0 && newAmount === 0) return;

      // If the initial amount is more than 0 and we're still on the no images page,
      // then reroute to the images path
      if (initialAmount > 0) this.$router.push({ path: `/project/${this.pid}/images/original/${newImageList[0].filename}` });

      // If the new amount is now more than 0,
      // then reroute the images path
      if (newAmount > 0) this.$router.push({ path: `/project/${this.pid}/images/original/${newImageList[0].filename}` });
    },
  },
  async created() {
    if (!this.currentProject.pid) {
      await this.getProject(this.pid);
    }
    let initialAmount;
    if (this.allImages.length === 0) {
      const images = await this.getImagesByProject({
        pid: this.currentProject.pid, cid: this.currentCompany.cid,
      });
      initialAmount = images.length;
    } else {
      initialAmount = this.allImages.length;
    }

    if (initialAmount > 0) this.$router.push({ path: `/project/${this.pid}/images/original/${this.allImages[0].filename}` });
  },
};
</script>
