import axios from 'axios';
import { baseURL } from '@utils/endpoint';

export default {
  update: (id, updates) => new Promise((resolve, reject) => {
    axios.put(`${baseURL}/api/companies/${id}`, updates)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
};
