import axios from 'axios';
import { baseProcessUrl } from '@utils/endpoint';

export default {
  process: (processData, options) => new Promise((resolve, reject) => {
    axios.post(`${baseProcessUrl}/process`, processData, options)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),

  batch_process: (processData, options) => new Promise((resolve, reject) => {
    axios.post(`${baseProcessUrl}/batch_process/predict`, processData, options)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
};
