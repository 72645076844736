const state = {
  index: 0,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  currentCarouselIndex: (state) => state.index,
};

const actions = {
  setCarouselIndex({ commit }, index) {
    commit('set_index', index);
  },
};

const mutations = {
  set_index: (state, index) => {
    state.index = index;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
