<template>
  <v-container fluid>
    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr v-for="(imageDetail, key) in imageDetails" :key="key">
              <td><span class="font-weight-medium">
                {{ formatString(key) }}
              </span></td>
              <td v-if="key === 'pole_name'"><span>{{ imageDetail }}</span></td>
              <td v-else-if="key === 'file_name'"><span>{{ imageDetail }}</span></td>
              <td v-else-if="key === 'caption'"><ImageCaption :type="type" :key="imageDetail"/></td>
              <td v-else-if="key === 'date_uploaded'"><span>{{ imageDetail }}</span></td>
              <td v-else-if="key === 'date_inspected'"><span>{{ imageDetail }}</span></td>
              <td v-else-if="key === 'latitude'">
                <ImageLocation
                  coordinateType="latitude"
                  :coordinate="imageDetail"
                  :key="imageDetail"
                />
              </td>
              <td v-else-if="key === 'longitude'">
                <ImageLocation
                  coordinateType="longitude"
                  :coordinate="imageDetail"
                  :key="imageDetail"
                />
              </td>
              <td v-else-if="key === 'severity'">
                <ImageSeverity :severity="imageDetail" />
              </td>
              <td v-else-if="key === 'time_logs'">
                <ImageTimeLogs
                  :totalTime="imageDetail.totalTime"
                  :timeLogs="imageDetail.timeLogs"
                  key="time_logs"
                />
              </td>
              <td v-else-if="key === 'pole_review_time'">
                <ImageTimeLogs
                  :totalTime="imageDetail.totalTime"
                  :timeLogs="imageDetail.timeLogs"
                  key="pole_review_time"
                />
              </td>
              <td v-else-if="key === 'field_inspection'">
                <PoleFieldInspection :folder="imageDetails" />
              </td>
              <td v-else-if="key === 'clustering_uncertainty'">
                <ClusteringUncertainty :folder="imageDetails" />
              </td>
              <td v-else-if="key === 'classes'">
                <ImageClasses :classes="imageDetail" />
              </td>
              <td v-else-if="key === 'priority'">
                <ImagePriority :priority="imageDetail" />
              </td>
              <td v-else-if="key === 'reviewed_image'">
                <ImageReviewed :reviewed="imageDetail"/>
              </td>
              <td v-else-if="key === 'overall_pole_priority'">
                <FolderOverallPriority :folder="imageDetail"/>
              </td>
              <td v-else-if="key === 'work_order'">
                <StructureWorkOrder :workOrder="imageDetail"/>
              </td>
              <td v-else-if="key === 'first_reviewer'">
                <FirstReviewer :project="imageDetail" />
              </td>
              <td v-else-if="key === 'second_reviewer'">
                <SecondReviewer :folder="imageDetail" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          :color="(editingImage) ? 'error' : 'primary'"
          small
          @click="editFields">
          {{ (editingImage) ? 'Close' : 'Edit' }}
        </v-btn>
        <v-btn
          text
          color="primary"
          small
          @click="save"
          :disabled="!editingFolder && !editingImage"
        >Complete Inspection</v-btn>
      </v-card-actions>
      <v-overlay :value="saving" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        <p>{{ sectionSaved }}</p>
      </v-overlay>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { upperFirst, find, cloneDeep } from 'lodash';
import ImageCaption from '@components/images/image_details/ImageCaption.vue';
import ImageLocation from '@components/images/image_details/ImageLocation.vue';
import ImageTimeLogs from '@components/images/image_details/ImageTimeLogs.vue';
import ImageClasses from '@components/images/image_details/ImageClasses.vue';
import ImageReviewed from '@components/images/image_details/ImageReviewed.vue';
import ImageSeverity from '@components/images/image_details/ImageSeverity.vue';
import ImagePriority from '@components/images/image_details/ImagePriority.vue';
import PoleFieldInspection from '@components/images/image_details/PoleFieldInspection.vue';
import ClusteringUncertainty from '@components/images/image_details/ClusteringUncertainty.vue';
import FolderOverallPriority from '@components/images/image_details/FolderOverallPriority.vue';
import FirstReviewer from '@components/images/image_details/reviewers/FirstReviewer.vue';
import SecondReviewer from '@components/images/image_details/reviewers/SecondReviewer.vue';
import StructureWorkOrder from '@components/images/image_details/StructureWorkOrder.vue';
import time from '@utils/time';
import helpers, { toDMS, deepCompare } from '@methods/helpers';

const alaramSeverity = ['TD-1', 'Priority 1', 'Emergency'];

export default {
  name: 'ImageDetails2',
  props: ['index', 'type'],
  components: {
    ImageCaption,
    ImageLocation,
    ImageTimeLogs,
    ImageClasses,
    ImageSeverity,
    ImageReviewed,
    ImagePriority,
    FolderOverallPriority,
    PoleFieldInspection,
    FirstReviewer,
    SecondReviewer,
    ClusteringUncertainty,
    StructureWorkOrder,
  },
  data: () => ({
    saving: false,
    sectionSaved: '',
    intiialReviewer: {
      assigner_uid: '',
      name: '',
      uid: '',
      email: '',
      reviewed: false,
      reason: '',
    },
    initialFirstReviewer: {
      assigner_uid: '',
      email: '',
      name: '',
      phone: '',
      uid: '',
    },
  }),
  methods: {
    ...mapActions([
      'updateFolder',
      'editImageFields',
      'editFolderFields',
      'editProjectFields',
      'updateImage',
      'setModifiedImage',
      'sendEmailNotification',
      'clearSecondReviewerForm',
      'setSecondReviewer',
      'updateProject',
      'clearFirstReviewerForm',
      'setFirstReviewer',
    ]),
    formatString(label) {
      if (label === 'overall_pole_priority' && this.companyHas('structurePriority')) {
        return 'Structure Priority';
      }

      return upperFirst(label).split('_').join(' ');
    },
    secondsToTime: (secs) => time.secondsToTime(secs),
    getFolder: (folders, image) => find(folders, (folder) => folder.path === image.folder),
    editFields() {
      this.editImageFields(!this.editingImage);
      this.editFolderFields(!this.editingFolder);
      this.editProjectFields(!this.editingProject);
      if (this.secondReviewerCleared) this.clearSecondReviewerForm(false);
    },
    reviewerCleared(reviewer) {
      // Get all keys of the reviewer object
      const reviewerInfo = Object.keys(reviewer);

      // Compute the current reviewer value with the intiial reviewer value and see if
      // it still matches. If all matches, then it that means the user has cleared
      // the reviewer form and saved
      const cleared = reviewerInfo.filter((field) => field !== 'reviewed').reduce((acc, curr) => {
        let clearedField = false;

        // Instead we want to check if it has an assigner_uid, email, reason, uid,
        // and name assigned to it
        const isStringFields = curr === 'assigner_uid' || curr === 'email' || curr === 'reason' || curr === 'uid' || curr === 'name';

        // If that is the case then clearedField should be marked as true
        if (isStringFields && (reviewer[curr] === '')) clearedField = true;

        // Return if the previous result with the current one (clearedField)
        // remain true
        return acc && clearedField;
      });

      return cleared;
    },
    async saveProject() {
      const project = cloneDeep(this.currentProject);
      const firstReviewer = cloneDeep(this.firstReviewer);
      project.first_reviewer = deepCompare(firstReviewer, this.intiialFirstReviewer)
        ? {} : firstReviewer;
      const response = await this.updateProject({ pid: project.pid, updates: project });

      if (this.firstReviewerCleared) this.clearFirstReviewerForm(true);

      if (response.status.code === 'SUCCESS') {
        response.status = 200;
        this.setFirstReviewer(this.initialFirstReviewer);
      }

      return response;
    },
    async saveFolder() {
      const secondReviewer = cloneDeep(this.secondReviewer);
      const currentFolder = this.getFolder(this.allFolders, this.currentImage);
      const folder = cloneDeep(currentFolder);

      if (this.companyHas('overallSeverity')) {
        folder.overall_severity = this.overallPriority;
      }

      if (this.companyHas('clustering_uncertainty')) {
        folder.clustering_uncertainty = this.clusteringUncertainty;
      }

      if (this.companyHas('inFieldPoleVerification')) {
        folder.field_inspection = this.fieldInspection;
      }

      if (this.companyHas('work_order_field')) {
        folder.work_order = this.workOrder;
      }

      // If user cleared the second reviewer, then just save an empty object to Datastore
      if (this.companyHas('second_reviewer')) {
        folder.second_reviewer = deepCompare(secondReviewer, this.intiialReviewer)
          ? {} : secondReviewer;
      }

      if (!folder.complete_inspection) folder.complete_inspection = true;

      const payload = {
        pid: folder.project,
        cid: folder.company,
        path: folder.path,
        updates: folder,
      };
      const response = await this.updateFolder(payload);
      if (this.secondReviewerCleared) this.clearSecondReviewerForm(false);
      this.setSecondReviewer(this.intiialReviewer);
      // eslint-disable-next-line consistent-return
      return response;
    },
    async save() {
      this.saving = true;
      this.sectionSaved = 'Saving changes to image...';
      const updatedImage = cloneDeep(this.currentImage);
      const processTracking = updatedImage.process_tracking;

      const payload = {
        companyId: this.currentImage.companyId,
        project_id: this.currentImage.project_id,
        date: this.currentImage.date,
        filename: this.currentImage.filename,
        update: {},
      };

      if (this.type === 'ai') {
        processTracking[processTracking.length - 1].caption = this.modifiedImage.caption;
        updatedImage.process_tracking = processTracking;
      } else {
        updatedImage.caption = this.modifiedImage.caption;
      }

      const { length } = processTracking;

      if (length === 0) {
        processTracking.push({});
      }

      if (!processTracking[processTracking.length - 1]) {
        processTracking[processTracking.length - 1] = {};
      }

      // severity
      processTracking[processTracking.length - 1].severity = this.modifiedImage.severity;

      // Check if the image has location data
      if (updatedImage.location[0] && updatedImage.location[1]) {
        const coordinates = {
          lat: updatedImage.location[0] === null ? 0 : updatedImage.location[0],
          long: updatedImage.location[1] === null ? 0 : updatedImage.location[1],
        };

        let modus;
        let position;

        const coordinateTypes = ['latitude', 'longitude'];

        coordinateTypes.forEach((item) => {
          switch (item) {
            case 'longitude':
              // Missing either W or S should give a negative modus
              modus = Math.sign(this.modifiedImage[item].toString().toUpperCase().search('W') * this.modifiedImage[item].toString().toUpperCase().search('S'));
              position = parseFloat(this.modifiedImage[item]) * modus;
              coordinates.long = Number.isNaN(position) ? coordinates.long : position;
              updatedImage.location[1] = this.modifiedImage[item];
              break;
            case 'latitude':
              modus = Math.sign(this.modifiedImage[item].toString().toUpperCase().search('W') * this.modifiedImage[item].toString().toUpperCase().search('S'));
              position = parseFloat(this.modifiedImage[item]) * modus;
              coordinates.lat = Number.isNaN(position) ? coordinates.lat : position;
              updatedImage.location[0] = this.modifiedImage[item];
              break;
            default:
              break;
          }
        });
      }
      // reviewed status
      updatedImage.reviewed = this.modifiedImage.reviewed;

      payload.update = updatedImage;

      const originalFolder = this.getFolder(this.allFolders, this.currentImage);

      const response = await this.updateImage(payload);

      if (response.status === 200) {
        this.setModifiedImage({ type: 'caption', value: '' });
        this.sectionSaved = 'Saving changes to folder...';
        let folderResponse;

        if (originalFolder) {
          folderResponse = await this.saveFolder();
        } else folderResponse = { status: 200 };
        const poleUrl = window.location.href;
        const projectUrl = poleUrl.substring(0, poleUrl.indexOf('/images'));
        if (folderResponse.status === 200) {
          if (process.env.NODE_ENV === 'production' && this.companyConfig('customEmailAlert') && alaramSeverity.includes(this.overallPriority)) {
            this.sendEmailNotification({
              data: {
                recipient: this.companyConfig('customEmailAlert'),
                pole: originalFolder.path,
                assigner: this.currentUser.name,
                project: this.currentProject.name,
                poleUrl,
                projectUrl,
              },
              type: 'add_reviewer',
            });
          }

          // send email to notify first reviewer
          // eslint-disable-next-line max-len
          const firstReviewerChanged = this.currentProject?.first_reviewer?.uid !== this.firstReviewer?.uid;
          if (firstReviewerChanged) {
            const emailNotification = {
              data: {
                recipient: this.firstReviewer.email,
                pole: originalFolder.path,
                assigner: this.currentUser.name,
                project: this.currentProject.name,
                poleUrl,
                projectUrl,
              },
              type: 'add_reviewer',
            };
            this.sendEmailNotification(emailNotification);
          }
          if (this.companyHas('second_reviewer') && originalFolder) {
            const originalSecondReviewer = originalFolder.second_reviewer.name;
            const modifiedSecondReviewer = this.modifiedFolder.second_reviewer.name;
            const reviewerChanged = originalSecondReviewer !== modifiedSecondReviewer;

            if (reviewerChanged && !this.secondReviewerCleared) {
              const emailNotification = {
                data: {
                  recipient: folderResponse.data.second_reviewer.email,
                  pole: originalFolder.path,
                  assigner: this.currentUser.name,
                  project: this.currentProject.name,
                  poleUrl,
                  projectUrl,
                },
                type: 'add_reviewer',
              };

              if (!this.secondReviewerCleared && this.reviewerChangeDetected) {
                if (process.env.NODE_ENV === 'production') this.sendEmailNotification(emailNotification);
              }
            }
          }

          this.sectionSaved = 'Saving changes to project...';
          const projectResponse = await this.saveProject();
          if (projectResponse.status === 200) this.sectionSaved = 'Saving Complete!';
        }
        setTimeout(() => {
          this.sectionSaved = '';
          this.saving = false;
        }, 1000);
        this.editImageFields(false);
        this.editFolderFields(false);
        this.editProjectFields(false);
        this.$eventBus.$emit('map:updateImage', updatedImage);
      }
    },
    folderTimeLogs(image) {
      const { folder } = image;
      let totalTime = 0;
      let timeLogs = [];
      if (!folder) {
        if (image.time_logs !== null && image.time_logs !== undefined) {
          timeLogs = image.time_logs;
        }

        totalTime = this.secondsToTime(timeLogs.reduce((p, c) => c.timeSpent + p, 0));
        return { totalTime, timeLogs };
      }

      const folderImages = this.allImages.filter((img) => img.folder === folder);
      const aggregateTimeLogs = {};
      let aggregatedTime = 0;
      folderImages.forEach((img) => {
        let currentTimeLogs = [];
        if (img.time_logs !== null && img.time_logs !== undefined) {
          currentTimeLogs = img.time_logs;
        }
        aggregatedTime += currentTimeLogs.reduce((p, c) => c.timeSpent + p, 0);
        currentTimeLogs.forEach((c) => {
          if (aggregateTimeLogs[c.userId]) {
            aggregateTimeLogs[c.userId].timeSpent += c.timeSpent;
          } else {
            aggregateTimeLogs[c.userId] = { ...c };
          }
        });
      });
      totalTime = this.secondsToTime(aggregatedTime);
      timeLogs = Object.values(aggregateTimeLogs);
      return { totalTime, timeLogs };
    },
  },
  computed: {
    ...mapGetters([
      'allImages',
      'currentImage',
      'currentFolder',
      'currentProject',
      'companyHas',
      'currentUser',
      'allFolders',
      'editingImage',
      'editingFolder',
      'editingProject',
      'modifiedImage',
      'modifiedFolder',
      'secondReviewerCleared',
      'firstReviewerCleared',
      'overallPriority',
      'fieldInspection',
      'clusteringUncertainty',
      'secondReviewer',
      'firstReviewer',
      'workOrder',
      'companyConfig',
    ]),
    currentF() {
      const { folder } = this.currentImage;
      const currentFolder = find(this.allFolders, (f) => f.path === folder);
      return currentFolder;
    },
    imageDetails() {
      const image = { ...this.currentImage };
      const { location } = image;
      let currentFolder;

      if (this.companyHas('poleByFolders') || this.companyHas('general_folders')) {
        const { folder } = this.currentImage;
        currentFolder = find(this.allFolders, (f) => f.path === folder);
      }

      // Declare filename variable to be modifiable
      let filename;

      // If filename includes a '+' then split the string
      // and take the first index of it to get the actual filename
      filename = (image.filename.includes('+')) ? image.filename.split('+')[1] : image.filename;

      // If filename includes a '!' then the file is a part of a sub folder.
      // Replace the '!' with ' > '
      if (filename.includes('!')) filename = filename.replace('!', ' > ');
      let details = {};

      details.pole_name = image.folder;
      let dateInspected;
      if (image.timestamp) dateInspected = helpers.moment(image.timestamp).format('dddd, MMMM Do YYYY, h:mm:ss a');
      else dateInspected = null;
      details = {
        ...details,
        file_name: filename,
        caption: image.caption,
        date_uploaded: helpers.convertToDate(image.date).format('dddd, MMMM Do YYYY, h:mm:ss a'),
        latitude: (location) ? toDMS(location[0], 'latitude') : 'N/A',
        longitude: (location) ? toDMS(location[1], 'longitude') : 'N/A',
        date_inspected: dateInspected,
      };

      if (this.companyHas('inFieldPoleVerification') && this.type === 'ai' && currentFolder !== undefined) {
        details.field_inspection = currentFolder.field_inspection;
      }

      if (this.companyHas('poleByFolders') && this.currentFolder.name !== '__all__') {
        details.clustering_uncertainty = this.clusteringUncertainty;
      }

      if (this.type === 'ai') {
        // if the company has timelogs feature enabled
        if (this.companyHas('image_severity')) {
          const processedImage = image.process_tracking[image.process_tracking.length - 1];
          details.severity = processedImage.severity;
        }

        if (this.companyHas('work_order_field')) {
          details.work_order = currentFolder.work_order;
        }

        if (this.companyHas('image_review')) {
          details.reviewed_image = image.reviewed;
        }

        if (this.companyHas('timeLogs')) {
          let timeLogs = [];
          if (image.time_logs !== null && image.time_logs !== undefined) {
            timeLogs = image.time_logs;
          }

          const totalTime = this.secondsToTime(timeLogs.reduce((p, c) => c.timeSpent + p, 0));
          details.time_logs = { totalTime, timeLogs };
        }

        if (this.companyHas('folder_time_log') && currentFolder !== undefined) {
          details.pole_review_time = this.folderTimeLogs(image);
        }
        const firstReviewer = this.currentProject;
        details.first_reviewer = firstReviewer;

        // if process tracking exists
        if (image.process_tracking.length) {
          const processing = image.process_tracking.slice(-1)[0];
          details.caption = processing.caption;
          details.classes = processing.labels;

          // If the company has overallSeverity feature enabled
          if (this.companyHas('overallSeverity') && currentFolder !== undefined) {
            details.overall_pole_priority = currentFolder;
          }

          if (this.companyHas('reviewers') && currentFolder !== undefined) {
            details.second_reviewer = currentFolder;
          }
        }
      }
      return details;
    },
    imageType() {
      return (this.$route === 'ProcessedImage') ? 'ai' : 'original';
    },
    isUpdatingFolder() {
      const currentFolder = find(
        this.allFolders,
        (folder) => folder.path === this.currentImage.folder,
      );

      // Editable fields: overall_severity, field_inspection, reviewers
      const overallSeverity = currentFolder.overall_severity;
      const fieldInspection = currentFolder.field_inspection;
      const clusteringUncertainty = currentFolder.clustering_uncertainty;
      const { reviewers } = currentFolder.reviewers;

      return {
        overallSeverity,
        fieldInspection,
        clusteringUncertainty,
        reviewers,
      };
    },
    reviewerChangeDetected() {
      const currentFolder = find(
        this.allFolders,
        (folder) => folder.path === this.currentImage.folder,
      );

      return !deepCompare(currentFolder.second_reviewer, this.modifiedFolder.second_reviewer);
    },
  },
  destroyed() {
    this.editImageFields(false);
    this.editFolderFields(false);
    this.editProjectFields(false);
  },
};
</script>
