<template>
  <div>
    <v-row>
      <v-col cols="12" class="text-center buttons-row">
        <v-btn
          :color="uploadBtnDisplay.color"
          @click="showUploader"
          :disabled="processingImage || uploadingImages"
          class="mx-2"
          id="upload-btn"
        >
          {{uploadBtnDisplay.text}}
          <v-icon right dark>
            cloud_upload
          </v-icon>
        </v-btn>
        <v-btn
          :color="eNFUploaderBtnDisplay.color"
          @click="showENFUploader"
          :disabled="(processingImage || uploadingImages)"
          class="mx-2"
          id="upload-btn"
          v-if="companyHas('epriUploader')"
        >
          {{eNFUploaderBtnDisplay.text}}
          <v-icon right dark>
            cloud_upload
          </v-icon>
        </v-btn>
        <v-btn
          :color="metaUploadBtnDisplay.color"
          @click="showMetaUploader"
          :disabled="processingImage || uploadingImages"
          class="mx-2"
          id="upload-btn"
        >
          {{metaUploadBtnDisplay.text}}
          <v-icon right dark>
            cloud_upload
          </v-icon>
        </v-btn>
        <v-btn
          :color="filterBtnDisplay.color"
          @click="showFilter"
          :disabled="processingImage || uploadingImages"
          id="filter-btn"
          class="mx-2"
        >
          {{filterBtnDisplay.text}}
          <v-icon right dark>
            filter_alt
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" v-if="uploader">
        <DirectImageUploader :cid="currentUser.cid" :pid="currentProject.pid" />
      </v-col>
      <v-col cols="12" v-if="eNFUploader">
        <EPRIInputFolderUploader :cid="currentUser.cid" :pid="currentProject.pid" />
      </v-col>
      <v-col cols="12" v-if="metaUploader">
        <MetaDataUploader :cid="currentUser.cid" :pid="currentProject.pid" />
      </v-col>
      <v-col cols="12" v-if="filter">
        <ImageFilters />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DirectImageUploader from '@components/images/utilities/DirectImageUploader.vue';
import MetaDataUploader from '@components/images/utilities/MetaDataUploader.vue';
import EPRIInputFolderUploader from '@components/images/utilities/EPRIInputFolderUploader.vue';
import ImageFilters from '@components/images/utilities/ImageFilters.vue';

export default {
  name: 'ImageToolBar',
  components: {
    DirectImageUploader,
    EPRIInputFolderUploader,
    MetaDataUploader,
    ImageFilters,
  },
  data: () => ({
    uploader: false,
    filter: false,
    metaUploader: false,
    eNFUploader: false,
  }),
  methods: {
    showUploader() {
      this.filter = false;
      this.uploader = !this.uploader;
      this.metaUploader = false;
      this.eNFUploader = false;
    },
    showFilter() {
      this.filter = !this.filter;
      this.uploader = false;
      this.metaUploader = false;
      this.eNFUploader = false;
    },
    showMetaUploader() {
      this.filter = false;
      this.uploader = false;
      this.eNFUploader = false;
      this.metaUploader = !this.metaUploader;
    },
    showENFUploader() {
      this.filter = false;
      this.uploader = false;
      this.metaUploader = false;
      this.eNFUploader = !this.eNFUploader;
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'processingImage', 'currentProject', 'uploadingImages', 'companyHas']),
    uploadBtnDisplay() {
      return {
        color: this.uploader ? 'error' : 'primary',
        text: this.uploader ? 'Hide Uploaders' : 'Show Uploaders',
      };
    },
    eNFUploaderBtnDisplay() {
      return {
        color: this.eNFUploader ? 'error' : 'primary',
        text: this.eNFUploader ? 'Hide EPRI NF Uploader' : 'Show EPRI NF Uploader',
      };
    },
    metaUploadBtnDisplay() {
      return {
        color: this.metaUploader ? 'error' : 'primary',
        text: this.metaUploader ? 'Hide Meta Data Uploader' : 'Show Meta Data Uploader',
      };
    },
    filterBtnDisplay() {
      return {
        color: this.filter ? 'error' : 'primary',
        text: this.filter ? 'Hide Filters' : 'Filter Images',
      };
    },
  },
};
</script>

<style scoped>
.buttons-row {
  margin-top: -10px;
}
.buttons-row .v-btn {
  margin-top: 10px;
}
</style>
