<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              id="folders-list-search"
              outlined
              dense
              v-model="search"
              append-icon="mdi-magnify"
              label="Search Folders"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0"><FolderFilters /></v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        id="folders-list-table"
        :headers="headers"
        :items="foldersList"
        class="elevation-1"
        :search="search"
        :sort-by="['date']"
        :page.sync="currentPage"
        @pagination="updatePageNo"
        @update:items-per-page="updateItemPerPage"
        :sort-desc="[true]"
        v-scroll:#folders-list-table="onScroll"
        :footer-props="footer"
      >
      <template v-slot:item="{ item }">
        <tr
          @click="setCurrFolder(item)"
          :class="(isSelected(item)) ? 'selected-folder' : 'not-selected-folder'"
        >
          <td>
            <v-btn icon text><v-icon dark>mdi-folder</v-icon></v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="userRequired(item)"
                  color="red"
                  v-on="on"
                  small
                  v-bind="attrs"
                >error</v-icon>
              </template>
              Requires Attention
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="shouldInspect(item)"
                  color="amber"
                  v-on="on"
                  v-bind="attrs"
                  small
                >report_problem</v-icon>
              </template>
              Requires Field Inspection
            </v-tooltip>
          </td>
          <td>
            <div id="folder-name" class="d-inline-block mr-1">
              <span>{{item.name}}</span>
            </div>
            <div
              id="potential-image-missing"
              v-if="folderError(item) && !companyHas('general_folders')" class="d-inline-block ml-1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="error" v-on="on" v-bind="attrs">
                    help_outline
                  </v-icon>
                </template>
                This folder may contain too little or too many images
              </v-tooltip>
            </div>
          </td>
          <td>
            <v-icon v-if="displayReviewed(item)" color="primary">
              verified
            </v-icon>
          </td>
          <td>
            {{ (type === 'ai') ? item.processed_image_count : item.image_count }}
          </td>
          <td>{{item.nice_date}}</td>
        </tr>
      </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import helpers from '@methods/helpers';
import FolderFilters from '@components/folders/FolderFilters.vue';
import { setFolder } from '@utils/map/session-storage';

export default {
  name: 'FolderList',
  props: ['folders', 'loadingFolderlist', 'type'],
  components: {
    FolderFilters,
  },
  data: () => ({
    search: '',
    headers: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: '',
        width: '30%',
      },
      {
        text: 'Folder Name',
        align: 'start',
        sortable: true,
        value: 'name',
        width: '30%',
      },
      { text: 'Reviewed', value: 'second_reviewer.reviewed', width: '10%' },
      { text: 'Number of Images', value: 'processed_image_count' },
      { text: 'Date', value: 'nice_date', width: '30%' },
    ],
    timeout: null,
    footer: {
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      prevIcon: 'mdi-chevron-left',
      nextIcon: 'mdi-chevron-right',
      'items-per-page-options': [10, 50, 100, 200, 500],
    },
  }),
  methods: {
    ...mapActions(['setCurrentPage', 'setSelectedFolderPath', 'setCurrentFolder', 'setFolderFilter', 'setShowFolders']),
    hasFolderCountError(folder) {
      const numberImages = (this.type === 'ai') ? folder.processed_image_count : folder.image_count;

      // Minimum amount of images should be 2
      // Maximum amount of images should be 8
      const minimumImageCount = 2;
      const maximumImageCount = 8;

      return numberImages <= minimumImageCount || numberImages >= maximumImageCount;
    },
    updatePageNo(pagination) {
      this.setCurrentPage(pagination.page);
    },
    updateItemPerPage() {
      this.setCurrentPage(1);
    },
    // Finds error in folder (i.e number of images doesn't seem right)
    folderError(folder) {
      if (!this.currentProject) return false;
      const projectType = this.currentProject.project_type;
      if (projectType && projectType === 'Transmission') return false;

      return this.hasFolderCountError(folder);
    },
    // Sets the current folder on click
    setCurrFolder(folder) {
      this.setCurrentFolder(folder);
      setFolder(folder);
      this.setSelectedFolderPath(folder.path);
      this.setShowFolders(false);
    },
    // Scroll event handler
    onScroll(e) {
      // debounce if scrolling fast
      if (this.folders !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        this.timeout && clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.rowHeight);

          this.start = rows + this.perPage > this.folders.length
            ? this.folders.length - this.perPage : rows;

          this.$nextTick(() => { e.target.scrollTop = scrollTop; });
        }, 20);
      }
    },

    // Determines if the user is required for review
    userRequired(folder) {
      if (this.companyHas('general_folders')) return false;
      if (folder.path === '__all__') return false;

      // second_reviewer will be a field by default. If it's an empty object, then return false
      if (!Object.keys(folder.second_reviewer).length < 1) return false;

      // If the second_reviewer's uid matches the current user uid, then flag
      return folder.second_reviewer.uid === this.currentUser.uid;
    },

    // Determines if a field inspection is necessary
    shouldInspect(pole) {
      if (!(Object.keys(pole).includes('field_inspection'))) return false;

      return pole.field_inspection;
    },

    // Display check mark if the second_reviewer has reviewed the pole
    displayReviewed(pole) {
      if (!pole.second_reviewer) return false;
      return pole.second_reviewer.reviewed;
    },
    isSelected(folder) {
      return this.selectedFolderPath === folder.path;
    },
  },
  computed: {
    ...mapGetters(['allFolders', 'currentPage', 'selectedFolderPath', 'rootFolder', 'currentFolder', 'allImages', 'currentUser', 'companyHas', 'insightsFolders', 'currentProject']),
    foldersList() {
      const isImages = this.$route.name === 'ProcessedImages'
        || this.$route.name === 'OriginalImages'
        || this.$route.name === 'HumanInTheLoop'
        || this.$route.name === 'HumanInTheLoopEdit';

      const isDashboard = this.$route.name === 'Insights';

      // If Newfoundland
      const isNfl = this.companyHas('poleByFolders');

      // If general instance
      const isGeneral = (this.companyHas('general_folders') && isImages) || (this.companyHas('general_folders') && isDashboard);
      let foldersToFilter;

      if (isNfl) foldersToFilter = (isImages) ? this.allFolders : this.insightsFolders;

      // Compute the number of images that matches the folder.path value
      if (isGeneral) {
        foldersToFilter = this.allFolders.map((folder) => {
          const currentFolder = folder;

          currentFolder.image_count = this.allImages
            .filter((image) => image.folder === folder.path).length;

          currentFolder.processed_image_count = this.allImages
            .filter((image) => image.processedImageUrl)
            .filter((image) => image.folder === folder.path).length;

          return currentFolder;
        });
      }

      const niceDateFolders = foldersToFilter.map((fold) => ({
        ...fold,
        nice_date: helpers.convertToDate(new Date(fold.date)).format('dddd, MMMM Do YYYY, h:mm:ss a'),
        name: fold.path,
      }));
      const root = cloneDeep(this.rootFolder);
      if (this.type === 'original') {
        root.image_count = this.allImages.length;
      } else {
        root.processed_image_count = this.allImages
          .filter((image) => image.processedImageUrl).length;
      }

      // If it's in the processed images route, filter out the folders so that
      // it only displays folders with images that have been processed
      if (this.$route.name === 'ProcessedImages' && this.companyHas('poleByFolders')) {
        const filtered = niceDateFolders.filter((folder) => {
          const currentFolder = cloneDeep(folder);
          const imagesInFolder = this.allImages.filter((img) => img.folder === currentFolder.path);
          const processedImagesInFolder = imagesInFolder.filter((img) => img.processedImageUrl);
          return processedImagesInFolder.length > 0;
        });
        filtered.unshift(root);
        return filtered;
      }

      if (!this.companyHas('disable_root_folder')) niceDateFolders.unshift(root);
      // Otherwise, return the list of folders for the original images
      return niceDateFolders;
    },
  },
  mounted() {
    // Create processed header and original header
    const processedHeader = { text: 'Number of Processed Images', value: 'processed_image_count' };
    const originalHeader = { text: 'Number of Images', value: 'image_count' };

    // Find index of header with text 'Number of Images'
    const index = this.headers.findIndex((header) => header.text === 'Number of Images');

    // If it's within ai tab, replace originalHeader with processedHeader
    if (this.type === 'ai') this.headers.splice(index, 1, processedHeader);

    // If it's original, replace the original header with the generated one
    if (this.type === 'original') this.headers.splice(index, 1, originalHeader);
    if (this.companyHas('disable_root_folder') && Object.keys(this.currentFolder).length === 0) {
      this.setCurrentFolder(this.allFolders[0]);
    }
    const [selectedRow] = document.getElementsByClassName('selected-folder') || [];

    // scroll to the selected folder if a folder is selected
    if (selectedRow) {
      const topPos = selectedRow.offsetTop;
      document.getElementById('folders-list-table').scrollTop = topPos;
    }
  },

};
</script>

<style scoped>
#folders-list-table{
  max-height: 250px;
  overflow: auto;
}
.selected-folder {
  cursor: pointer;
  background-color: #E3F2FD;
}
.not-selected-folder {
  cursor: pointer;
}
</style>
