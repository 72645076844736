<template>
  <v-select
    v-if="showDropdown"
    outlined
    dense
    class="mt-3"
    label="Navigate to a project"
    v-model="nextProject"
    :items="projects"
    return-object
    item-text="name"
    @change="changeRoute"
  ></v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import imageHelpers from '@methods/images';
import RouteMapper from '@components/layouts/route-mapping';

export default {
  name: 'RedirectByProject',
  data: () => ({
    projects: [],
    nextProject: {},
    availableRoutes: [
      'ProjectsLayout',
      'Project',
      'EditProject',
      'NoImages',
      'Images',
      'OriginalImages',
      'CurrentImage',
      'CurrentProcessedImage',
      'HumanInTheLoop',
      'NoProcessedImages',
      'ProcessedImages',
      'Analytics',
      'Insights',
      'HumanInTheLoopAnalytics',
    ],
  }),
  methods: {
    ...mapActions(['setCurrentProject', 'setCurrentImage', 'setLoadingImage']),
    async changeImageRoute() {
      this.setLoadingImage(true);
      const { pid } = this.nextProject;
      const imageData = await imageHelpers
        .getImageDataByProject(this.currentCompany.cid, pid);
      if (imageData) {
        this.setCurrentProject(this.nextProject);
      }

      // Determine if the current project has any images and processed images within
      const { images } = imageData;
      const { processedImages } = imageData;
      const tab = (this.$route.params.tab) ? this.$route.params.tab : this.$route.path.split('/')[4];

      let firstImage;

      if (tab === 'original') [firstImage] = images;
      else [firstImage] = processedImages;

      this.setCurrentImage(firstImage);

      // Boolean values for if we're on original tab and if we have original images
      const originalNoImages = tab === 'original' && images.length === 0;
      const originalWithImages = tab === 'original' && images.length > 0;

      // Boolean values for if we're on ai tab and if we have processed images
      const processedNoImages = tab === 'ai' && processedImages.length === 0;
      const processedWithImages = tab === 'ai' && processedImages.length > 0;
      let nextRoute;

      // Conditions determin what nextRoute is
      if (originalNoImages) nextRoute = `/project/${pid}/images/${tab}/no-images`;
      if (originalWithImages) nextRoute = `/project/${pid}/images/${tab}/${firstImage.filename}`;
      if (processedNoImages) nextRoute = `/project/${pid}/images/${tab}/no-processed-images/processed`;
      if (processedWithImages) nextRoute = `/project/${pid}/images/${tab}/${firstImage.filename}`;

      // Navigate to the nextRoute
      this.$router.push(nextRoute)
        .then(() => {
          this.setLoadingImage(false);
        });
    },
    async changeRoute() {
      const params = cloneDeep(this.$route.params);

      const isProjects = this.$route.name === 'ProjectsLayout';

      const isImageRoute = this.$route.name === 'Images'
        || this.$route.name === 'NoImages'
        || this.$route.name === 'OriginalImages'
        || this.$route.name === 'HumanInTheLoop'
        || this.$route.name === 'NoProcessedImages'
        || this.$route.name === 'ProcessedImages';

      if (isImageRoute) await this.changeImageRoute();
      else {
        params.pid = this.nextProject.pid;
        const nextRoute = (isProjects)
          ? RouteMapper.Project(params)
          : RouteMapper[this.$route.name](params);

        if (this.$route.path !== nextRoute) this.$router.push(nextRoute);
      }
    },
  },
  computed: {
    ...mapGetters(['currentProject', 'allProjects', 'currentCompany', 'loadingProject', 'companyHas']),
    // Determines whether or not the component should exist on the navbar
    showDropdown() {
      return this.availableRoutes.includes(this.$route.name);
    },
  },
  watch: {
    // When the projects list is modified, this.projects should be modified as well
    allProjects: {
      immediate: true,
      handler(newProjectsList) {
        this.projects = newProjectsList;
      },
    },
    // When project is changed, this.nextProject should be changed to the newProject
    currentProject: {
      immediate: true,
      handler(newProject) {
        this.nextProject = newProject;
      },
    },
  },
  created() {
    // Finds the first project and intiialize it to this.nextProject
    [this.nextProject] = this.allProjects
      .filter((project) => project.pid === this.currentProject.pid);
  },
};
</script>
