<template>
  <v-container fluid>
    <NoProjects v-if="allProjects.length === 0" />
    <div class="mb-8" v-else>
      <ProjectsDataIterators />
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProjectsDataIterators from '@components/projects/ProjectsDataIterators.vue';
import NoProjects from '@components/projects/NoProjects.vue';

export default {
  name: 'ProjectGrid',
  components: {
    ProjectsDataIterators,
    NoProjects,
  },
  data: () => ({
    show: false,
    message: '',
    page: 1,
  }),
  methods: {
    ...mapActions(['setProjectsPage']),
    getCreateProjectStatus(status) {
      if (status.includes('successfully')) {
        this.show = true;
        this.message = status;
        setTimeout(() => {
          this.show = false;
          this.message = '';
        }, 5000);
      }
    },
  },
  computed: {
    ...mapGetters(['allProjects', 'projectsOnPage', 'currentProjectsPage', 'currentProjectSearch']),
  },
};
</script>
