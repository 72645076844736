import axios from 'axios';
import { baseURL } from '@utils/endpoint';

export default {
  get: (cid, pid) => new Promise((resolve, reject) => {
    axios.get(`${baseURL}/api/dashboard/company/${cid}/project/${pid}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  filter: (cid, pid, query) => new Promise((resolve, reject) => {
    axios.get(`${baseURL}/api/dashboard/company/${cid}/project/${pid}/filter/?${query}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
};
