<template>
  <v-container>
    <v-card>
      <v-card-text>
        Please review and confirm the information provided below.
        You are also asked to set a new password.
      </v-card-text>
      <v-form ref="registerForm">
      <v-container>
        <v-row>
          <v-col xs="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field
              outlined
              label="First Name"
              v-model="firstName"
              :rules="[requiredRule]"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field
              outlined
              label="Last Name"
              v-model="lastName"
              :rules="[requiredRule]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field
              outlined
              label="Email Address"
              v-model="email"
              disabled
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field
              outlined
              label="Phone"
              @input="formatPhone"
              v-model="phone"
              :rules="[requiredRule]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Password"
              :rules="[requiredRule]"
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Confirm Password"
              :rules="[requiredRule]"
              :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showConfirm = !showConfirm"
              :type="showConfirm ? 'text' : 'password'"
              :error-messages="error"
              v-model="confirm"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions class="my-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="registerUser" :loading=loading>Register</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Register',
  data: () => ({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirm: '',
    showPassword: false,
    showConfirm: false,
    error: '',
    uid: '',
    requiredRule: (v) => !!v || 'Field is required',
    loading: false,
  }),
  methods: {
    ...mapActions(['getUserAuthenticationData', 'confirmUser']),
    async registerUser() {
      this.loading = true;
      const isFormValid = this.$refs.registerForm.validate();
      if (isFormValid) {
        const {
          firstName, lastName, password, confirm, phone, uid,
        } = this;
        if (password !== confirm) {
          this.error = 'Your passwords must match';
          this.loading = false;
          return;
        }
        const displayName = `${firstName} ${lastName}`;
        const data = {
          phoneNumber: `+1${phone}`,
          displayName,
          uid,
          password,
        };
        try {
          const user = await this.confirmUser(data);
          if (user.status) {
            this.$router.push('/login');
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      }
      this.loading = false;
    },
    formatPhone() {
      const number = this.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone = !number[2] ? number[1] : `(${number[1]}) ${number[2]}${number[3] ? `-${number[3]}` : ''}`;
    },
  },
  async created() {
    const { uid } = this.$router.history.current.params;
    this.uid = uid;
    const query = `uid=${uid}`;
    const user = await this.getUserAuthenticationData(query);

    if (user.emailVerified) {
      this.$router.push('/login');
    }
    const { displayName, email } = user;
    [this.firstName, this.lastName] = displayName.split(' ');
    this.email = email;
  },
};
</script>
