<template>
  <v-container fluid>
    <v-stepper v-model="e6" vertical>
      <v-stepper-step :complete="e6 > 1" step="1">
        Search Account with Email Address
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card flat class="mb-12">
          <v-row>
            <v-col cols="6">
              <v-alert outlined>
                <div class="title"></div>
                <div>
                  Please enter in your email address, and we'll send a 6 digit verification
                  code to the phone number or email associated with your account.
                </div>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                outlined
                label="Enter Email Address"
                type="email"
                v-model="email"
                @keydown.enter="searchUserByEmail"
                :error-messages="userError.userNotFound"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-card-actions>
          <v-btn color="primary" text @click="searchUserByEmail">Continue</v-btn>
        </v-card-actions>
        <v-divider></v-divider>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 2" step="2">Choosing Verification Method</v-stepper-step>
      <v-stepper-content step="2">
        <router-view v-if="e6 === 2"></router-view>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 3" step="3">Verifying User</v-stepper-step>
      <v-stepper-content step="3">
        <router-view v-if="e6 === 3"></router-view>
      </v-stepper-content>

       <v-stepper-step :complete="e6 > 4" step="4">Choose Password</v-stepper-step>
      <v-stepper-content step="4">
        <router-view v-if="e6 === 4"></router-view>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 5" step="5">Successful Password Reset</v-stepper-step>
      <v-stepper-content step="5">
        <router-view v-if="e6 === 5"></router-view>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ForgotPassword',
  props: ['step'],
  components: {
  },
  data: () => ({
    e6: 1,
    email: '',
    error: { noUser: '' },
  }),
  computed: {
    ...mapGetters(['userError']),
  },
  watch: {
    $route(newRoute) {
      this.getResetPasswordStep(newRoute.name);
    },
  },
  methods: {
    ...mapActions(['getUserByEmail']),
    getResetPasswordStep(route) {
      switch (route) {
        case 'VerificationMethods':
          this.e6 = 2;
          break;
        case 'VerifyUser':
          this.e6 = 3;
          break;
        case 'ResetPasswordForm':
          this.e6 = 4;
          break;
        case 'PasswordResetComplete':
          this.e6 = 5;
          break;
        default:
          this.e6 = 1;
          break;
      }
    },
    async searchUserByEmail() {
      // Step 1: search the database by email
      const res = await this.getUserByEmail(this.email);
      if (res.status === 200) {
        this.e6 += 1;
        this.$router.push(`/forgot-password/verification-select/${res.data.uid}`);
      } else this.error.noUser = 'Unable to find user with the provided email address';
    },
  },
  mounted() { this.getResetPasswordStep(this.$route.name); },
};
</script>
