<template>
  <v-list-group no-action :prepend-icon="icon">
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>{{ title }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <v-list-item v-for="child in children" :key="child.title" link :to="child.path">
      <v-list-item-title>{{ child.title }}</v-list-item-title>
    </v-list-item>
  </v-list-group>
</template>

<script>
export default {
  name: 'ListGroupItem',
  props: ['icon', 'title', 'children'],
};
</script>
