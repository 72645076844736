import axios from 'axios';
import { baseURL } from '@utils/endpoint';

const state = {
  prices: [],
  price: {},
  newPrice: {},
  loading: false,
  errors: {},
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  allPrices: (state) => state.prices,
  currentPrice: (state) => state.price,
  currentNewPrice: (state) => state.newPrice,
  loadingPrice: (state) => state.loading,
  loadingPrices: (state) => state.loading,
  pricingErrors: (state) => state.errors,
};

const actions = {
  setLoadingPrices({ commit }, loading) {
    commit('loading_prices', loading);
  },
  setLoadingPrice({ commit }) {
    commit('loading_price', true);
  },
  finishLoadingPrice({ commit }) {
    commit('loading_price', false);
  },
  getPrices({ commit }) {
    commit('loading_prices', true);
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}/api/prices`)
        .then((res) => {
          commit('fetch_prices', res.data);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  getNewPriceById({ commit }, priceId) {
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}/api/prices/${priceId}`)
        .then((res) => {
          commit('fetch_new_price', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  async getPriceById({ commit }, priceId) {
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}/api/prices/${priceId}`)
        .then((res) => {
          commit('fetch_price', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  async getImagePlanPrices({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}/api/prices/image`)
        .then((res) => {
          const { data } = res.data;
          commit('fetch_prices', data);

          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  getPriceByProductId({ commit }, payload) {
    commit('loading_prices', true);
    const { planName } = payload;
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}/api/prices/tier/${planName}`)
        .then((res) => {
          commit('fetch_price', res.data);
          resolve(res.data);
        })
        .catch((err) => {
          commit('error_prices', err);
          reject(err);
        });
    });
  },
};

const mutations = {
  loading_price: (state, loading) => {
    state.loading = loading;
  },
  loading_prices: (state, loading) => {
    state.loading = loading;
  },
  fetch_prices: (state, prices) => {
    state.prices = prices;
  },
  fetch_price: (state, price) => {
    state.price = price;
  },
  fetch_new_price: (state, price) => {
    state.newPrice = price;
  },
  error_prices: (state, error) => {
    state.errors = error;
    if (state.loading) state.loading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
