<template>
  <v-container fluid id="structure-work-order">
    <div id="structure-work-order-display" v-if="!editingFolder">
      <span class="text-caption">{{ workOrderStatus }}</span>
    </div>
    <div id="structure-work-order-editor" v-else>
      <v-select
        :items="['Yes', 'No']"
        id="work-order-field"
        dense
        outlined
        v-model="workOrderStatus"
        label="Require work order?"
        @change="changeWorkOrder"
      ></v-select>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'StructureWorkOrder',
  props: ['workOrder'],
  data: () => ({
    edit: false,
    workOrderStatus: '',
  }),
  methods: {
    ...mapActions(['setNotification', 'setWorkOrder']),
    changeWorkOrder() {
      const workOrder = this.workOrderStatus === 'Yes';
      this.setWorkOrder(workOrder);
    },
  },
  computed: {
    ...mapGetters(['editingFolder']),
  },
  watch: {
    folder(newFolder) {
      this.workOrder = newFolder.work_order ? 'Yes' : 'No';
    },
    workOrder(newWorkOrder) {
      this.workOrderStatus = newWorkOrder ? 'Yes' : 'No';
    },
  },
  mounted() {
    this.workOrderStatus = this.workOrder ? 'Yes' : 'No';
  },
};
</script>
