import axios from 'axios';
import jwtDecode from 'jwt-decode';
import firebase from '@database/index';
import setAuthToken from '@utils/auth';
import { baseURL } from '@utils/endpoint';
import services from '@services';

const { database, auth } = firebase;
const userCollection = database.collection('users');
// const PHONE_AUTH_URL = 'https://twilio-server-246321.appspot.com/send-code';

const state = {
  // User data
  user: null,
  // Loaders
  loading: false,
  loadUsers: false,
  updating: false,
  // Email & Password Auth
  firstFactor: 'currentUser' in auth() && auth().currentUser !== null,
  // TWO FACTOR AUTH
  sendingCode: false,
  verifyingTwoFactor: false,
  secondFactor: false,
  // FORGOT PASSWORD STATES
  codeSent: false,
  codeVerified: false,
  passwordResetted: false,
  codeGenerated: false,
  backdoor: false,
  errors: { userNotFound: '' },
  notifications: { success: false, message: '' },
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  // User data
  currentUser: (state) => state.user,
  // Loaders
  loadingUser: (state) => state.loading,
  loadingUsers: (state) => state.loadUsers,
  updatingUser: (state) => state.updating,
  // Authentication
  firstFactorAuthenticated: (state) => state.firstFactor,
  secondFactorAuthenticated: (state) => state.secondFactor,
  verifyingTwoFactor: (state) => state.verifyingTwoFactor,
  loggedIn: (state) => state.firstFactor && state.secondFactor,
  hasBackdoorAccess: (state) => state.backdoor,
  // Errors
  userError: (state) => state.errors,
  userNotifications: (state) => state.notifications,
};

const actions = {
  getBackdoorAccess({ commit }, uid) {
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}/api/auth/backdoor/access/${uid}`)
        .then((res) => {
          const { hasAccess } = res.data;

          commit('enable_backdoor', hasAccess);
          resolve(hasAccess);
        })
        .catch((err) => reject(err));
    });
  },
  setSecondFactorAuth({ commit }) {
    commit('set_two_factor', localStorage.jwtToken !== undefined);
  },
  async verifyTwoFactorAuth({ commit }, payload) {
    commit('verifying_two_auth', true);
    const body = { code: payload.code, authType: payload.authType };

    return new Promise((resolve, reject) => {
      axios.post(`${baseURL}/api/auth/${payload.verification}/verify/${payload.uid}`, body)
        .then(async (res) => {
          const { token } = res.data;

          // Sets token to localStorage
          localStorage.setItem('jwtToken', token);

          // Sets token to Auth header
          setAuthToken(token);
          const decoded = jwtDecode(token);

          const snapshot = await userCollection.doc(decoded.user_id).get();
          const user = snapshot.data();
          commit('fetch_user', user);
          commit('set_two_factor', localStorage.jwtToken !== undefined);

          // Two Factor Auth finishes verifications
          commit('verifying_two_auth', false);
          resolve(res);
        })
        .catch((err) => {
          const notification = { success: true, message: 'Your verification code did not match' };
          commit('notification_users', notification);
          setTimeout(() => {
            commit('notification_users', { message: '', success: false });
          }, 3000);
          commit('verifying_two_auth', false);
          reject(err);
        });
    });
  },
  setCurrentUser({ commit }, uid) {
    commit('loading_user');
    return new Promise((resolve, reject) => {
      userCollection.doc(uid).get()
        .then((documentSnapshot) => {
          const user = documentSnapshot.data();
          commit('fetch_user', user);
          const authenticated = 'currentUser' in auth();

          commit('set_first_factor', authenticated);
          commit('set_two_factor', localStorage.jwtToken !== undefined);
          resolve(user);
        })
        .catch((err) => reject(err));
    });
  },
  setFirstFactorAuth({ commit }, user) {
    const authenticated = 'currentUser' in auth();
    commit('set_first_factor', authenticated);
    commit('fetch_user', user);
  },
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      auth().signInWithEmailAndPassword(user.email, user.password)
        .then(() => {
          // Search for user data
          userCollection.where('uid', '==', auth().currentUser.uid).get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const authenticated = 'currentUser' in auth();

                commit('set_first_factor', authenticated);
                commit('fetch_user', doc.data());
              });
            })
            .catch((err) => reject(err));
          resolve(auth().currentUser);
        }).catch((err) => reject(err));
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      auth().signOut()
        .then(() => {
          commit('remove_user', null);
          // If localstorage has a buzzToken, remove it since it's the old token
          if (localStorage.getItem('buzzToken')) localStorage.removeItem('buzzToken');
          if (localStorage.getItem('jwtToken')) localStorage.removeItem('jwtToken');

          setAuthToken(false);
          commit('set_first_factor', false);
          commit('set_two_factor', false);
          resolve(200);
        })
        .catch((err) => reject(err));
    });
  },
  // Updates the user document in Firestore
  updateUser({ commit }, updatedUser) {
    commit('updating_user', true);
    return new Promise((resolve, reject) => {
      userCollection.doc(updatedUser.uid).update(updatedUser)
        .then(() => {
          commit('update_user', updatedUser);
          const status = { code: 'SUCCESS', msg: 'User has been updated' };
          commit('updating_user', false);
          resolve(status);
        })
        .catch((err) => reject(err));
    });
  },
  // Updates the user data in Firebase.Auth
  updateUserAuth({ commit }, profile) {
    commit('test');
    const {
      firstName,
      lastName,
      phone,
      email,
    } = profile;
    return new Promise((resolve, reject) => {
      const updatedProfile = { displayName: `${firstName} ${lastName}`, email, phoneNumber: phone };
      firebase.auth().currentUser.updateProfile(updatedProfile)
        .then(() => {
          resolve({ msg: 'Update Success' });
        })
        .catch((err) => reject(err));
    });
  },
  // Resets Password by email
  passwordReset({ commit }, emailAddress) {
    return new Promise((resolve, reject) => {
      auth().sendPasswordResetEmail(emailAddress)
        .then(() => {
          const status = {
            code: 'auth/user-found',
            message: 'Please check your inbox for instructions on resetting your password.',
          };
          commit('reset_password', status);
          resolve(status);
        })
        .catch((err) => reject(err));
    });
  },
  createUser({ commit }, userData) {
    const { adminStatus } = userData;
    return new Promise((resolve, reject) => {
      if (!adminStatus) {
        const error = new Error('You are not an admin');
        reject(error);
      }
      axios.post(`${baseURL}/api/user/create`, userData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit('user_error', err);
          reject(err);
        });
    });
  },
  confirmUser({ commit }, userData) {
    return new Promise((resolve, reject) => {
      axios.post(`${baseURL}/api/user/confirm/${userData.uid}`, userData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit('user_error', err);
          reject(err);
        });
    });
  },
  deleteUser({ commit }, userData) {
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}/api/user/delete/${userData.uid}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit('user_error', err);
          reject(err);
        });
    });
  },
  getUserAuthenticationData({ commit }, query) {
    return new Promise((resolve, reject) => {
      services.auth.getFireBaseUser(query)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          commit('user_error', err);
          reject(err);
        });
    });
  },
  sendNewUserEmail({ commit }, userData) {
    const { adminStatus, credentials } = userData;
    return new Promise((resolve, reject) => {
      if (!adminStatus) {
        const error = new Error('You are not an admin');
        reject(error);
      }

      axios.post(`${baseURL}/api/user/email/${credentials.uid}`,
        credentials)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          commit('user_error', err);
          reject(err);
        });
    });
  },
  // FORGOT PASSWORD ACTIONS
  getUserByEmail({ commit }, email) {
    return new Promise((resolve, reject) => {
      axios.post(`${baseURL}/api/user/email`, { email })
        .then((res) => {
          commit('fetch_user', res.data);
          resolve(res);
        })
        .catch((err) => {
          commit('user_error', { code: 'userNotFound', message: 'No user found with that email address' });
          reject(err);
        });
    });
  },
  getUserDocumentById({ commit }, uid) {
    commit('loading_user');
    return new Promise((resolve, reject) => {
      services.users.get(uid)
        .then((res) => {
          commit('fetch_user', res.data);
          resolve(res);
        })
        .catch((err) => {
          commit('user_error', { code: 'userNotFound', message: 'No user found with that email address' });
          reject(err);
        });
    });
  },
  resetPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${baseURL}/api/user/reset-password`, payload)
        .then((res) => {
          commit('password_reset', true);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  updateJWTToken({ commit }, uid) {
    commit('updating_user', true);
    return new Promise((resolve, reject) => {
      axios.put(`${baseURL}/api/auth/signed-token/update/${uid}`)
        .then((res) => {
          const { token } = res.data;
          localStorage.setItem('jwtToken', token);

          setAuthToken(token);
          const decoded = jwtDecode(token);
          commit('updating_user', false);
          resolve(decoded);
        })
        .catch((err) => reject(err));
    });
  },
  createUserDocument({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.users.create(payload)
        .then((res) => {
          commit('fetch_user', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
};

const mutations = {
  notification_users: (state, notification) => { state.notification = notification; },
  enable_backdoor: (state, access) => { state.backdoor = access; },
  // First Factor Authentication
  set_first_factor: (state, authenticated) => { state.firstFactor = authenticated; },

  // Two Factor Authentication
  set_two_factor: (state, authenticated) => { state.secondFactor = authenticated; },
  verifying_two_auth: (state, verifying) => { state.verifying = verifying; },

  // Loading Mutation
  loading_user: (state) => { state.loading = true; },
  loading_users: (state) => { state.loadUsers = true; },
  updating_user: (state, updating) => { state.updating = updating; },

  finish_loading_verification: (state) => { state.loadVerification = false; },

  // Errors
  user_error: (state, err) => {
    state.errors[err.code] = err.message;
  },
  reset_password: (state, status) => { state.status = status; },
  // CRUD
  fetch_user: (state, user) => {
    state.user = user;
    state.loading = false;
  },
  remove_user: (state) => {
    state.user = null;
    state.secondFactor = false;
    state.firstFactor = false;
  },
  update_user: (state, user) => { state.user = user; },

  // FORGOT PASSWORD MUTATIONS
  send_verification: (state, sent) => { state.codeSent = sent; },
  code_verified: (state, verified) => { state.codeVerified = verified; },
  password_reset: (state, resetted) => { state.passwordResetted = resetted; },
  verfication_code_generated: (state, generated) => { state.codeGenerated = generated; },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
