import * as lodash from 'lodash';
import firebase from '@database';

const teamsCollection = firebase.database.collection('teams');
// const { getDataFromDocumentReferenceList } = helpers;

const state = {
  teams: [],
  loading: false,
  projectsInTeamLoading: false,
  employeesInTeamLoading: false,
  projectsInTeam: [],
  notification: {
    message: '',
    success: true,
  },
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  allTeams: (state) => state.teams,
  teamProjects: (state) => state.projectsInTeam,
  loadingTeams: (state) => state.loading,
  loadingTeamsProjects: (state) => state.projectsInTeamLoading,
  loadingEmployeesTeams: (state) => state.employeesInTeamLoading,
  teamsNotification: (state) => state.notification,
};

const actions = {
  setTeamsNotification({ commit }, notification) {
    commit('notification_team', notification);
    setTimeout(() => {
      commit('notification_team', { success: false, message: '' });
    }, 3000);
  },
  clearTeams({ commit }) {
    commit('clear_teams');
  },
  setLoadingTeams({ commit }) {
    commit('set_loading_teams');
  },
  setEmployeesLoadingInTeam({ commit }) {
    commit('set_loading_employees_teams');
  },
  finishTeamLoading({ commit }) {
    commit('finish_loading_teams');
  },
  getAllTeams({ commit }, cid) {
    commit('set_loading_teams');
    return new Promise((resolve, reject) => {
      teamsCollection
        .where('cid', '==', cid)
        .get()
        .then((querySnapshot) => {
          const { docs } = querySnapshot;
          const fetchedTeams = docs.map((doc) => doc.data());

          commit('fetch_teams', fetchedTeams);
          resolve(fetchedTeams);
        })
        .catch((err) => reject(err));
    });
  },
  async getTeamsByDocumentReferences({ commit }, teamDocs) {
    commit('set_loading_teams');
    const promises = teamDocs.map(async (doc) => doc.get());
    const documentSnapshots = await Promise.all(promises);
    const teams = documentSnapshots.map((doc) => doc.data());
    commit('fetch_teams', teams);
    return teams;
  },
  getProjectsDataFromDoc({ commit }, teamData) {
    commit('set_loading_project_teams');
    const { tid, projects } = teamData;

    const projectDocPromises = projects.map((doc) => new Promise((resolve, reject) => {
      doc.get()
        .then((documentSnapshot) => {
          resolve(documentSnapshot.data());
        })
        .catch((err) => reject(err));
    }));

    return Promise.all(projectDocPromises)
      .then((projectsData) => {
        const projectsDataNotNull = projectsData.filter((p) => p != null);
        commit('fetch_projects_in_team', { tid, projects: projectsDataNotNull });
      });
  },
  getEmployeesDataFromDoc({ commit }, teamData) {
    commit('set_loading_employees_teams');

    const { tid, members } = teamData;

    const membersDocPromises = members.map((doc) => new Promise((resolve, reject) => {
      doc.get()
        .then((documentSnapshot) => {
          resolve(documentSnapshot.data());
        })
        .catch((err) => reject(err));
    }));

    return Promise.all(membersDocPromises)
      .then((membersData) => {
        const membersDataNotNull = membersData.filter((m) => m != null);
        commit('fetch_employees_in_team', { tid, members: membersDataNotNull });
      });
  },
  getProjectsInTeam({ commit }, projectDocs) {
    commit('set_loading_project_teams');

    const projectPromises = projectDocs.map((doc) => new Promise((resolve, reject) => {
      doc.get()
        .then((docSnapshot) => {
          resolve(docSnapshot.data());
        })
        .catch((err) => reject(err));
    }));

    Promise.all(projectPromises)
      .then((projectsData) => {
        commit('fetch_projects_in_team', projectsData);
      });
  },
  createTeam({ commit }, teamData) {
    // const { members, projects } = teamData;
    // const newTeamDoc = {
    //   cid: teamData.cid,
    //   members: (members.length > 0)
    // ? helpers.getDocReferenceList(members, 'users', 'uid') : [],
    //   projects: (projects.length > 0)
    // ? helpers.getDocReferenceList(projects, 'projects', 'pid') : [],
    //   name: teamData.name,
    // };

    return new Promise((resolve, reject) => {
      teamsCollection
        .add(teamData)
        .then(async (docRef) => {
          await docRef.update({ tid: docRef.id });

          const addedTeamData = teamData;
          addedTeamData.tid = docRef.id;

          const documentSnapshot = await docRef.get();

          commit('create_team', documentSnapshot.data());
          const status = {
            code: 'SUCCESS',
            message: 'Team has been successfully created',
            data: addedTeamData,
          };

          const notification = {
            success: true,
            message: `${addedTeamData.name} has been created.`,
          };
          commit('notification_team', notification);

          setTimeout(() => {
            commit('notification_team', { success: false, message: '' });
          }, 3000);
          resolve(status);
        })
        .catch((err) => reject(err));
    });
  },
  updateTeam({ commit }, updatedTeam) {
    const { tid } = updatedTeam;

    return new Promise((resolve, reject) => {
      teamsCollection
        .doc(tid)
        .update(updatedTeam)
        .then(() => {
          commit('update_team', updatedTeam);
          const status = {
            code: 'SUCCESS',
            data: updatedTeam,
          };
          resolve(status);
        })
        .catch((err) => reject(err));
    });
  },
  replaceMembers({ commit }, membersData) {
    commit('replace_members', membersData);
  },
  replaceProjects({ commit }, projectsData) {
    commit('replace_projects', projectsData);
  },
  deleteTeam({ commit }, team) {
    const { tid } = team;
    return new Promise((resolve, reject) => {
      teamsCollection
        .doc(tid)
        .delete()
        .then(() => {
          const status = {
            code: 'SUCCESS',
            message: 'Sucessfully deleted team',
            data: team,
          };

          commit('remove_team', tid);
          resolve(status);
        })
        .catch((err) => reject(err));
    });
  },
};

const mutations = {
  notification_team: (state, notification) => {
    state.notification = notification;
  },
  clear_teams: (state) => {
    state.teams = [];
  },
  set_loading_teams: (state) => {
    state.loading = true;
  },
  set_loading_project_teams: (state) => {
    state.projectsInTeamLoading = false;
  },
  set_loading_employees_teams: (state) => {
    state.employeesInTeamLoading = true;
  },
  finish_loading_teams: (state) => {
    state.loading = false;
  },
  fetch_projects_in_team: (state, projectsData) => {
    const { tid, projects } = projectsData;
    if (state.teams.length > 0) {
      const modifyingTeam = lodash.find(state.teams, (team) => team.tid === tid);
      modifyingTeam.projects = projects;
      const index = state.teams.findIndex((team) => team.tid === tid);
      if (index !== -1) state.teams.splice(index, 1, modifyingTeam);
    }
    // state.projectsInTeam = projects;
    state.projectsInTeamLoading = false;
  },
  fetch_employees_in_team: (state, membersData) => {
    const { tid, members } = membersData;
    if (state.teams.length > 0) {
      const modifyingTeam = lodash.find(state.teams, (team) => team.tid === tid);
      modifyingTeam.members = members;

      const index = state.teams.findIndex((team) => team.tid === tid);
      if (index !== -1) state.teams.splice(index, 1, modifyingTeam);
    }

    state.employeesInTeamLoading = false;
  },
  fetch_teams: (state, teams) => {
    state.teams = teams;
    state.loading = false;
  },
  fetch_teams_doc: (state, teams) => {
    if (state.teams.length === 0) state.teams.push(teams);
    else state.teams = state.teams.concat(teams);
  },
  create_team: (state, newTeam) => {
    state.teams.unshift(newTeam);
  },
  fetch_team: (state, team) => {
    state.team = team;
    state.loading = false;
  },
  remove_team: (state, tid) => {
    state.teams = state.teams.filter((team) => team.tid !== tid);
  },
  update_team: (state, updatedTeam) => {
    const index = state.teams.findIndex((team) => team.tid === updatedTeam.tid);
    if (index !== -1) state.teams.splice(index, 1, updatedTeam);
  },
  replace_members: (state, membersData) => {
    const { tid, membersList } = membersData;
    const index = state.teams.findIndex((team) => team.tid === tid);
    if (index !== -1) state.teams[index].members = membersList;
  },
  replace_projects: (state, projectsData) => {
    const { tid, projectsList } = projectsData;
    const index = state.teams.findIndex((team) => team.tid === tid);
    if (index !== -1) state.teams[index].projects = projectsList;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
