var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.allCompanies,"loading":_vm.loadingCompanies || _vm.updatingUser},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Company Instances")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_vm._v(" Create Company Instance ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('CreateInstanceForm',{attrs:{"company":_vm.editedCompany},on:{"close":_vm.closeForm}})],1),_c('v-dialog',{attrs:{"max-width":"750px"},model:{value:(_vm.userDialog),callback:function ($$v) {_vm.userDialog=$$v},expression:"userDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Create new User: "+_vm._s(_vm.editedCompany.companyName)+" ")]),_c('v-divider'),_c('CreateUserForm',{attrs:{"errors":_vm.errors},on:{"close":_vm.closeUserForm,"save":_vm.saveUser}})],1)],1),_c('v-dialog',{attrs:{"max-width":"750px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('EditCompanyForm',{key:_vm.editedCompany.cid,attrs:{"company":_vm.editedCompany},on:{"close":function($event){_vm.editDialog = false}}})],1)],1)]},proxy:true},{key:"item.finished",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"x-small":"","color":(item.finished) ? 'green' : 'secondary',"text-color":"white"}},[_vm._v(" "+_vm._s((item.finished) ? 'Finished' : 'In Progress')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":"","color":(_vm.currentUser.cid === item.cid) ? 'primary': ''},on:{"click":function($event){return _vm.swapInstance(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s((_vm.currentUser.cid === item.cid) ? 'toggle_on' : 'toggle_off')+" ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s((_vm.currentUser.cid === item.cid) ? ("Turn off " + (item.companyName)) : ("Switch to " + (item.companyName) + " instance"))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.addUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("person_add")])],1)]}}],null,true)},[_vm._v(" Add User ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.editClient(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("edit")])],1)]}}],null,true)},[_vm._v(" Edit Client ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }