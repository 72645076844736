<template>
  <Loading v-if="loadingUser"/>
  <div class="background" v-else>
    <div class="dark-overlay">
      <v-card class="modal" style="text-align: left">
        <v-card-title class="headline">
            <img src="../../assets/buzz-icon-yellow.svg" alt="Buzz Solutions" class="buzz-logo">
            Buzz Solutions
        </v-card-title>
        <v-card-title style="color: grey; justify-content: center">
          Two Factor Authentication
        </v-card-title>

        <span v-if="!authType">
          <v-card-text style="text-align: left">
            <div style="text-align: center">Select method</div>
              <p class="text--primary text-center" v-if="noPhoneNumber">
                <span class="font-weight-regular red--text text-sm-caption">
                  No phone number is provided. Authentication by SMS is disabled
                </span>
              </p>
            <!-- </div> -->
            <v-radio-group class="authtype-radio-group" v-model="authType" row>
              <v-radio
                label="Phone"
                value="phone"
                :disabled="noPhoneNumber"
              ></v-radio>
              <v-radio
                label="Email"
                value="email"
              ></v-radio>
              <v-radio
                v-if="hasBackdoorAccess"
                label="Backdoor Code"
                value="backdoor"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
        </span>

        <span v-else>
          <div id="backdoor" v-if="authType === 'backdoor'">
            <v-card-subtitle class="subtitle">
              Please enter the 6 digit code given to you by your provider.
            </v-card-subtitle>
          </div>
          <div id="no-backdoor" v-else>
            <v-card-title class="subtitle">
            Sending code to: {{ authType === 'phone' ? formatPhone : email }}
            </v-card-title>
            <v-card-subtitle class="subtitle">
              Your code will only be active for 10 minutes
            </v-card-subtitle>
          </div>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Enter your code"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  single-line
                  outlined
                  v-model="code"
                  id="code-input"
                  class="mx-auto"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  @keydown.enter="twoFactorAuthenticate"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  block
                  @click="twoFactorAuthenticate"
                  id="two-fact-btn">
                  Authenticate
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  text
                  block
                  id="resend-btn"
                  :disabled="authType === 'backdoor'"
                  @click="resendCode()"
                >Resend Code</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </span>
      </v-card>
    </div>
    <SnackBar :snackbar="userNotifications.success" :text="userNotifications.message" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as lodash from 'lodash';
import Loading from '@components/common/Loading.vue';
import SnackBar from '@components/common/SnackBar.vue';

export default {
  name: 'TwoFactor',
  components: { SnackBar, Loading },
  data: () => ({
    authType: '',
    email: '',
    phoneNumber: '',
    code: '',
    errors: { message: '' },
    show: false,
    backdoor: false,
  }),
  methods: {
    ...mapActions(['setCurrentUser', 'getCompanyByDocRef', 'verifyTwoFactorAuth', 'getVerification', 'createVerification', 'updateVerification', 'getBackdoorAccess', 'getAllTeams']),
    async twoFactorAuthenticate() {
      const payload = {
        uid: this.currentUser.uid,
        code: this.code,
        verification: 'two-factor',
        authType: this.authType,
      };

      // Verify the code
      try {
        const res = await this.verifyTwoFactorAuth(payload);

        // If response has a status of 200, get company from user's company document
        if (res.status === 200) {
          const { company } = this.currentUser;
          this.getCompanyByDocRef(company)
            .then((response) => {
              this.getAllTeams(response.cid)
                .then(() => {
                  this.$router.push('/projects/view/grid');
                });
            });
        }
      // eslint-disable-next-line no-console
      } catch (err) { console.log(err); }
    },
    async resendCode() {
      let updatedVerification;
      try {
        await this.getVerification(this.currentUser.sid);
      } catch (err) {
        const payloadUpdate = { uid: this.currentUser.uid, verification: 'two-factor' };
        // Cancel the current verification
        updatedVerification = await this.updateVerification(payloadUpdate);
      }
      if (updatedVerification.status === 200) {
        await this.createVerification({
          uid: this.currentUser.uid,
          channel: this.authType === 'phone' ? 'sms' : 'email',
          verification: 'two-factor',
        });
      }
    },
    async sendCode() {
      // Set payload to make a call to endpoint /api/auth/:verificationType/:uid
      // with request body of { channel }
      const payload = {
        uid: this.currentUser.uid,
        channel: this.authType === 'phone' ? 'sms' : 'email',
        verification: 'two-factor',
      };

      if (this.currentUser.sid) {
        try {
          await this.getVerification({
            verification: 'two-factor',
            sid: this.currentUser.sid,
          });
        } catch (err) {
          await this.createVerification(payload);
        }
      } else {
        this.createVerification(payload);
      }
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'userNotifications', 'loggedIn', 'loadingUser', 'hasBackdoorAccess']),
    formatPhone() {
      const sections = lodash.split(this.phoneNumber, '-');
      return sections.length > 1
        ? `(***)-***-${sections[sections.length - 1]}` // usa format
        : this.phoneNumber
          .replace(/\d/g, '*')
          .slice(0, this.phoneNumber.length - 4) + this.phoneNumber.slice(-4);
    },
    noPhoneNumber() {
      return !this.currentUser.phone;
    },
    showBackdoorCodeCheckbox() {
      return Object.keys(this.currentUser).includes('backDoorAccess') && this.currentUser.backDoorAccess;
    },
  },
  async created() {
    if (this.loggedIn) return;

    let user;

    const { uid } = this.$router.history.current.params;

    if (!this.currentUser) user = await this.setCurrentUser(uid);
    else user = this.currentUser;

    if (user.phone !== '') this.phoneNumber = user.phone;
    if (user.email) this.email = user.email;

    const hasBackdoorAccess = await this.getBackdoorAccess(uid);
    if (!hasBackdoorAccess) {
      this.phoneNumber = user.phone;
      this.email = user.email;
      this.authType = this.$route.query.authType;
    }
  },
  watch: {
    authType(val) {
      if (val) {
        if (val !== 'backdoor' && val !== '') this.sendCode();

        if (this.$route.query.authType !== val) {
          this.$router.push({
            query: { ...this.$route.query, authType: val },
          });
        }
      }
    },
    $route(route) {
      this.authType = route.query.authType;
    },
  },
};
</script>

<style>
.authtype-radio-group .v-input--radio-group__input  {
  justify-content: center;
}
</style>

<style scoped>
.background {
  text-align: center;
  width: 100%;
  position: relative;
  background: url('../../assets/washed-out-wires.png') no-repeat;
  background-size: cover;
  height: calc(100vh - 60px);
  margin-bottom: -50px;
  -o-backgorund-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
}
.dark-overlay {
  background-color: rgb(0,0,0,0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal {
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 100%;
  max-width: 500px;
}
.headline {
  justify-content: center;
  /* color: grey; */
  font-weight: 500;
}
.buzz-logo {
  width: 75px;
  margin-right: 10px;
}
</style>
