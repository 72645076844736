<template>
  <div class="image-upload">
    <form>
      <vue-dropzone
        ref="directDropzone"
        id="drop"
        :options="dropzoneOptions"
        @vdropzone-upload-progress="onUploadProgress"
        @vdropzone-total-upload-progress="totalUploadProgress"
        @vdropzone-sending="beforeSend"
        @vdropzone-files-added="handleAddedFiles"
        @vdropzone-queue-complete="onQueueComplete"
        style="max-height: 500px; overflow: scroll;"
      ></vue-dropzone>
      <v-progress-linear
        indeterminate
        color="primary"
        v-if="uploadingImages"
      ></v-progress-linear>
      <p class="text-center">
        {{ (uploadingImages) ?
          'Give us a moment to finish up the upload'
          : `${totalProgress.toFixed(2)} %`}}
      </p>
    </form>
  </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import { baseURL } from '@utils/endpoint';

export default {
  name: 'MetaDataUploader',
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: ['cid', 'pid'],
  data() {
    return {
      cloudsvg: '../../../../../static/img/upload.svg',
      fillColor: 'LightSkyBlue',
      totalProgress: 0,
      fileListLength: 0,
      dropzoneOptions: {
        paramName: 'file',
        acceptedFiles: '.csv,.json,.xml,.kml,.xlx,.xlsx',
        url: 'https://storage.googleapis.com/',
        thumbnailWidth: 200,
        addRemoveLinks: true,
        dictDefaultMessage: "<div class='v-list-item__icon'><i aria-hidden='true' class='v-icon notranslate material-icons theme--light light-blue--text text--lighten-3' style='font-size:200px;'>cloud_upload</i></div>",
        createImageThumbnails: false,
        autoProcessQueue: false,
        parallelUploads: 5,
        timeout: 3600 * 1000,
        maxFilesize: null,
      },
      filePolicies: {},
    };
  },
  methods: {
    ...mapActions([
      'updateProject',
      'setNewImageAmount',
      'setNotification',
      'setUploading',
      'getProject',
      'createMeta',
    ]),
    // eslint-disable-next-line no-unused-vars
    totalUploadProgress(totaluploadprogress, totalBytes, totalBytesSent) {
      if (totaluploadprogress) this.totalProgress = totaluploadprogress;
    },
    // eslint-disable-next-line no-unused-vars
    onUploadProgress(file, progress, bytesSent) {
      this.uploadProgress = Number.parseFloat(progress).toFixed(2);
    },
    // eslint-disable-next-line no-unused-vars
    async beforeSend(file, xhr, formData) {
      const params = this.filePolicies[file.name].fields;
      formData.append('Content-Type', file.type);
      Object.keys(params).forEach((p) => {
        formData.append(p, params[p]);
      });
    },
    async handleAddedFiles(fileObjects) {
      const files = [];
      fileObjects.forEach((file) => {
        files.push({
          contentType: file.type,
          fileName: `${this.folderPath}/${file.name}`,
        });
      });
      const policies = await axios.post(`${baseURL}/api/upload/signed_url`, {
        bucketName: this.currentCompany.bucket,
        files,
      });
      this.filePolicies = { ...this.filePolicies, ...policies.data };
      this.$refs.directDropzone.processQueue();
      const elementsToModify = ['dz-image', 'dz-details', 'dz-progress', 'dz-success-mark'];
      elementsToModify.forEach((className) => this.noZIndex(className));
    },
    async onQueueComplete() {
      const uploadedFiles = this.$refs.directDropzone.getAcceptedFiles().filter((file) => file.status === 'success');
      if (uploadedFiles.length === 0) {
        return;
      }

      const metaDataObjects = uploadedFiles.map((meta) => ({
        company: this.currentCompany.cid,
        project: this.currentProject.pid,
        url: `${this.uploadUrl}${this.folderPath}/${meta.name}`,
        date: Date.now(),
      }));

      const response = await this.createMeta({ metaDataObjects });

      if (response.status === 201) {
        this.setUploading(false);
        this.setNotification({
          success: true,
          message: `${metaDataObjects.length} metadata have been uploaded to the ${this.currentProject.name} project`,
        });
      }
    },
    noZIndex(className) {
      const elements = document.getElementsByClassName(className);
      elements.forEach((element) => element.setAttribute('style', 'z-index: 0'));
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'currentProject', 'allImages', 'uploadingImages', 'currentCompany']),
    folderPath() {
      return `metadata/${this.currentProject.pid}`;
    },
    uploadUrl() {
      return `https://storage.googleapis.com/${this.currentCompany.bucket}/`;
    },
  },
  mounted() {
    this.$refs.directDropzone.setOption('url', this.uploadUrl);
  },
  created() {
    const { pid } = this;
    if (Object.keys(this.currentProject).length === 0) this.getProject(pid);
  },
};
</script>

<style scoped>
.display-none{
  display: none;
}
</style>
