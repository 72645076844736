<template>
  <Loading v-if="loadingCurrentImage"/>
  <v-container fluid v-else>
    <v-row>
      <v-col cols="12">
        <ImageToolBar />
      </v-col>
      <v-col xs="12" sm="12" md="7" lg="7" xl="7">
        <CarouselController
          :images="folderImages"
          :currentImage="currentImage"
          type="original"
          :cid="currentCompany.cid"
          v-on:imageIndex="getCurrentImageIndex"
          ref="child"
        />
      </v-col>
      <v-col xs="12" sm="12" md="5" lg="5" xl="5">
        <ImageDetails type="original" />
        <SimpleFileManager :images="folderImages" type="original" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import CarouselController from '@components/images/CarouselController.vue';
import ImageToolBar from '@components/images/utilities/ImageToolBar.vue';
import SimpleFileManager from '@components/folders/SimpleFileManager.vue';
import ImageDetails from '@components/images/image_details/ImageDetails2.vue';
import Loading from '@components/common/Loading.vue';
import helpers from '@methods/helpers';

export default {
  name: 'OriginalImagesContent',
  data: () => ({
    currentIndex: 0,
    files: [],
    uploader: false,
    modalOpen: false,
  }),
  props: [],
  components: {
    Loading,
    CarouselController,
    ImageToolBar,
    ImageDetails,
    SimpleFileManager,
  },
  methods: {
    ...mapActions(['setCurrentImage']),
    getCurrentImageIndex(index) {
      if (this.allImages.length === 0) {
        return;
      }
      this.currentIndex = index;
    },
    showUploader() {
      this.uploader = !this.uploader;
    },
  },
  computed: {
    ...mapGetters([
      'allImages',
      'currentImageFilter',
      'currentCompany',
      'loadingImages',
      'currentImage',
      'currentProject',
      'currentFolder',
      'loadingCurrentImage',
      'currentSeverityFilters',
    ]),
    uploadBtncolor() {
      return (this.uploader) ? 'error' : 'primary';
    },
    uploadBtnText() {
      return (this.uploader) ? 'Hide Uploader' : 'Show Uploader';
    },
    displayedImages() {
      const { allImages, currentImageFilter } = this;
      const { type } = currentImageFilter;
      const images = [...allImages].map((image) => {
        const img = { ...image };
        img.createdAt = helpers.convertToDate(img.date).format('YYYY-MM-DD');
        img.url = img.originalImageUrl;
        return img;
      });

      if (type === '') return images;
      const filtered = images.filter((image) => {
        const { start, end } = currentImageFilter;
        return helpers.withinTimeFrame(image.createdAt, start, end);
      });

      return filtered;
    },
    folderImages() {
      const filterBySeverity = (images) => {
        if (this.currentSeverityFilters.length < 1) return images;

        return images.filter((image) => image.processedImageUrl).filter((image) => {
          const current = cloneDeep(image);

          const [processedData] = current.process_tracking.slice(-1);
          return this.currentSeverityFilters.includes(processedData.severity);
        });
      };
      if (!this.currentFolder.path) return filterBySeverity(this.displayedImages);
      if (this.currentFolder.path === '__all__') return filterBySeverity(this.displayedImages);

      const imagesInFolder = this.displayedImages
        .filter((e) => e.folder === this.currentFolder.path);

      return filterBySeverity(imagesInFolder);
    },
  },
  async created() {
    const { filename, pid } = this.$route.params;
    const { cid } = this.currentCompany;

    // Uses the route params to filter the specific current image and sets it
    const [current] = this.allImages.filter((image) => image.filename === filename
      && pid === image.project_id
      && cid === image.companyId);

    if (current === undefined) {
      const firstImg = `/project/${this.currentProject.pid}/images/original/${this.allImages[0].filename}`;
      this.$router.push(firstImg);
      return;
    }
    this.setCurrentImage(current);
  },
  watch: {
    folderImages(fImages) {
      if (this.$route.name !== 'OriginalImages') return;
      if (fImages.length > 0 && !this.modalOpen && !this.currentImage) {
        this.setCurrentImage(fImages[0]);
      }
    },
  },
  mounted() {
    this.$watch(
      '$refs.child.dialog',
      (newValue) => {
        this.modalOpen = newValue;
      },
    );
  },
};
</script>
