import axios from 'axios';
import { baseURL } from '@utils/endpoint';

const mailer = {
  notifyReviewer: (payload, type) => new Promise((resolve, reject) => {
    axios.post(`${baseURL}/api/email/${type}`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  }),
};

export default mailer;
