<template>
  <v-data-table
    :headers="headers"
    :items="allCompanies"
    class="elevation-1"
    :loading="loadingCompanies || updatingUser"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Company Instances</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  color="primary"
                  class="mb-2"
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...dialog }"
                  icon
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </template>
              Create Company Instance
            </v-tooltip>
          </template>
          <CreateInstanceForm :company="editedCompany" v-on:close="closeForm"/>
        </v-dialog>
        <v-dialog v-model="userDialog" max-width="750px">
          <v-card>
            <v-card-title class="headline">
              Create new User: {{ editedCompany.companyName }}
            </v-card-title>
            <v-divider></v-divider>
            <CreateUserForm v-on:close="closeUserForm" v-on:save="saveUser" :errors="errors"/>
          </v-card>
        </v-dialog>
        <v-dialog v-model="editDialog" max-width="750px">
          <EditCompanyForm
            :company="editedCompany"
            @close="editDialog = false"
            :key="editedCompany.cid"
          />
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.finished`]="{ item }">
      <v-chip x-small :color="(item.finished) ? 'green' : 'secondary'" text-color="white">
        {{ (item.finished) ? 'Finished' : 'In Progress' }}
      </v-chip>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            x-small
            :color="(currentUser.cid === item.cid) ? 'primary': ''"
            v-on="on"
            v-bind="attrs"
            @click="swapInstance(item)"
          >
            <v-icon class="mr-2">
              {{ (currentUser.cid === item.cid) ? 'toggle_on' : 'toggle_off' }}
            </v-icon>
          </v-btn>
        </template>
        {{
          (currentUser.cid === item.cid)
          ? `Turn off ${item.companyName}` : `Switch to ${item.companyName} instance`
        }}
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            x-small
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="addUser(item)"
          >
            <v-icon class="mr-2">person_add</v-icon>
          </v-btn>
        </template>
        Add User
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            x-small
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="editClient(item)"
          >
            <v-icon class="mr-2">edit</v-icon>
          </v-btn>
        </template>
        Edit Client
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import CreateInstanceForm from '@components/admin/CreateInstanceForm.vue';
import CreateUserForm from '@components/admin/CreateUserForm.vue';
import EditCompanyForm from '@components/admin/EditCompanyForm.vue';
import helpers from '@methods/helpers';

export default {
  name: 'CompanyTable',
  components: {
    CreateInstanceForm,
    CreateUserForm,
    EditCompanyForm,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    userDialog: false,
    editDialog: false,
    headers: [
      { text: 'Company', value: 'companyName' },
      { text: 'Date Created', value: 'createdAt' },
      { text: 'Images Processed', value: 'images_processed' },
      { text: 'User Amount', value: 'employees.length' },
      { text: 'Model', value: 'ml_model' },
      { text: 'Actions', value: 'actions' },
    ],
    editedCompany: {},
    errors: {},
  }),
  computed: {
    ...mapGetters(['loadingCompanies', 'allCompanies', 'currentUser', 'updatingCompany', 'updatingUser']),
  },
  methods: {
    ...mapActions(['updateUser', 'getCompany', 'updateJWTToken', 'addUserToInstance']),
    closeForm() {
      this.dialog = false;
    },
    swapInstance(company) {
      // Clone the user getter
      const user = cloneDeep(this.currentUser);

      // If the clicked company cid matches the currentUser cid,
      // then just retrieve the id of the company document
      // and reset it back to the original BuzzSolutions cid
      user.cid = (company.cid === this.currentUser.cid)
        ? this.currentUser.originalCid : company.cid;
      user.company = helpers.getDocReference('company', user.cid);

      // Update the user document with the new cid
      this.updateUser(user)
        .then(() => {
          // Update the JWT to encode with the new 'cid'
          this.updateJWTToken(user.uid)
            .then((decoded) => {
              // Fetch company
              this.getCompany(decoded.company_id);
            });
        });
    },
    // Opens the "Create User Form"
    addUser(company) {
      this.editedIndex = this.allCompanies.indexOf(company);
      this.editedCompany = { ...company };
      this.userDialog = true;
    },
    editClient(client) {
      this.editedCompany = { ...client };
      this.editDialog = true;
    },
    // Closes the "Create User Form"
    closeUserForm() {
      this.userDialog = false;
    },
    // Saves User to DB
    saveUser(newUser) {
      const payload = { cid: this.editedCompany.cid, newUser };
      this.addUserToInstance(payload)
        .then((res) => {
          if (res.status === 200) {
            this.closeUserForm();
          }
        })
        .catch((err) => {
          if (err.response.data.code) {
            this.errors.email = err.response.data.message;
          } else {
            this.errors = err.response.data;
          }
        });
    },
  },
};
</script>
