<template>
  <div id="no-projects-content" style="height=100%">
    <v-container fluid class="text-center">
      <v-sheet class="px-4 pt-3 pb-3">
        <div v-text="noProjectTitle" class="text-h2 font-weight-medium"></div>
        <v-dialog v-model="dialog" max-width="700px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text color="primary" v-on="on" v-bind="attrs">
              Please add your first project to get started
            </v-btn>
          </template>
          <CreateProjectForm
            :cid="currentCompany.cid"
            :teams="allTeams"
            v-on:closeDialog="close"
          />
        </v-dialog>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CreateProjectForm from '@components/projects/CreateProjectForm.vue';

export default {
  name: 'NoProjects',
  components: {
    CreateProjectForm,
  },
  data: () => ({
    dialog: false,
    noProjectTitle: 'No Projects available',
    noProjectSubtitle: 'Please add your first projects to get started',
  }),
  methods: {
    close() {
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters(['currentCompany', 'allTeams']),
  },
};
</script>
