<template>
  <v-app>
    <Loading v-if="loadingUserContent"/>
    <div v-else>
      <NewSideBar
        :drawerShown="drawer"
        v-if="loggedIn"
        v-on:drawerChange="drawerChange"
      />
      <Navbar
        v-on:toggleSidebar="toggleSidebarSize"
        :isMobile="resizeScreen(window.width)"
        :drawerStatus="drawer"
      />
      <v-main class="scroll-y">
        <v-container fluid class="pa-0 d-print-block">
          <router-view v-show="!loadingUserContent"></router-view>
        </v-container>
      </v-main>
      <SnackBar
        :snackbar="currentNotification.success"
        :text="currentNotification.message"
      />
    </div>
    <!-- P R O G R E S S  B A R -->
    <div id="progress-bar" width="100%" ref="progress" v-show="showProgressBar"></div>
    <v-bottom-sheet
      flat
      v-model="sheet"
      :retain-focus="false"
      dark
      persistent
      no-click-animation
      hide-overlay
      max-width="400px"
      attach="#progress-bar"
      eager
    >
      <v-divider></v-divider>
      <v-expansion-panels v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            Progress
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ProgressBar v-on:close="closeProgressBar" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-bottom-sheet>
    <GeneralProgressBar />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Navbar from '@components/layouts/Navbar.vue';
import NewSideBar from '@components/layouts/NewSideBar.vue';
import SnackBar from '@components/common/SnackBar.vue';
import Loading from '@components/common/Loading.vue';
import ProgressBar from '@components/common/ProgressBar.vue';
import GeneralProgressBar from '@components/common/GeneralProgressBar.vue';
import validateAuthentication from '@utils/authentication';
import setAuthToken from '@utils/auth';
import firebase from '@database';
import { baseProcessUrl } from '@utils/endpoint';
import jwtDecode from 'jwt-decode';

export default {
  name: 'App',
  components: {
    Navbar,
    NewSideBar,
    Loading,
    SnackBar,
    ProgressBar,
    GeneralProgressBar,
  },
  data: () => ({
    sideBarMini: false,
    drawer: true,
    window: { width: 0, height: 0 },
    sheet: true,
    panel: 0,
  }),
  methods: {
    ...mapActions([
      'getCompany',
      'logout',
      'setCurrentUser',
      'setLoadingCompany',
      'handleScreenSize',
      'getCustomer',
      'setLoadingCustomer',
      'getAllTeams',
      'updateTaskProgress',
      'getTasks',
      'longPollProgress',
      'setProcessingImages',
    ]),
    drawerChange(drawerStatus) { this.drawer = drawerStatus; },
    toggleSidebarSize(drawer) { this.drawer = drawer; },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      this.handleScreenSize(this.window);
    },
    resizeScreen(size) { return size <= 992; },

    // Positions the progress bar on `mounted` to the right corner
    positionProgressBar() {
      const progressBar = this.$refs.progress;
      progressBar.children[0].style = 'justify-content: flex-end;';
    },

    // Clears the progress bar
    closeProgressBar() {
      this.updateTaskProgress({});
    },
  },
  watch: {
    $route(newRoute) {
      const { name } = newRoute;
      const hasImages = (name === 'ProcessImages')
        || (name === 'OriginalImages')
        || (name === 'Analytics')
        || (name === 'Insights');
      if (hasImages) this.drawer = false;
    },
  },
  computed: {
    ...mapGetters([
      'loggedIn',
      'loadingUser',
      'loadingCompany',
      'loadingCustomer',
      'loadingTeams',
      'processingImage',
      'currentProgress',
      'currentNotification',
      'processingImage',
      'allInProgressTasks',
    ]),
    loadingUserContent() {
      const {
        loadingUser,
        loadingCompany,
        loadingCustomer,
        loadingTeams,
      } = this;

      let loading;

      if (this.loggedIn) {
        loading = loadingCompany || loadingTeams || loadingCustomer || loadingUser;
      } else loading = loadingCompany || loadingTeams || loadingCustomer;
      return loading;
    },
    showProgressBar() {
      const show = (this.processingImage || Object.keys(this.currentProgress).length > 0)
      || this.currentProgress.status === 'Success'
      || this.allInProgressTasks.length > 0;

      return show;
    },
  },
  mounted() {
    const { name } = this.$route;
    if (name === 'ProcessedImages' || name === 'OriginalImages') this.drawer = false;

    // Immediately postion the progress bar to the right
    // since Vuetify doesn't offer any properties to do so
    this.positionProgressBar();
  },
  async created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    const firstFactor = firebase.auth().currentUser;
    const secondFactor = localStorage.jwtToken !== undefined;

    // Initial check for 2FA, since intervals does not fire off initially
    if (!validateAuthentication(localStorage.jwtToken)) {
      // eslint-disable-next-line no-console
      this.logout()
        .then((status) => {
          if (status === 200) this.$router.push('/login');
        });
      return;
    }
    const tokenPayload = jwtDecode(localStorage.jwtToken);
    // eslint-disable-next-line max-len
    const UUIDregexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    if (tokenPayload.company_id && UUIDregexExp.test(tokenPayload.company_id)) {
      console.log('invalid token. redirecting to login');
      localStorage.removeItem('jwtToken');
      this.logout()
        .then(() => this.$router.push('/login'));
      return;
    }
    // Every half hour check if the user is still authenticated
    const intervalId = setInterval(() => {
      if (!validateAuthentication(localStorage.jwtToken)) {
        this.logout()
          .then(() => this.$router.push('/login'));
      }
    }, (3600000 / 2));

    // If the old token 'buzzToken' exists, just log out and have them login again
    if (localStorage.getItem('buzzToken')) {
      this.logout().then(() => this.$router.push('/login'));
      return;
    }

    if (secondFactor && firstFactor) {
      setAuthToken(localStorage.jwtToken);

      this.setLoadingCompany();
      this.setLoadingCustomer();
      const userData = await this.setCurrentUser(firebase.auth().currentUser.uid);

      const company = await this.getCompany(userData.cid);
      await this.getAllTeams(userData.cid);

      if (Object.keys(company).includes('customerId')) await this.getCustomer(company.customerId);

      const tasks = await this.getTasks(firebase.auth().currentUser.uid);

      const taskIds = Object.keys(tasks);

      if (taskIds.length > 0) {
        const requestPayload = { type: 'GET', headers: { Authorization: localStorage.jwtToken } };

        taskIds.forEach((taskId) => {
          requestPayload.url = `${baseProcessUrl}/batch_process/result/${taskId}`;
          requestPayload.taskId = taskId;
          this.longPollProgress(requestPayload);
        });
      }

      // else this user is not a customer yet. handle this case
    } else if (firstFactor && !secondFactor) await this.setCurrentUser(firstFactor.uid);
    else if (this.loggedIn) {
      this.logout().then(() => {
        this.$router.push('/login');
        clearInterval(intervalId);
      });
    }
  },
};
</script>

<style>
  html { overflow-y: auto; }
  @media print {
    body,
    html { overflow: auto; height: auto; }
    .no-print { display: none; }
  }
</style>
