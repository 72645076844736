<template>
  <v-container fluid ref="top">
    <v-overlay :value="retrievingData">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <p>Retrieving {{ retrievingDataType }} Data...</p>
    </v-overlay>
    <SkeletonLoader type='insights' v-if='loadingProjects || loadingProject' />
    <div v-else>
      <InsightsContent :pid="pid" />
      <ScrollToTop
        v-if="!loadingProject && !loadingProjects"
        :target="target"
        :options="options">
      </ScrollToTop>
      <SnackBar :snackbar="imageNotification.success" :text="imageNotification.message" />
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SkeletonLoader from '@components/common/loaders/SkeletonLoader.vue';
import InsightsContent from '@components/analytics/insights/InsightsContent.vue';
import ScrollToTop from '@components/common/ScrollToTop.vue';
import SnackBar from '@components/common/SnackBar.vue';
import { flattenArray, uniqueArrayBy } from '@methods/helpers';

export default {
  name: 'NfInsights',
  props: ['pid'],
  data: () => ({
    target: {},
    options: {
      offset: 0,
      easing: 'easeInOutCubic',
      duration: 300,
    },
  }),
  components: {
    SkeletonLoader,
    ScrollToTop,
    InsightsContent,
    SnackBar,
  },
  methods: {
    ...mapActions(['getAllProjectsByCompanyId', 'setProjectsLoading', 'finishProjectLoad', 'getProjectsByDocRefs']),
    async fetchProject() {
      this.setProjectsLoading();
      const { cid, adminStatus, teams } = this.currentUser;

      // If user is admin, you can automatically retrieve all projects by CID
      if (adminStatus) await this.getAllProjectsByCompanyId(cid);
      else {
        let userTeams;

        if (this.allTeams.length < 1) {
          // If the user is logging in for the first time,
          // App.vue has already rendered, so it won't fetch again.
          // If there are no teams loaded, manually do so.
          const teamsDocumentSnapshot = await Promise.all(teams.map((team) => team.get()));
          userTeams = teamsDocumentSnapshot.map((snapshot) => snapshot.data());
        } else {
          // Otherwise, get a list of team IDs
          // And retrieve a list of user teams
          const userTeamIds = teams.map((team) => team.id);
          userTeams = this.allTeams.filter((team) => userTeamIds.includes(team.tid));
        }
        const userProjects = uniqueArrayBy(flattenArray(userTeams.map((t) => t.projects), 'id'));
        const projectResponses = await this.getProjectsByDocRefs(userProjects);
        if (projectResponses.length === userProjects.length) this.finishProjectLoad();
      }
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'allProjects', 'currentProject', 'loadingProject', 'loadingProjects', 'currentProject', 'imageNotification', 'retrievingImageData', 'retrievingFolderData']),
    retrievingData() {
      return this.retrievingImageData || this.retrievingFolderData;
    },
    retrievingDataType() {
      if (this.retrievingFolderData) return 'Folder';
      if (this.retrievingImageData) return 'Image';
      return '';
    },
  },
  watch: {
    pid(newPid) { this.fetchProject(newPid); },
  },
  mounted() { this.target = this.$refs.top; },
  async created() { await this.fetchProject(this.pid); },
};
</script>
