import axios from 'axios';
import { cloneDeep, chunk, flatten } from 'lodash';
import * as JSZip from 'jszip';
import { annotateImage } from '@components/annotations/fabric-renderer';

export const processOriginalImages = async (data, actions) => {
  const { images, company, pid } = data;
  const { addCancelToken, processImages } = actions;

  const files = images.map((image) => {
    const { filename, location, date } = cloneDeep(image);
    const [latitude, longitude] = location;

    return { filename, location: [latitude, longitude], date };
  });

  const processDatas = chunk(files, 3)
    .map((batch) => ({
      files: batch,
      project_id: pid,
      bucket_name: company.bucket,
      folder_path: `original/${pid}`,
      company_id: company.cid,
      user_id: data.uid,
    }))
    .map((pData) => {
      const cancelToken = axios.CancelToken.source();
      addCancelToken(cancelToken);
      return { processData: pData, cancelToken };
    });
  const promises = await processDatas.map(async (pData) => processImages(pData));
  const response = await Promise.all(promises);

  return flatten(response);
};

export const batchProcess = async (data, actions) => {
  const files = data.images.map((image) => {
    const { filename, location, date } = cloneDeep(image);
    const [latitude, longitude] = location;

    return { filename, location: [latitude, longitude], date };
  });

  const cancelToken = axios.CancelToken.source();
  actions.addCancelToken(cancelToken);

  const processData = {
    files,
    project_id: data.pid,
    bucket_name: data.company.bucket,
    folder_path: `original/${data.pid}`,
    company_id: data.company.cid,
    user_id: data.uid,
  };

  if (data.models) processData.models = data.models;

  const response = await actions.batchProcessImages({ processData, cancelToken });
  return response;
};

export const downloadImage = (blob, filename, isUrl = false) => {
  const url = isUrl ? blob : URL.createObjectURL(blob);
  // Create a new anchor element
  const a = document.createElement('a');

  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  a.href = url;
  a.download = filename || 'download';

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };

  // Add the click event listener on the anchor element
  // Comment out this line if you don't want a one-off download of the blob content
  a.addEventListener('click', clickHandler, false);

  // Programmatically trigger a click on the anchor element
  // Useful if you want the download to happen automatically
  // Without attaching the anchor element to the DOM
  // Comment out this line if you don't want an automatic download of the blob content
  a.click();

  // Return the anchor element
  // Useful if you want a reference to the element
  // in order to attach it to the DOM or use it in some other way
  return a;
};

export const getAnnotatedFile = (image) => new Promise((resolve, reject) => {
  annotateImage(image)
    .then((dataUrl) => {
      if (dataUrl !== false) {
        const [, b64] = dataUrl.split(',');
        resolve(b64);
      } else reject();
    })
    .catch((err) => reject(err));
});

export const downloadAsZip = (dataList, type) => {
  const zip = new JSZip();

  // Iterate through each image data and zip it up
  dataList.forEach((data) => {
    // Filename will either be buzz-{filename} or buzz-processed-{filename}
    const filename = (type === 'original') ? `buzz-${data.image.filename}` : `buzz-processed-${data.image.filename}`;

    // Zip the file
    zip.file(filename, data.blob, { base64: true });
  });

  // Asynchronously return content in Promise
  return new Promise((resolve, reject) => {
    zip.generateAsync({ type: 'blob' })
      .then((content) => { resolve(content); })
      .catch((err) => reject(err));
  });
};

export const getImagePayload = (image, updates, updateType) => {
  const payload = {
    filename: image.filename,
    companyId: image.companyId,
    project_id: image.project_id,
    date: image.date,
    user_id: image.user_id,
    update: {},
  };

  const { length } = image.process_tracking;
  payload.update.process_tracking = image.process_tracking;

  if (updateType === 'reviewed') payload.update.process_tracking[length - 1].reviewed = updates.reviewedVal;
  else if (updateType === 'priority') payload.update.process_tracking[length - 1].priority = updates.priorityVal;
  else if (updateType === 'severity') payload.update.process_tracking[length - 1].severity = updates.severity;

  return payload;
};

export const filterVegetationEncroachment = (annotations) => {
  const annos = cloneDeep(annotations);

  return annos.filter((anno) => {
    const currentAnno = cloneDeep(anno);
    // Filters for the labeling body and destructures the array
    // and checks if the string includes Vegetation Encroachment
    const { body } = currentAnno;

    const [labelingBody] = body.filter((b) => b.purpose === 'labeling');

    // Only return the annos if it does not include Vegetation Encroachment
    return !labelingBody.value.includes('Vegetation Encroachment');
  });
};

export const getHighestSeverity = (labels) => {
  const severities = ['None', 'Low', 'Medium', 'High'];
  const indexes = labels.map((l) => (l.severity ? severities.indexOf(l.severity) : -1));
  return severities[Math.max(...indexes)];
};
