<template>
  <v-container fluid>
    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">Project</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, name) in projectContent" :key="name">
              <td>{{capitalizeFirstLetterOfName(name)}}</td>
              <td>{{value}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { upperFirst } from 'lodash';

export default {
  name: 'ProjectDataTable',
  props: ['project'],
  data: () => ({
    currentProject: {},
  }),
  methods: {
    capitalizeFirstLetterOfName: (name) => upperFirst(name),
  },
  computed: {
    ...mapGetters(['companyHas']),
    projectContent() {
      const { teams } = this.project;
      const project = {
        date: this.project.date,
        name: this.project.name,
        description: (this.project.description) ? this.project.description : 'No description was entered',
        location: (this.project.location) ? this.project.location : 'No location specified',
        teams: teams.map((team) => team.name).join(', '),
      };

      project.reviewer = (this.project.first_reviewer) ? this.project.first_reviewer.name : 'No reviewer assigned';

      project['project type'] = (this.project.project_type) ? this.project.project_type : 'This project has not been assigned a project type yet';
      return project;
    },
  },
};
</script>
