var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-app-bar',{attrs:{"color":"white","fixed":""}},[_c('v-toolbar-items',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","id":"trash-btn","icon":""},on:{"click":_vm.removeAnnotation}},on),[_c('v-icon',[_vm._v("fas fa-trash")])],1)]}}])},[_vm._v(" Remove Annotation ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","id":"rectangle-btn","icon":""},on:{"click":function($event){return _vm.setMode('rect')}}},on),[_c('v-icon',[_vm._v("crop_3_2")])],1)]}}])},[_vm._v(" Draw Rectangle ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","id":"polygon-btn","icon":""},on:{"click":function($event){return _vm.setMode('polygon')}}},on),[_c('v-icon',[_vm._v("fas fa-draw-polygon")])],1)]}}])},[_vm._v(" Draw Polygon ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","id":"visible-btn","icon":""},on:{"click":_vm.setVisible}},on),[_c('v-icon',[_vm._v(_vm._s((_vm.visibility) ? 'visibility_off' : 'visibility'))])],1)]}}])},[_vm._v(" "+_vm._s((_vm.visibility) ? 'Hide Annotations' : 'Show Annotations')+" ")]),(_vm.aiProcessed)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"human-in-loop-thumbs-up-btn","color":"primary","icon":""},on:{"click":function($event){return _vm.updateFeedback(1)}}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.aiFeedback === 1 ? 'mdi-thumb-up' : 'mdi-thumb-up-outline')+" ")])],1)]}}],null,false,3846283541)},[_vm._v(" "+_vm._s(_vm.aiFeedback === 1 ? 'Reset AI Feedback' : 'The AI detected labels are accurate')+" ")]):_vm._e(),(_vm.aiProcessed)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"human-in-loop-thumbs-down-btn","color":"primary","icon":""},on:{"click":function($event){return _vm.updateFeedback(-1)}}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.aiFeedback === -1 ? 'mdi-thumb-down' : ' mdi-thumb-down-outline')+" ")])],1)]}}],null,false,221727938)},[_vm._v(" "+_vm._s(_vm.aiFeedback === -1 ? 'Reset AI Feedback' : 'The AI detected labels are not accurate')+" ")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","id":"save-btn","icon":""},on:{"click":_vm.save}},on),[_c('v-icon',[_vm._v("save")])],1)]}}])},[_vm._v(" Save Annotations ")]),(_vm.changeDetected && !_vm.companyHas('annoAutoSave'))?_c('v-dialog',{attrs:{"id":"ai-unsaved-changes","width":"50%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","id":"ai-unsave-confirm-btn","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,2338423898),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('UnsavedAnnotationsConfirm',{attrs:{"unsavedAmount":_vm.amountOfChanges},on:{"closeConfirm":_vm.closeConfirm,"closeAnnotate":_vm.close}})],1):_c('v-btn',{attrs:{"id":"human-in-loop-close-btn","color":"primary","icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-spacer'),_c('v-toolbar-items',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":_vm.shouldZoom ? 'primary' : 'grey',"id":"search-minus-btn","icon":""},on:{"click":_vm.selectZoom}},on),[_c('v-icon',[_vm._v("fas fa-arrows-alt")])],1)]}}])},[_vm._v(" Select Zoom ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","id":"search-minus-btn","icon":""},on:{"click":_vm.resetSize}},on),[_c('v-icon',[_vm._v("fas fa-search")])],1)]}}])},[_vm._v(" Reset ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","id":"search-minus-btn","icon":""},on:{"click":_vm.zoomOut}},on),[_c('v-icon',[_vm._v("fas fa-search-minus")])],1)]}}])},[_vm._v(" Zoom Out ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","id":"search-plus-btn","icon":""},on:{"click":_vm.zoomIn}},on),[_c('v-icon',[_vm._v("fas fa-search-plus")])],1)]}}])},[_vm._v(" Zoom In ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.companyHas('timeLogs'))?_c('v-btn',_vm._g({attrs:{"color":"primary","id":"pause-btn","icon":""},on:{"click":_vm.pauseTimer}},on),[_c('v-icon',[_vm._v("pause_circle_filled")])],1):_vm._e()]}}])},[_vm._v(" Pause ")])],1),(_vm.isAnnotified)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("check_circle")]):_vm._e(),_c('v-spacer'),_c('v-toolbar-items',[_c('SeverityComboBox')],1),_c('v-toolbar-title',[_vm._v(" Edit Annotations")])],1),_c('ImageEditor',{ref:"editor",attrs:{"visibility":_vm.visibility,"size":_vm.imageSize,"tab":(_vm.editMode || _vm.$route.meta.editMode) ? 'ai' : 'original'},on:{"navigated":_vm.handleNavigation,"zoomSelection":_vm.handleSelectZoom}}),_c('v-overlay',{attrs:{"value":_vm.savingAnnotations}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}}),_c('p',[_vm._v("Saving changes")])],1),_c('v-overlay',{attrs:{"value":_vm.timerPaused}},[_c('v-icon',{attrs:{"size":"99"},on:{"click":_vm.unPausetimer}},[_vm._v("play_circle_filled")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }