<template>
  <v-data-table
    :headers="headers"
    :items="items"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Teams</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <!-- NEW TEAM -->
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  color="primary"
                  dark
                  icon
                  id="create-team"
                  class="mb-2"
                  v-bind="attrs"
                  v-on="{...tooltip, ...dialog}"
                >
                  <v-icon>group_add</v-icon>
                </v-btn>
              </template>
              Add Team
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      v-model="editedItem.name"
                      label="Team Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      label="Members"
                      :items="allEmployees"
                      item-text="name"
                      return-object
                      chips
                      v-model="editedItem.members"
                      outlined
                      deletable-chips
                      clearable
                      clear-icon="clear"
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      label="Projects"
                      :items="allProjects"
                      item-text="name"
                      clearable
                      return-object
                      chips
                      v-model="editedItem.projects"
                      outlined
                      deletable-chips
                      multiple
                      clear-icon="clear"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- DELETE TEAM -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">
              Are you sure you want to delete the team: {{ editedItem.name }}?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <!-- MEMBERS COLUMN -->
    <template v-slot:[`item.members`]="{ item }">
      <div class="ma-2 d-inline" v-for="(member, i) in item.members" :key="i">
        <v-chip color="primary" class="ma-2" x-small>
        {{ member.firstName }} {{ member.lastName }}
      </v-chip>
      </div>
    </template>

    <!-- PROJECTS COLUMN -->
    <template v-slot:[`item.projects`]="{ item }">
      <div class="ma-2 d-inline" v-for="(project, i) in item.projects" :key="i">
        <v-chip color="green" text-color="white" class="ma-2" x-small>
          {{ project.name }}
        </v-chip>
      </div>
    </template>

    <!-- ACTIONS COLUMN -->
    <template v-slot:[`item.actions`]="{item}">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import teamHeaders from '@components/company/team_headers';
import helpers, { find } from '@methods/helpers';

export default {
  name: 'TeamsTable',
  props: ['teams'],
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [],
    items: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      members: [],
      projects: [],
    },
    defaultItem: {
      name: '',
      members: [],
      projects: [],
    },
  }),
  computed: {
    ...mapGetters(['allTeams', 'allEmployees', 'allProjects', 'currentCompany']),
    formTitle() {
      return this.editedIndex === -1 ? 'New Team' : 'Edit Team';
    },
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
    dialogDelete(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeDelete();
    },
    teams(newTeamsList) {
      this.items = newTeamsList;
    },
  },
  methods: {
    ...mapActions(['createTeam', 'updateEmployee', 'updateProject', 'replaceMembers', 'replaceProjects', 'updateEmployee', 'updateProject', 'updateTeam', 'deleteTeam', 'setTeamsNotification']),
    initialize() {

    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      const teamToRemove = this.editedItem;
      const res = await this.deleteTeam(teamToRemove);

      if (res.code === 'SUCCESS') {
        // remove team from user and project
        const usersInTeam = res.data.members;
        const projectsInTeam = res.data.projects;

        // Go through each users within the team and remove team docRef
        usersInTeam.forEach(async (user) => {
          const currentUser = { ...user };
          currentUser.teams = currentUser.teams.filter((team) => team.id !== res.data.tid);
          const userDocRef = helpers.getDocReference('users', currentUser.uid);
          await userDocRef.update(currentUser);
        });

        // Go through each projects within the team and remove team docRef
        projectsInTeam.forEach(async (project) => {
          const currentProject = { ...project };
          currentProject.teams = currentProject.teams.filter((team) => team.id !== res.data.tid);
          const projectDocRef = helpers.getDocReference('projects', currentProject.pid);
          await projectDocRef.update(currentProject);
        });
        this.setTeamsNotification({ success: true, message: `The team ${res.data.name} has been successfully deleted` });
      }

      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.editedIndex > -1) {
        const updatedTeam = { ...this.editedItem };
        updatedTeam.members = updatedTeam.members.map((member) => helpers.getDocReference('users', member.uid));
        updatedTeam.projects = updatedTeam.projects.map((project) => helpers.getDocReference('projects', project.pid));

        const originalTeam = find(this.allTeams, (team) => team.tid === this.editedItem.tid);

        // Arrays of document references
        const membersDifferences = helpers.compareLists(originalTeam.members, updatedTeam.members, 'id');
        const projectsDifferences = helpers.compareLists(originalTeam.projects, updatedTeam.projects, 'id');

        // Update the team
        const updatedTeamResponse = await this.updateTeam(updatedTeam);

        // Grab the team Document Reference
        const { data } = updatedTeamResponse;
        const teamDocRef = helpers.getDocReference('teams', data.tid);

        if (updatedTeamResponse.code === 'SUCCESS') {
          // Get lists of users that were added and removed from the team
          const originalAddedUsersData = membersDifferences.added
            .map((user) => find(this.allEmployees, (member) => member.uid === user.id));
          const originalRemovedUsersData = membersDifferences.removed
            .map((user) => find(this.allEmployees, (member) => member.uid === user.id));

          // Get lists of projects that were added and removed from the team
          const originalAddedProjectsData = projectsDifferences.added
            .map((project) => find(this.allProjects, (p) => p.pid === project.id));
          const originalRemovedProjectsData = projectsDifferences.removed
            .map((project) => find(this.allProjects, (p) => p.pid === project.id));

          // Update the added and removed users by removing or adding teamDocRef
          this.addTeamToCollection(originalAddedUsersData, teamDocRef, this.updateEmployee);
          this.removeTeamFromCollection(originalRemovedUsersData, teamDocRef, this.updateEmployee);

          // Update the added and removed projects by removing or adding teamDocRef
          this.addTeamToCollection(originalAddedProjectsData, teamDocRef, this.updateProject);
          this.removeTeamFromCollection(
            originalRemovedProjectsData,
            teamDocRef,
            this.updateProject,
          );
          this.setTeamsNotification({ success: true, message: `${data.name} has been updated` });
        }
      } else {
        // Adding
        const teamToSave = { ...this.editedItem };
        teamToSave.cid = this.currentCompany.cid;
        teamToSave.projects = teamToSave.projects.map((project) => helpers.getDocReference('projects', project.pid));
        teamToSave.members = teamToSave.members.map((member) => helpers.getDocReference('users', member.uid));
        const createdTeam = await this.createTeam(teamToSave);

        if (createdTeam.code === 'SUCCESS') {
          const { members, projects } = createdTeam.data;
          const teamDocRef = helpers.getDocReference('teams', createdTeam.data.tid);

          const userIds = members.map((member) => member.id);
          this.allEmployees
            .filter((user) => userIds.includes(user.uid))
            .map((user) => {
              const currentUser = { ...user };
              currentUser.teams.push(teamDocRef);
              return currentUser;
            })
            .forEach(async (updatedUser) => {
              const { uid } = updatedUser;
              const userDocRef = helpers.getDocReference('users', uid);
              await userDocRef.update(updatedUser);
            });
          const projectIds = projects.map((project) => project.id);
          this.allProjects
            .filter((project) => projectIds.includes(project.pid))
            .map((project) => {
              const currentProject = { ...project };
              project.teams.push(teamDocRef);
              return currentProject;
            })
            .forEach(async (updatedProject) => {
              const { pid } = updatedProject;
              const projectDocRef = helpers.getDocReference('projects', pid);
              await projectDocRef.update(updatedProject);
            });
          this.setTeamsNotification({ success: true, message: `The team ${createdTeam.data.name} has been successfully created` });
        }
      }
      this.close();
    },
    addTeamToCollection(docs, teamDocRef, update) {
      if (docs.length < 1) return;

      docs.forEach(async (doc) => {
        const currentDoc = doc;
        currentDoc.teams.push(teamDocRef);
        await update(currentDoc);
      });
    },
    removeTeamFromCollection(docs, teamDocRef, update) {
      if (docs.length < 1) return;

      docs.forEach(async (doc) => {
        const currentDoc = doc;
        const newTeamList = doc.teams.filter((team) => team.id !== teamDocRef.id);
        currentDoc.teams = newTeamList;
        await update(currentDoc);
      });
    },
  },
  mounted() {
    this.items = [...this.teams];
    this.headers = [...teamHeaders];
  },
  created() {
    this.initialize();
  },
};
</script>
