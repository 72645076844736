import * as lodash from 'lodash';
// import moment from 'moment';
import {
  trim,
  toLower,
  uniqBy,
  flatten,
} from 'lodash';
import time from '@utils/time';
import firebase from '@database';
import services from '@services';

const projectCollection = firebase.database.collection('projects');

const chunkProjects = (projects) => lodash.chunk(projects, 6);

const filterProjects = (user, projectList) => {
  const isBuzzUser = user.email.includes('@buzzsolutions.co');
  return (isBuzzUser) ? projectList : projectList.filter((project) => !project.prototyping);
};

const state = {
  projects: [],
  project: {},
  filtered: [],
  projectTeams: [],
  notification: {
    success: false,
    message: '',
  },
  search: '',
  projectsPerPage: [],
  page: 1,
  loadingProjects: false,
  loadingProject: false,
  loadingProcessed: false,
  loadingTeams: false,
  editing: false,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  allProjects: (state) => state.projects,
  currentProject: (state) => state.project,
  filteredProjects: (state) => state.filtered,
  projectsOnPage: (state) => {
    if (state.search === '') return lodash.chunk(state.projects, 6);
    return state.projectsPerPage;
  },
  currentProjectsPage: (state) => state.page,
  currentProjectSearch: (state) => state.search,
  teamsOfProject: (state) => state.projectTeams,
  projectNotification: (state) => state.notification,
  loadingProjects: (state) => state.loadingProjects,
  loadingProject: (state) => state.loadingProject,
  loadingTeamsInProject: (state) => state.loadingTeams,
  loadingProcessedInProject: (state) => state.loadingProcessed,
  editingProject: (state) => state.editing,
};

const actions = {
  setProjectsNotification({ commit }, notification) {
    commit('notification_project', notification);

    setTimeout(() => {
      commit('notification_project', { success: false, message: '' });
    }, 3000);
  },
  clearProjects({ commit }) {
    commit('clear_projects');
  },
  setProjectsLoading({ commit }) {
    commit('set_loading_projects');
  },
  setTeamsInProjectLoading({ commit }) {
    commit('set_loading_team_project');
  },
  setTeamsInProjectLoaded({ commit }) {
    commit('set_team_project_load_finish');
  },
  setProcessedInProjectLoading({ commit }) {
    commit('set_loading_processed_project');
  },
  setProcessedInProjectLoaded({ commit }) {
    commit('set_processed_project_load_finish');
  },
  finishProjectLoad({ commit }) {
    commit('finish_project_load');
  },
  setProjectSearch({ commit }, search) { commit('set_project_filter', search); },
  setProjectsPage({ commit }, page) { commit('set_projects_page', page); },
  createProject({ commit }, projectData) {
    return new Promise((resolve, reject) => {
      projectCollection
        .add(projectData)
        .then(async (docRef) => {
          const addedProjectData = projectData;
          addedProjectData.pid = docRef.id;
          await docRef.update({
            pid: docRef.id,
            images: [],
            processed: [],
          });

          const documentSnapshot = await docRef.get();

          const status = {
            code: 'SUCCESS',
            message: 'Project has been successfully created',
            data: addedProjectData,
          };

          commit('create_project', documentSnapshot.data());
          const notification = {
            success: true,
            message: `${projectData.name} has been created.`,
          };
          commit('notification_project', notification);

          setTimeout(() => {
            commit('notification_project', { success: false, message: '' });
          }, 3000);
          resolve(status);
        })
        .catch((err) => reject(err));
    });
  },
  getAllProjectsByCompanyId(store, cid) {
    store.commit('set_loading_projects');

    return new Promise((resolve, reject) => {
      services.projects.getByCompany(cid)
        .then((res) => {
          const sortedProjectList = time.sortByDescendingDates(res.data);
          const projects = filterProjects(store.getters.currentUser, sortedProjectList);

          store.commit('fetch_projects', projects);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  async getProjectsByTeams({ commit }, teams) {
    const teamDocumentSnapshots = await Promise.all(teams.map((team) => team.get()));
    const userTeams = teamDocumentSnapshots.map((snapshot) => snapshot.data());

    const projectDocumentReferences = uniqBy(flatten(userTeams.map((team) => team.projects)), 'id');

    const projectSnapshots = await Promise.all(projectDocumentReferences.map((doc) => doc.get()));
    const projects = projectSnapshots.map((snapshot) => snapshot.data());

    commit('fetch_projects', projects);
    return projects;
  },
  async getProjectsByDocRefs(store, docRefs) {
    store.commit('set_loading_projects');
    const promises = await docRefs.map(async (doc) => doc.get());
    const documentSnapshots = await Promise.all(promises);
    const projects = documentSnapshots.map((doc) => doc.data());
    const sortedProjects = time.sortByDescendingDates(projects);

    const p = filterProjects(store.getters.currentUser, sortedProjects);

    store.commit('fetch_projects', p);
    return projects;
  },
  getProjectsByDocumentReference({ commit }, projectDoc) {
    return new Promise((resolve, reject) => {
      projectDoc.get()
        .then((documentSnapshot) => {
          commit('fetch_projects_doc', documentSnapshot.data());
          const status = {
            code: 'SUCCESS',
            data: documentSnapshot.data(),
          };
          resolve(status);
        })
        .catch((err) => reject(err));
    });
  },
  getProjectsByDocumentReferences(store, projectDocs) {
    const docs = lodash.flatten(projectDocs);
    return Promise.all(docs.map((doc) => doc.get()))
      .then((projects) => {
        const projectsList = projects.map((project) => project.data());
        const sortedProjectsList = time.sortByDescendingDates(projectsList);

        const p = filterProjects(store.getters.currentUser, sortedProjectsList);
        store.commit('fetch_projects', p);
      });
  },
  getProject({ commit }, pid) {
    commit('set_loading_project');
    return new Promise((resolve, reject) => {
      services.projects.get(pid)
        .then((res) => {
          commit('fetch_project', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  setCurrentProject({ commit }, project) {
    commit('set_current_project', project);
  },
  async getProcessedDataFromDoc({ commit }, projectData) {
    const { pid, processed } = projectData;
    const processedDocPromises = processed.map((doc) => new Promise((resolve, reject) => {
      doc.get()
        .then((documentSnapshot) => {
          const status = {
            code: 'SUCCESS',
            processedData: documentSnapshot.data(),
          };
          resolve(status);
        })
        .catch((err) => reject(err));
    }));

    await Promise.all(processedDocPromises)
      .then((res) => {
        const processedData = res.map((response) => response.processedData);
        commit('fetch_processed_within_project', { pid, processed: processedData });
      });
    return processedDocPromises;
  },
  updateProject({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.projects.update(payload.pid, payload.updates)
        .then((res) => {
          commit('update_project', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  deleteProject({ commit }, deletingProject) {
    const { pid, name } = deletingProject;
    return new Promise((resolve, reject) => {
      projectCollection
        .doc(pid)
        .delete()
        .then(() => {
          const status = {
            code: 'SUCCESS',
            message: 'Successfully deleted project',
            data: deletingProject,
          };

          const notification = {
            success: true,
            message: `The project ${name} has been deleted`,
          };

          commit('remove_project', pid);
          commit('notification_project', notification);

          setTimeout(() => {
            commit('notification_project', { success: false, message: '' });
          }, 3000);

          resolve(status);
        })
        .catch((err) => reject(err));
    });
  },
  addTeamDocToProject({ commit }, team) {
    commit('add_team_to_project', team);
  },
  addTeamToCurrentProjectList({ commit }, newTeam) {
    commit('add_team_project', newTeam);
  },
  removeTeamFromCurrentProjectList({ commit }, tid) {
    commit('remove_team_project', tid);
  },
  editProjectFields({ commit }, edit) {
    commit('set_edit_project', edit);
  },
};

const mutations = {
  clear_projects: (state) => { state.projects = []; },
  set_loading_projects: (state) => { state.loadingProjects = true; },
  set_loading_project: (state) => { state.loadingProject = true; },
  set_loading_team_project: (state) => { state.loadingTeams = true; },
  set_loading_processed_project: (state) => { state.loadingProcessed = true; },
  set_team_project_load_finish: (state) => { state.loadingTeams = false; },
  set_processed_project_load_finish: (state) => { state.loadingProcessed = false; },
  finish_project_load: (state) => { state.loadingProjects = false; },
  set_project_filter: (state, search) => {
    const reformatted = toLower(trim(search));
    state.search = reformatted;
    state.projectsPerPage = chunkProjects(state.projects
      .filter((project) => toLower(trim(project.name)).search(reformatted) > -1));
  },
  set_projects_page: (state, page) => { state.page = page; },
  fetch_projects_doc: (state, projects) => {
    if (state.projects.length === 0) state.projects.push(projects);
    else state.projects = state.projects.concat(projects);
  },
  fetch_projects: (state, projects) => {
    // Sorts each project in descending order
    state.projects = projects.sort((a, b) => new Date(b.date) - new Date(a.date));
    state.loadingProjects = false;
  },
  fetch_project: (state, project) => {
    state.project = project;
    state.loadingProject = false;
  },
  fetch_teams_within_project: (state, teamsData) => {
    const { pid, teams } = teamsData;
    if (state.projects.length > 0) {
      const modifyingProject = lodash.find(state.projects, (project) => project.pid === pid);
      modifyingProject.teams = teams;
      const index = state.projects.findIndex(((project) => project.pid === pid));
      if (index !== -1) state.projects.splice(index, 1, modifyingProject);
    }

    if (state.project.pid === pid) {
      state.project.teams = teams;
    }
    state.loadingTeams = false;
  },
  fetch_processed_within_project: (state, processedData) => {
    const { pid, processed } = processedData;

    if (state.projects.length > 0) {
      const modifyingProject = lodash.find(state.projects, (project) => project.pid === pid);
      modifyingProject.processed = processed;

      const index = state.projects.findIndex((project) => project.pid === pid);
      if (index !== -1) state.projects.splice(index, 1, modifyingProject);
    }

    if (state.project.pid === pid) {
      state.project.processed = processed;
    }
  },
  fetch_teams_in_project: (state, teamsData) => {
    const { teams, pid } = teamsData;
    const teamObj = {};
    teamObj[pid] = teams;
    if (!(pid in state.projectTeams)) {
      state.projectTeams.push(teamObj);
    }
    state.loadingTeams = false;
  },
  create_project: (state, newProject) => {
    state.projects.unshift(newProject);
  },
  update_project: (state, newProject) => {
    const index = state.projects.findIndex((project) => project.pid === newProject.pid);
    if (index !== -1) {
      state.projects.splice(index, 1, newProject);
    }
    state.project = newProject;
  },
  add_team_project: (state, newTeam) => {
    state.projectTeams.push(newTeam);
  },
  remove_team_project: (state, tid) => {
    state.projectTeams = state.projectTeams.filter((team) => team.tid !== tid);
  },
  remove_project: (state, pid) => {
    state.projects = state.projects.filter((project) => project.pid !== pid);
  },
  notification_project: (state, notification) => {
    state.notification = notification;
  },
  set_edit_project: (state, edit) => { state.editing = edit; },
  set_current_project: (state, project) => { state.project = project; },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
