<template>
  <Loading v-if="loadingProjects || loadingEmployees" />
  <v-container fluid v-else>
    <SnackBar :snackbar="projectNotification.success" :text="projectNotification.message" />
    <div id="projects">
      <ProjectsToolBar />
      <router-view></router-view>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProjectsToolBar from '@components/projects/ProjectsToolBar.vue';
import Loading from '@components/common/Loading.vue';
import SnackBar from '@components/common/SnackBar.vue';
import { uniqueArrayBy, flattenArray } from '@methods/helpers';

export default {
  name: 'Projects',
  props: ['view'],
  components: {
    Loading,
    SnackBar,
    ProjectsToolBar,
  },
  data: () => ({
    show: false,
    message: '',
  }),
  methods: {
    ...mapActions([
      'getAllProjectsByCompanyId', // Check
      'setProjectsLoading',
      'finishProjectLoad',
      'clearProjects',
      'getProjectsByDocRefs',
    ]),
    getCreateProjectStatus(status) {
      if (status.includes('successfully')) {
        this.show = true;
        this.message = status;
        setTimeout(() => {
          this.show = false;
          this.message = '';
        }, 5000);
      }
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'loadingProjects', 'loadingEmployees', 'projectNotification', 'allTeams']),
  },
  async created() {
    this.clearProjects();
    this.setProjectsLoading();
    const { cid, adminStatus, teams } = this.currentUser;

    // If user is admin, you can automatically retrieve all projects by CID
    if (adminStatus) await this.getAllProjectsByCompanyId(cid);
    else {
      let userTeams;

      if (this.allTeams.length < 1) {
        // If the user is logging in for the first time,
        // App.vue has already rendered, so it won't fetch again.
        // If there are no teams loaded, manually do so.
        const teamsDocumentSnapshot = await Promise.all(teams.map((team) => team.get()));
        userTeams = teamsDocumentSnapshot.map((snapshot) => snapshot.data());
      } else {
        // Otherwise, get a list of team IDs
        // And retrieve a list of user teams
        const userTeamIds = teams.map((team) => team.id);
        userTeams = this.allTeams.filter((team) => userTeamIds.includes(team.tid));
      }

      const userProjects = uniqueArrayBy(flattenArray(userTeams.map((t) => t.projects), 'id'));
      const projectResponses = await this.getProjectsByDocRefs(userProjects);
      if (projectResponses.length === userProjects.length) this.finishProjectLoad();
    }
  },
};
</script>
