<template>
  <v-container id="overall-priority" fluid>
    <div id="overall-priority-display" v-if="!editingFolder">
      <span class="text-caption">
        {{ (severity === '') ? 'There is no priority assigned to this pole' : severity }}
      </span>
    </div>
    <div id="overall-priority-editor" v-else>
      <v-select
        outlined
        dense
        :items="priorityItems"
        :label="priorityLabel"
        @change="changeInPriority"
        v-model="severity"
      >
        <template v-slot:append-outer>
        </template>
      </v-select>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FolderOverallPriority',
  props: ['folder'],
  data: () => ({
    severity: '',
  }),
  methods: {
    ...mapActions(['updateFolder', 'setNotification', 'setModifiedFolder', 'setOverallPriority']),
    changeInPriority() {
      this.setOverallPriority(this.severity);
    },
  },
  computed: {
    ...mapGetters(['currentFolder', 'editingFolder', 'modifiedFolder', 'overallPriority', 'companyHas']),
    priorityPlaceholder() {
      if (this.companyHas('structurePriority')) {
        return 'There is no priority assigned to this structure';
      }

      return 'There is no priority assigned to this pole';
    },
    priorityLabel() {
      if (this.companyHas('structurePriority')) {
        return 'Select structure priority';
      }

      return 'Select overall pole priority';
    },
    priorityItems() {
      if (this.companyHas('structurePriority')) {
        return ['Priority 5', 'Priority 4', 'Priority 3', 'Priority 2', 'Priority 1', 'Emergency'];
      }

      return ['None', 'TD-5', 'TD-4', 'TD-2', 'TD-1', 'Emergency'];
    },
  },
  watch: {
    editingFolder(editing) {
      const noSeverity = 'There is no severity assigned to this pole';
      // In case this.folder doesn't have the overall_severity field
      if (editing) {
        if (Object.keys(this.folder).includes('overall_severity')) {
          this.severity = this.folder.overall_severity;
          this.setOverallPriority(this.severity);
        } else this.severity = noSeverity;
      }
    },
    folder(newFolder) {
      this.severity = (newFolder.overall_severity) ? newFolder.overall_severity : '';
      if (newFolder.overall_severity) this.setOverallPriority(this.severity);
    },
  },
  mounted() {
    this.severity = (this.folder.overall_severity) ? this.folder.overall_severity : '';
    if (this.folder.overall_severity) this.setOverallPriority(this.severity);
  },
};
</script>
