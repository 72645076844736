<template>
  <v-card flat>
    <v-card-title class="headline">Please verify your account</v-card-title>
    <v-card-subtitle class="subtitle">Verification codes will expire in 10 minutes</v-card-subtitle>
    <v-card-text>
      <span class="mb-2" id="instruction">{{ message }}</span>
      <v-text-field
        outlined
        class="mt-2"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        label="Verification Code"
        v-model="code"
        :type="show ? 'text' : 'password'"
        @click:append="show = !show"
      ></v-text-field>
      <v-btn color="primary" text small @click="resendCode">Resend Code</v-btn>
      <v-card-actions>
        <v-btn color="primary" text @click="navigateBack">Back</v-btn>
        <v-btn color="primary" text @click="verifyCode">Verify</v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
  name: 'VerifyUser',
  props: ['uid', 'type'],
  data: () => ({
    code: '',
    show: false,
    contact: '',
  }),
  methods: {
    ...mapActions(['getUserDocumentById', 'createVerification', 'getVerification', 'updateVerification', 'verifyPasswordResetCode']),
    navigateBack() {
      const path = `/forgot-password/verification-select/${this.uid}`;
      this.$router.push(path);
    },
    async resendCode() {
      const payloadUpdate = { uid: this.currentUser.uid, verification: 'password-reset' };
      // Cancel the current verification
      const updatedVerification = await this.updateVerification(payloadUpdate);
      const channel = (this.type === 'phone') ? 'sms' : 'email';
      if (updatedVerification.status === 200) {
        await this.createVerification({ uid: this.uid, channel, verification: 'password-reset' });
      }
    },
    async verifyCode() {
      const payload = { uid: this.currentUser.uid, code: this.code, verification: 'password-reset' };
      const res = await this.verifyPasswordResetCode(payload);

      if (res.status === 200) this.$router.push(`/forgot-password/reset/${this.uid}`);
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    message() {
      if (this.type === 'email') return `We've sent a verification code to the email: ${this.contact}`;
      return `We've sent a verification code to the phone number: ${this.contact}`;
    },
  },
  async created() {
    let user;
    // If this.currentUser is null, fetch for it, otherwise set user to this.currentUser
    if (!this.currentUser) {
      const response = await this.getUserDocumentById(this.uid);
      user = response.data;
    } else user = cloneDeep(this.currentUser);

    let channel;

    if (this.type === 'email') {
      this.contact = user.email;
      channel = 'email';
    }

    if (this.type === 'phone') {
      const lastFourDigits = user.phone.substring(user.phone.length - 4, user.phone.length);
      this.contact = `(***)-***-${lastFourDigits}`;
      channel = 'sms';
    }

    if (Object.keys(user).includes('sid') && user.sid !== '') {
      try {
        await this.getVerification({ sid: user.sid, verification: 'password-reset' });
      } catch (err) {
        await this.createVerification({ uid: this.uid, channel, verification: 'password-reset' });
      }
    } else this.createVerification({ uid: this.uid, channel, verification: 'password-reset' });
  },
};
</script>
