<template>
  <v-container fluid>
    <router-view></router-view>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Analytics',
  props: ['pid'],
  methods: {
    ...mapActions(['getDashboardImages', 'loadPaidProducts', 'getAllProjectsByCompanyId', 'getProjectsByTeams']),
    async getFirstProject() {
      let projects;
      try {
        if (this.currentUser.adminStatus) {
          projects = await this.getAllProjectsByCompanyId(this.currentCompany.cid);
        } else {
          projects = await this.getProjectsByTeams(this.currentUser.teams);
        }
        const [project] = projects;

        return project;
      } catch (err) {
        return err;
      }
    },
    async fetchProjects() {
      let projects;
      try {
        if (this.currentUser.adminStatus) {
          const res = await this.getAllProjectsByCompanyId(this.currentCompany.cid);
          projects = res.data;
        } else {
          projects = await this.getProjectsByTeams(this.currentUser.teams);
        }
        return projects;
      } catch (err) {
        return new Error(err);
      }
    },
  },
  computed: {
    ...mapGetters(['currentCompany', 'currentProject', 'currentUser']),
  },
  async created() {
    // Check dashboard access
    const canAccess = await this.loadPaidProducts({
      customerId: this.currentCompany.customerId,
      product: 'Dashboard',
    });

    const projects = await this.fetchProjects();
    const [firstProject] = projects;
    const { pid } = this.$route.params;

    const currentPid = (!pid) ? firstProject.pid : pid;

    // Can't access
    if (!canAccess) this.$router.push('/analytics/no-access');
    else this.$router.push(`/analytics/insights/${currentPid}`);
  },
};
</script>
