var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"id":"folders-list-search","outlined":"","dense":"","append-icon":"mdi-magnify","label":"Search Folders","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('FolderFilters')],1)],1)],1),_c('v-data-table',{directives:[{name:"scroll",rawName:"v-scroll:#folders-list-table",value:(_vm.onScroll),expression:"onScroll",arg:"#folders-list-table"}],staticClass:"elevation-1",attrs:{"id":"folders-list-table","headers":_vm.headers,"items":_vm.foldersList,"search":_vm.search,"sort-by":['date'],"page":_vm.currentPage,"sort-desc":[true],"footer-props":_vm.footer},on:{"update:page":function($event){_vm.currentPage=$event},"pagination":_vm.updatePageNo,"update:items-per-page":_vm.updateItemPerPage},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:(_vm.isSelected(item)) ? 'selected-folder' : 'not-selected-folder',on:{"click":function($event){return _vm.setCurrFolder(item)}}},[_c('td',[_c('v-btn',{attrs:{"icon":"","text":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-folder")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.userRequired(item))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","small":""}},'v-icon',attrs,false),on),[_vm._v("error")]):_vm._e()]}}],null,true)},[_vm._v(" Requires Attention ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.shouldInspect(item))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"amber","small":""}},'v-icon',attrs,false),on),[_vm._v("report_problem")]):_vm._e()]}}],null,true)},[_vm._v(" Requires Field Inspection ")])],1),_c('td',[_c('div',{staticClass:"d-inline-block mr-1",attrs:{"id":"folder-name"}},[_c('span',[_vm._v(_vm._s(item.name))])]),(_vm.folderError(item) && !_vm.companyHas('general_folders'))?_c('div',{staticClass:"d-inline-block ml-1",attrs:{"id":"potential-image-missing"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" help_outline ")])]}}],null,true)},[_vm._v(" This folder may contain too little or too many images ")])],1):_vm._e()]),_c('td',[(_vm.displayReviewed(item))?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" verified ")]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s((_vm.type === 'ai') ? item.processed_image_count : item.image_count)+" ")]),_c('td',[_vm._v(_vm._s(item.nice_date))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }