const getUsers = async (users) => {
  const instanceUrl = window.location.hostname;
  const userDocRefs = await Promise.all(users.map((user) => user.get()));
  let employees = userDocRefs.map((docRef) => docRef.data());

  if (instanceUrl === 'nfpower.buzzpowerai.com') employees = employees.filter((employee) => employee.email.includes('@newfoundlandpower.com'));

  return employees;
};

export default {
  getFirstReviewer: async (users, assignerUid) => {
    const employees = await getUsers(users);

    return employees
      .filter((user) => user !== undefined)
      .map((user) => ({
        uid: user.uid,
        assigner_uid: assignerUid,
        name: user.name,
        email: user.email,
        phone: user.phone,
      }));
  },
  getSecondReviewer: async (users, assignerUid) => {
    const employees = await getUsers(users);

    return employees
      .filter((user) => user !== undefined)
      .map((user) => ({
        reason: '',
        uid: user.uid,
        assigner_uid: assignerUid,
        name: user.name,
        email: user.email,
        reviewed: false,
      }));
  },
};
