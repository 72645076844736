<template>
  <div :id="`histogram-${chartId}`"></div>
</template>

<script>
import Plotly from '@components/charts/custom-plotly';
import helpers from '@methods/helpers';

export default {
  name: 'Histogram',
  data: () => ({
    histogram: null,
    imagesByDate: {},
  }),
  props: ['images', 'chartId', 'showLegend', 'orientation', 'labels', 'values', 'title'],
  methods: {
    sortImagesByDate(images) {
      const imageDateCount = {};

      images.forEach((image) => {
        const createdAt = helpers.convertToDate(image.date).format('YYYY-MM-DD');
        if (!(createdAt in imageDateCount)) imageDateCount[createdAt] = [];
        imageDateCount[createdAt].push(image);
      });
      return imageDateCount;
    },
    async react(images, title) {
      const histo = document.getElementById(`histogram-${this.chartId}`);
      const dates = images
        .map((image) => helpers.convertToDate(image.date).format('YYYY-MM-DD'));
      const trace = {
        x: dates.sort(),
        type: 'histogram',
      };
      this.imagesByDate = this.sortImagesByDate(images);
      const data = [trace];

      const layout = {
        title: {
          text: title,
          font: {
            family: 'Courier New, monospace',
            size: 24,
          },
        },
        showlegend: this.showLegend,
        legend: {
          orientation: this.orientation,
          valign: 'bottom',
        },
      };

      const config = {
        displaylogo: false,
        responsive: true,
        displayModeBar: false,
      };

      this.histogram = await Plotly.react(histo, data, layout, config);
    },
  },
  async mounted() {
    const histo = document.getElementById(`histogram-${this.chartId}`);
    const dates = this.images
      .map((image) => helpers.convertToDate(image.date).format('YYYY-MM-DD'));
    const trace = {
      x: dates.sort(),
      type: 'histogram',
    };
    this.imagesByDate = this.sortImagesByDate(this.images);
    const data = [trace];

    const layout = {
      title: {
        text: this.title,
        font: {
          family: 'Courier New, monospace',
          size: 24,
        },
      },
      showlegend: this.showLegend,
      legend: {
        orientation: this.orientation,
        valign: 'bottom',
      },
    };

    const config = {
      displaylogo: false,
      responsive: true,
      displayModeBar: false,
    };

    this.histogram = await Plotly.newPlot(histo, data, layout, config);
  },
};
</script>
