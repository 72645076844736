import jwtDecode from 'jwt-decode';

const validateAuthentication = (jwt) => {
  if (jwt) {
    const decoded = jwtDecode(jwt);

    // Check for expired token
    const currentTime = Date.now() / 1000;

    return currentTime < decoded.exp;
  }

  return false;
};

export default validateAuthentication;
