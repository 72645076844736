<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        id="download-json-btn"
        icon
        x-large
        color="primary"
        v-bind="attrs"
        v-on="on"
        @click="downloadJson"
      >
        <v-icon>fab fa-js</v-icon>
      </v-btn>
    </template>
    Export to JSON
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import dashboardHelpers from '@components/dashboard/methods/';
import services from '@services';

export default {
  name: 'ExportJson',
  props: {
    pid: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    error: true,
  }),
  methods: {
    ...mapActions(['setRetrieveImage', 'setRetrieveFolder', 'setRetrieveProject']),
    async downloadJson() {
      if (this.pid === '') this.error = true;

      const data = {};

      this.setRetrieveProject(true);
      try {
        const projectResponse = await dashboardHelpers.fetchProjectByPid(this.pid);

        if (projectResponse.status === 200) {
          data.project = projectResponse.data;
          this.setRetrieveProject(false);
          this.setRetrieveFolder(true);

          const folderResponse = await services
            .folders
            .getByProject(this.currentCompany.cid, this.pid);

          if (folderResponse.status === 200) {
            this.setRetrieveFolder(false);
            this.setRetrieveImage(true);
            data.folders = folderResponse.data;

            const imageResponse = await services
              .images
              .getByProject(this.currentCompany.cid, this.pid);

            if (imageResponse.status === 200) {
              data.images = imageResponse.data;
              this.setRetrieveImage(false);
              // eslint-disable-next-line max-len
              dashboardHelpers.saveJson(imageResponse.data, data.folders, projectResponse.data.name);
            }
          }
        }
        return data;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        return err;
      }
    },
  },
  computed: {
    ...mapGetters(['currentProject', 'currentCompany']),
  },
};
</script>
