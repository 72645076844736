import axios from 'axios';

// axios config
axios.defaults.maxContentLength = Infinity;
axios.defaults.maxBodyLength = Infinity;

const setAuthToken = (token) => {
  if (token) axios.defaults.headers.common.Authorization = token; // Apply to every request
  else delete axios.defaults.headers.common.Authorization; // Deletes auth header
};

export default setAuthToken;
