<template>
  <v-skeleton-loader
    class="mx-auto"
    :max-width="width"
    type="card"
  ></v-skeleton-loader>
</template>

<script>
export default {
  name: 'CardSkeletonLoader',
  props: ['width'],
  data: () => ({
    attrs: {
      class: 'mb-6',
      boilerplate: false,
      elevation: 2,
    },
  }),
};
</script>
