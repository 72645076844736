import axios from 'axios';
import { baseURL } from '@utils/endpoint';

export default {
  create: (payload) => new Promise((resolve, reject) => {
    axios.post(`${baseURL}/api/folder`, payload)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  update: (payload) => new Promise((resolve, reject) => {
    axios.put(`${baseURL}/api/folder/${payload.path}/project/${payload.pid}/company/${payload.cid}`, payload)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  getByCompany: (cid) => new Promise((resolve, reject) => {
    axios.get(`${baseURL}/api/folder/company/${cid}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  /*
    |-------------------------------------------------|
    |   @method       getByCompany                    |
    |   @params       cid <String>, pid <String>      |
    |   @return       Promise [<folders>]             |
    |   @description  Takes in the company id and the |
    |                 project id to fetch a list of   |
    |                 folders within a project        |
    |-------------------------------------------------|
  */
  getByProject: (cid, pid) => new Promise((resolve, reject) => {
    axios.get(`${baseURL}/api/folder/project/${pid}/company/${cid}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  saveFolders: (payload) => new Promise((resolve, reject) => {
    axios.post(`${baseURL}/api/folder/save_folders`, payload)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
};
