import axios from 'axios';
import { baseURL } from '@utils/endpoint';

export default {
  /*
    |-------------------------------------------------|
    |   @service    all                               |
    |   @params     query <String>                    |
    |   @desc       Fetches a list of teams based on  |
    |               query string                      |
    |-------------------------------------------------|
  */
  all: (query) => axios.get(`${baseURL}/api/teams/?${query}`),

  /*
    |-------------------------------------------------|
    |   @service    get                               |
    |   @params     cid <String>, tid                 |
    |               <String>                          |
    |   @desc       Fetches a specific                |
    |               team based on company             |
    |               id and team id                    |
    |-------------------------------------------------|
  */
  get: (cid, tid) => axios.get(`${baseURL}/api/teams/${tid}/?company_id=${cid}`),

  /*
    |-------------------------------------------------|
    |   @service    create                            |
    |   @params     cid <String>, team                |
    |               <Object>                          |
    |   @desc       Creates a new project             |
    |-------------------------------------------------|
  */
  create: (id, cid, team) => axios.post(`${baseURL}/api/teams/?company_id=${id}&cid=${cid}`, team),

  /*
    |-------------------------------------------------|
    |   @service    update                            |
    |   @params     cid <String>, tid                 |
    |               <String>, updates <Object>        |
    |   @desc       Updates team data                 |
    |-------------------------------------------------|
  */
  update: (cid, id, tid, updates) => axios.put(`${baseURL}/api/teams/${id}/?company_id=${cid}&tid=${tid}`, updates),

  /*
    |-------------------------------------------------|
    |   @service    delete                            |
    |   @params     cid <String>, tid <String>        |
    |   @desc       Deletes team data                 |
    |-------------------------------------------------|
  */
  delete: (cid, id, tid) => axios.delete(`${baseURL}/api/teams/${id}/?company_id=${cid}&tid=${tid}`),
};
