<template>
  <v-card flat>
    <v-container fluid>
      <v-row class="child-flex">
        <div>
          <v-toolbar>
            <SearchBar searchFor="projects" />
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-btn
              id="grid-btn"
              icon
              :color="(grid) ? 'primary' : ''"
              @click="changeView('grid')"
            >
              <v-icon>grid_view</v-icon>
            </v-btn>
            <v-btn
              id="table-btn"
              icon
              :color="(table) ? 'primary' : ''"
              @click="changeView('table')"
            >
              <v-icon>table_chart</v-icon>
            </v-btn>
          </v-toolbar>
        </div>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import SearchBar from '@components/common/SearchBar.vue';

export default {
  name: 'ProjectsToolBar',
  components: {
    SearchBar,
  },
  data: () => ({
    table: false,
    grid: true,
  }),
  methods: {
    changeView(view) {
      this.grid = view === 'grid';
      this.table = view === 'table';
      this.$router.push(`/projects/view/${view}`);
    },
  },
};
</script>
