<template>
  <div>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              v-model="user.firstName"
              outlined
              label="New User's first name"
              :error-messages="formErrors.firstName"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              v-model="user.lastName"
              outlined
              label="New User's last name"
              :error-messages="formErrors.lastName"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              v-model="user.phone"
              outlined
              label="New User's Phone Number"
              @input="formatPhone"
              :error-messages="formErrors.phone"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              v-model="user.country"
              outlined
              label="Country Associated with Phone"
              :error-messages="formErrors.country"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              v-model="user.email"
              outlined
              label="New User's Email Address"
              :error-messages="formErrors.email"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              v-model="user.password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              outlined
              label="Password"
              :error-messages="formErrors.password"
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              v-model="user.password2"
              outlined
              label="Re-enter the password"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :error-messages="formErrors.password2"
              :type="show2 ? 'text' : 'password'"
              @click:append="show2 = !show2"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="close" :loading="addingUserToInstance">Close</v-btn>
      <v-btn color="primary" text @click="save" :loading="addingUserToInstance">Save</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CreateUserForm',
  props: ['cid', 'companyName', 'company', 'errors'],
  data: () => ({
    user: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      password2: '',
      country: '',
    },
    formErrors: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      password2: '',
    },
    show1: false,
    show2: false,
  }),
  methods: {
    ...mapActions(['addUserToInstance']),
    close() {
      this.$emit('close');
    },
    formatPhone() {
      const number = this.user.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.user.phone = !number[2] ? number[1] : `(${number[1]}) ${number[2]}${number[3] ? `-${number[3]}` : ''}`;
    },
    save() {
      const newUser = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        country: this.user.country,
      };
      if (this.user.phone.length > 0) newUser.phone = '+1 '.concat(this.user.phone);
      if (this.user.country.length > 0) newUser.country = this.user.country;

      newUser.password = this.user.password;
      newUser.password2 = this.user.password2;

      this.$emit('save', newUser);
    },
  },
  watch: {
    errors: {
      deep: true,
      handler(newErrors) {
        if (newErrors.firstName) this.formErrors.firstName = newErrors.firstName;
        if (newErrors.lastName) this.formErrors.lastName = newErrors.lastName;
        if (newErrors.email) this.formErrors.email = newErrors.email;
        if (newErrors.password) this.formErrors.password = newErrors.password;
        if (newErrors.password2) this.formErrors.password2 = newErrors.password2;
      },
    },
  },
  computed: {
    ...mapGetters(['addingUserToInstance']),
  },
};
</script>
