<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="headline">
        Edit Project
        <v-spacer></v-spacer>
        <v-checkbox
          v-if="currentUser.email.includes('@buzzsolutions.co')"
          :value="prototype"
          @change="prototypeHandler"
          label="Prototyping Project?"
        ></v-checkbox>
      </v-card-title>
      <v-container fluid>
      <v-row justify="center">
        <v-col cols="6">
          <v-text-field
            label="Project Name"
            outlined
            :value="name"
            @input="projectNameHandler"
            dense
            id="project-name-edit"
            class="mx-auto"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Location"
            prepend-icon="mdi-map-marker"
            @input="projectLocationHandler"
            outlined
            dense
            :value="location"
            id="project-location-edit"
            class="mx-auto"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-textarea
            :value="description"
            outlined
            dense
            @input="projectDescriptionHandler"
            label="Description"
            id="project-description-edit"
            class="mx-auto"
          ></v-textarea>
        </v-col>
        <v-col cols="6">
          <DatePicker
            class="project-date project-input"
            label="Inspection Date"
            id="inspection-date"
            v-on:dateInput="getDate"
            :iDate="date"
            :projectsLoading="loadingProject"
          />
          <v-select
            outlined
            dense
            label="Teams"
            clearable
            chips
            clear-icon="clear"
            @change="teamChangeHandler"
            :value="teams"
            :items="items"
            id="select-teams"
            prepend-icon="mdi-account-multiple"
            item-text="name"
            item-value="tid"
            return-object
            multiple
            detectable-chips
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            outlined
            dense
            :value="first_reviewer"
            @change="reviewerChangeHandler"
            :items="reviewers"
            :loading="loadingUsers"
            item-text="name"
            return-object
            label="Reviewer"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-if="companyHas('project_typing')"
            outlined
            dense
            :value="project_type"
            @change="projectTypeHandler"
            label="Project Type"
            :items="['Transmission', 'Distribution', 'Other']"
          ></v-select>
        </v-col>
      </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :to="`/project/${project.pid}`">Cancel</v-btn>
        <v-btn color="primary" @click="onClickUpdateProject">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DatePicker from '@components/common/DatePicker.vue';
// import helpers from '@methods/helpers';
import services from '@services';
import moment from 'moment';
// import userHelper from '@methods/get-users.js';

export default {
  name: 'EditProjectForm',
  props: ['project'],
  components: {
    DatePicker,
  },
  data: () => ({
    name: '',
    prototype: false,
    teams: [],
    location: '',
    description: '',
    date: '',
    items: [],
    first_reviewer: {},
    project_type: '',
    reviewers: [],
    updates: {},
    loading: true,
    loadingUsers: false,
  }),
  methods: {
    ...mapActions(['updateProject', 'setNotification']),
    getDate(date) {
      this.updates.date = date;
      this.date = date;
    },
    projectNameHandler(name) {
      this.updates.name = name;
    },
    projectLocationHandler(location) {
      this.updates.location = location;
    },
    projectDescriptionHandler(description) {
      this.updates.description = description;
    },
    teamChangeHandler(teams) {
      this.updates.teams = teams;
    },
    reviewerChangeHandler(reviewer) {
      this.updates.first_reviewer = {
        uid: reviewer.uid,
      };
    },
    projectTypeHandler(type) {
      this.updates.project_type = type;
    },
    prototypeHandler(isPrototype) {
      this.updates.prototyping = isPrototype;
    },
    onClickUpdateProject() {
      const payload = {
        pid: this.project.pid,
        updates: this.updates,
      };
      console.log(JSON.stringify(this.updates));
      this.updateProject(payload)
        .then((res) => {
          this.setNotification({
            success: true,
            message: `Successfully updated ${res.data.name}`,
            color: '',
          });
        });
    },
  },
  computed: {
    ...mapGetters(['loadingProject', 'currentUser', 'companyHas', 'currentProject', 'currentCompany']),
  },
  mounted() {
    const date = moment(this.currentProject.date, 'll');
    this.name = this.project.name;
    this.description = this.project.description;
    this.location = this.project.location;
    this.date = date.isValid() ? date.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    this.teams = this.project.teams;
    this.first_reviewer = (this.project.first_reviewer) ? this.project.first_reviewer : {};
    this.project_type = (this.project.project_type) ? this.project.project_type : '';
    this.prototype = (this.project.prototyping) ? this.project.prototyping : false;
  },
  async created() {
    this.loading = true;
    this.loadingUsers = true;

    services.teams.all(`company_id=${this.currentUser.cid}`)
      .then((res) => {
        this.items = res.data.map((team) => ({
          name: team.name,
          tid: team.tid,
        }));
        this.loading = false;
      });

    services.users.all(`company_id=${this.currentUser.cid}`)
      .then((res) => {
        this.reviewers = [...res.data].map((user) => ({
          email: user.email,
          name: user.name,
          uid: user.uid,
        }));
        this.loadingUsers = false;
      });
  },
};
</script>
