const state = {
  clearFirst: false,
  overall_severity: '',
  field_inspection: false,
  clustering_uncertainty: false,
  workOrder: false,
  second_reviewer: {
    reason: '',
    uid: '',
    assigner_uid: '',
    name: '',
    email: '',
    reviewed: false,
  },
  first_reviewer: {
    assigner_uid: '',
    email: '',
    name: '',
    phone: '',
    uid: '',
  },
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  firstReviewerCleared: (state) => state.clearFirst,
  overallPriority: (state) => state.overall_severity,
  fieldInspection: (state) => state.field_inspection,
  workOrder: (state) => state.workOrder,
  clusteringUncertainty: (state) => state.clustering_uncertainty,
  secondReviewer: (state) => state.second_reviewer,
  firstReviewer: (state) => state.first_reviewer,
};

const actions = {
  setOverallPriority({ commit }, priority) {
    commit('set_overall_priority', priority);
  },
  // Takes in a string of either 'Yes' or 'No'
  setFieldInspection({ commit }, inspection) {
    commit('set_field_inspection', inspection);
  },
  setClusteringUncertainty({ commit }, clusteringUncertainty) {
    commit('set_clustering_uncertainty', clusteringUncertainty);
  },
  setSecondReviewer({ commit }, reviewer) {
    commit('set_second_reviewer', reviewer);
  },
  setFirstReviewer({ commit }, reviewer) {
    commit('set_first_reviewer', reviewer);
  },
  setWorkOrder({ commit }, workOrder) {
    commit('set_work_order', workOrder);
  },
  clearFirstReviewerForm({ commit }, cleared) {
    commit('clear_first_reviewer', cleared);
  },
};

const mutations = {
  set_overall_priority: (state, priority) => { state.overall_severity = priority; },
  set_field_inspection: (state, inspection) => {
    state.field_inspection = inspection === 'Yes';
  },
  set_clustering_uncertainty: (state, clusteringUncertainty) => {
    state.clustering_uncertainty = clusteringUncertainty;
  },
  set_second_reviewer: (state, reviewer) => {
    state.second_reviewer = reviewer;
  },
  set_first_reviewer: (state, reviewer) => {
    state.first_reviewer = reviewer;
  },
  set_work_order: (state, workOrder) => {
    state.workOrder = workOrder;
  },
  clear_first_reviewer: (state, cleared) => {
    state.clearFirst = cleared;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
