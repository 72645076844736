var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-toolbar',{attrs:{"tile":"","height":"75","dense":""}},[_c('ProjectRouteSelector'),_c('v-spacer'),_c('v-spacer'),_c('ExportXlsx',{attrs:{"images":_vm.images}}),_c('DownloadJsonData'),(_vm.isMobile)?_c('v-dialog',{attrs:{"width":"50%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),Object.assign({}, dialog, tooltip)),[_c('v-icon',[_vm._v("details")])],1)]}}],null,true)},[_vm._v(" Reports Detail ")])]}}],null,false,2880951373),model:{value:(_vm.reports),callback:function ($$v) {_vm.reports=$$v},expression:"reports"}},[_c('ReportsDialog',{attrs:{"pid":_vm.pid},on:{"closeReports":_vm.closeReportsDialog}})],1):_vm._e(),(_vm.isMobile)?_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v("photo_size_select_small")])],1)]}}],null,true)},[_vm._v(" View Labels ")])]}}],null,false,2205126746),model:{value:(_vm.abbreviations),callback:function ($$v) {_vm.abbreviations=$$v},expression:"abbreviations"}},[_c('AbbreviationsDialog',{on:{"closeAbbrebiations":_vm.closeAbbrDialog}})],1):_vm._e(),(_vm.isMobile)?_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),Object.assign({}, dialog, tooltip)),[_c('v-icon',[_vm._v("filter_alt")])],1)]}}],null,true)},[_vm._v(" Filter Images ")])]}}],null,false,3399466577),model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}},[_c('DashboardFiltersDialog',{attrs:{"images":_vm.images,"index":_vm.index},on:{"closeFilter":_vm.closeFilterDialog}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }