import axios from 'axios';
import { baseURL } from '@utils/endpoint';
import firebase from '../../firebase';

const { database } = firebase;
const userCollection = database.collection('users');

const checkPlan = (plan) => {
  const isProcessImagePlan = plan.nickname === 'Bronze' || plan.nickname === 'Silver' || plan.nickname === 'Gold';
  const isActive = plan.active;
  if (isProcessImagePlan && isActive) return true;
  return false;
};

export default (uid) => new Promise((resolve, reject) => {
  userCollection
    .where('uid', '==', uid)
    .get()
    .then((querySnapShot) => {
      const { docs } = querySnapShot;
      const users = docs.map((doc) => doc.data());
      const [currentUser] = users;
      currentUser.company
        .get()
        .then((documentReference) => {
          const company = documentReference.data();
          const { customerId } = company;

          axios.get(`${baseURL}/api/customers/${customerId}`)
            .then((res) => {
              const { subscriptions } = res.data;
              const plans = subscriptions.data
                .filter((subscription) => checkPlan(subscription.plan));

              resolve({
                type: (plans.length > 0) ? 'success' : 'error',
                data: plans,
              });
            })
            .catch((err) => reject(err));
        })
        // eslint-disable-next-line prefer-promise-reject-errors
        .catch((err) => reject({
          type: 'error',
          err,
        }));
    })
    .catch((err) => reject(err));
});
