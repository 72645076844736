var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',{staticClass:"headline",attrs:{"id":"label-header"}},[_vm._v(" "+_vm._s(_vm.label)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.onCloseDialog}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"fault-list-table","headers":_vm.headers,"items":_vm.images,"sort-by":['date'],"sort-desc":[true],"item-key":"filename+date","item-class":"filename","items-per-page":10},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")]}},{key:"item.location[0]",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatLatitude(item.location[0]))+" ")]}},{key:"item.location[1]",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatLongitude(item.location[1]))+" ")]}},{key:"item.pid",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary","to":("/project/" + (item.project_id))}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-tasks")])],1)]}}],null,true)},[_vm._v(" Go To Project ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary","href":item.processedImageUrl,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("image")])],1)]}}],null,true)},[_vm._v(" Go To "+_vm._s(item.filename)+" ")])]}},{key:"item.processed",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":item.process_tracking.slice(-1)[0].severity !== 'Medium',"color":_vm.chipColor(item.process_tracking.slice(-1)[0].severity).color,"small":""}},[_vm._v(" "+_vm._s(item.process_tracking.slice(-1)[0].severity)+" ")])]}}],null,true)})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.onCloseDialog}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }