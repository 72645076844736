<template>
  <v-card>
    <v-container fluid>
      <v-card-title class="headline">
        {{type}}
        <v-spacer></v-spacer>
        <v-btn icon small @click="onCloseDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-data-table
            :headers="headers"
            :items="images"
            item-key="filename+date"
            item-class="filename"
            class="elevation-1"
            hide-default-footer
            :loading="generating"
          >
            <template v-slot:[`item.date`]="{ item }">
              {{ formatDate(item.date) }}
            </template>
            <template v-slot:[`item.location[0]`]="{ item }">
              {{ formatLatitude(item.location[0]) }}
            </template>
            <template v-slot:[`item.location[1]`]="{ item }">
              {{ formatLongitude(item.location[1]) }}
            </template>
            <template v-slot:[`item.pid`]="{ item }">
              <v-btn icon small color="primary" :to="`/project/${item.project_id}`">
                <v-icon>fas fa-tasks</v-icon>
              </v-btn>
              <v-btn
              icon small color="primary"
              :href="item.processedImageUrl"
              target="_blank">
                <v-icon>image</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.processed`]="{ item }">
              <v-chip
                :dark="item.process_tracking.slice(-1)[0].severity !== 'Medium'"
                :color="chipColor(item.process_tracking.slice(-1)[0].severity).color"
                small
              >{{item.process_tracking.slice(-1)[0].severity}}</v-chip>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="onCloseDialog">Close</v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import helpers, { latitudeDMS, longitudeDMS } from '@methods/helpers';
import { generateImageViewer } from '@components/annotations/fabric-renderer';

export default {
  name: 'FaultSummary',
  props: ['images', 'type'],
  data: () => ({
    headers: [
      {
        text: 'File Name',
        value: 'filename',
        align: 'start',
        sortable: true,
      },
      {
        text: 'Date Uploaded',
        value: 'date',
      },
      {
        text: 'Severity',
        value: 'processed',
      },
      {
        text: 'Latitude',
        value: 'location[0]',
      },
      {
        text: 'Longitude',
        value: 'location[1]',
      },
      {
        text: 'Go to',
        value: 'pid',
      },
    ],
    generating: false,
  }),
  methods: {
    onCloseDialog() {
      this.$emit('closeDialog', false);
    },
    chipColor(severity) {
      const colors = {
        High: 'red',
        Medium: 'yellow lighten-1',
        Low: 'green',
        None: 'blue',
      };
      return {
        color: colors[severity],
      };
    },
    formatDate: (date) => helpers.convertToDate(date),
    formatLatitude: (latitude) => latitudeDMS(latitude),
    formatLongitude: (longitude) => longitudeDMS(longitude),
    async exportAnnotation(image) {
      this.generating = true;
      const [head, body, script] = await generateImageViewer(image);
      const newWin = window.open('', Date.now().toString());
      newWin.document.head.innerHTML = head;
      newWin.document.body.innerHTML = body;
      newWin.document.head.appendChild(script);
      this.generating = false;
    },
  },
};
</script>
