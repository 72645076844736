<template>
  <CardSkeletonLoader v-if="loadingTeams || loadingProjects" width="300" />
  <v-container fluid v-else>
    <ProjectsGrid v-show="view === 'grid'"/>
    <ProjectsTable v-show="view === 'table'" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import CardSkeletonLoader from '@components/common/loaders/CardSkeletonLoader.vue';
import ProjectsGrid from '@views/project-views/ProjectsGrid.vue';
import ProjectsTable from '@views/project-views/ProjectsTable.vue';

export default {
  name: 'ProjectsLayout',
  props: ['view'],
  components: {
    CardSkeletonLoader,
    ProjectsGrid,
    ProjectsTable,
  },
  computed: {
    ...mapGetters(['allProjects', 'loadingTeams', 'allTeams', 'loadingProjects']),
  },
};
</script>
