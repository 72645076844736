<template>
  <v-container id="folder-filters" class="py-0">
    <v-row dense align-content="center" no-gutters>
      <v-col col="6" align-self="center" class="py-0">
        <v-checkbox
          class="d-inline-block"
          label="Reviewed"
          dense
          v-model="filters.reviewed"
          @change="select('reviewed')"
        ></v-checkbox>
      </v-col>
      <v-col col="6" align-self="center" class="py-0">
        <v-checkbox
          class="d-inline-block"
          label="Unreviewed"
          dense
          v-model="filters.not_reviewed"
          @change="select('not_reviewed')"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'FolderFilters',
  data: () => ({
    filters: {
      reviewed: false,
      not_reviewed: false,
    },
    currentFilter: '',
  }),
  methods: {
    ...mapActions(['setFolderFilter']),
    select(value) {
      if (this.currentFilter === value) {
        this.currentFilter = '';
        this.setFolderFilter('');
      } else {
        this.currentFilter = value;
        const filterTypes = Object.keys(this.filters);
        filterTypes.forEach((filterType) => {
          if (filterType !== this.currentFilter) this.filters[filterType] = false;
          else {
            this.filters[filterType] = true;
          }
        });
      }
    },
  },
  watch: {
    currentFilter(newFilter) {
      // When currentFilter changes, setFolderFilter will be called
      this.setFolderFilter(newFilter);
    },
  },
};
</script>
