<template>
  <v-container fluid>
    <v-form ref="reportForm"
      >
      <p class="text-subtitle-1 text-center" id="instruction-text">
        Please fill the form and submit your inquiry.
      Support will get back to you. You can also check FAQ section first.</p>

      <v-text-field
        v-model="report.subject"
        label="Subject"
        :rules="[requiredRule]"
        outlined
        data-cy="subject"
      >
      </v-text-field>

      <v-textarea
        v-model="report.description"
        label="Description"
        :rules="[requiredRule]"
        counter="500"
        outlined
        data-cy="description"
      >
      </v-textarea>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="report.name"
            label="Name"
            :rules="[requiredRule]"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="report.email"
            outlined
            label="Email"
            :rules="[requiredRule, emailRule]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="report.company"
            label="Company"
            :rules="[requiredRule]"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="report.phone"
            outlined
            :rules="[requiredRule]"
            label="Phone"
          ></v-text-field>
        </v-col>
      </v-row>
       <vue-dropzone
        ref="reportDropZone"
        id="reportImages"
        :options="dropzoneOptions"
        @vdropzone-upload-progress="onUploadProgress"
        @vdropzone-complete="onUploaded"
        @vdropzone-total-upload-progress="totalUploadProgress"
        @vdropzone-removed-file="onDelete"
      ></vue-dropzone>
      <v-progress-linear
        indeterminate
        color="primary"
        v-if="uploadingImages"
      ></v-progress-linear>
      <p class="text-center">
        {{ (uploadingImages) ?
          'Give us a moment to finish up the upload'
          : `${totalProgress.toFixed(2)} %`}}
      </p>
      <br>
      <v-btn id="submit-report-button" @click="submitReport" :loading=loading block dark>
        Submit</v-btn>
    </v-form>
    <v-snackbar :color=color v-model="snackbar" timeout="-1">
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { baseURL } from '@utils/endpoint';

export default {
  name: 'ReportForm',
  components: {
    vueDropzone: vue2Dropzone,
  },
  data: () => ({
    report: {
      subject: '',
      description: '',
    },
    emailRule: (v) => /.+@.+\..+/.test(v) || 'Not a valid E-mail',
    requiredRule: (v) => !!v || 'Field is required',
    snackbar: false,
    message: '',
    loading: false,
    color: '',
    files: [],
    uploadingImages: false,
    totalProgress: 0,
    dropzoneOptions: {
      url: baseURL,
      maxFilesize: 10,
      parallelUploads: 1,
      addRemoveLinks: true,
      timeout: 60000,
      thumbnailWidth: 200,
    },
  }),
  methods: {
    async submitReport() {
      this.loading = true;
      const isFormValid = this.$refs.reportForm.validate();

      if (isFormValid) {
        try {
          this.report.files = this.files;
          const req = await axios.post(
            `${baseURL}/api/support/send-report`,
            this.report,
          );
          this.message = req.data.msg;
          this.color = 'success';
          this.snackbar = true;
        } catch (err) {
          this.color = 'error';
          this.message = err.response.data.msg;
          this.snackbar = true;
        }
      }
      this.loading = false;
    },
    // eslint-disable-next-line no-unused-vars
    onUploadProgress(file, progress, bytesSent) {
      this.uploadingImages = true;
      this.uploadProgress = Number.parseFloat(progress).toFixed(2);
    },
    // eslint-disable-next-line no-unused-vars
    totalUploadProgress(totaluploadprogress, totalBytes, totalBytesSent) {
      if (totaluploadprogress) this.totalProgress = totaluploadprogress;
    },
    onUploaded(res) {
      this.uploadingImages = false;
      const data = JSON.parse(res.xhr.response);
      this.files = [...this.files, ...data.uploads];
    },
    onDelete(file) {
      const data = JSON.parse(file.xhr.response);
      this.files = this.files.filter((f) => data.uploads.indexOf(f));
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'currentCompany']),
  },
  created() {
    const userInfo = {
      name: this.currentUser.name,
      email: this.currentUser.email,
      company: this.currentCompany.companyName,
      customerId: this.currentCompany.customerId,
      phone: this.currentUser.phone,
    };
    this.report = { ...this.report, ...userInfo };
  },
};
</script>

<style>
</style>
