import Vue from 'vue';
import Vuex from 'vuex';

import screens from './modules/screens';
import companies from './modules/companies';
import users from './modules/users';
import projects from './modules/projects';
import teams from './modules/teams';
import images from './modules/images';
import folders from './modules/folders';
import labels from './modules/labels';
import tasks from './modules/tasks';
import verifications from './modules/twilio/verifications';
import metadata from './modules/metadata';
import emails from './modules/twilio/emails';
import analytics from './modules/analytics';

// Stripe API Routes
import products from './modules/stripe/products';
import customer from './modules/stripe/customer';
import payments from './modules/stripe/payments';
import setupIntent from './modules/stripe/setup-intent';
import subscription from './modules/stripe/subscription';
import prices from './modules/stripe/prices';
import invoices from './modules/stripe/invoices';

// Components
import carousel from './modules/components/carousel';
import insights from './modules/components/insights';
import notifier from './modules/components/notifier';
import progress from './modules/components/progress';
import timer from './modules/components/timer';
import folderDetails from './modules/components/folder-details';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    screens,
    companies,
    users,
    verifications,
    projects,
    teams,
    images,
    folders,
    labels,
    tasks,
    products,
    customer,
    payments,
    setupIntent,
    subscription,
    prices,
    invoices,
    carousel,
    insights,
    notifier,
    progress,
    metadata,
    timer,
    emails,
    folderDetails,
    analytics,
  },
});
