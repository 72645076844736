import axios from 'axios';
import { baseURL } from '@utils/endpoint';

export default {
  all: (query) => axios.get(`${baseURL}/api/user/?${query}`),
  get: (id) => new Promise((resolve, reject) => {
    axios.get(`${baseURL}/api/user/${id}/document`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  }),

  create: (newUser) => new Promise((resolve, reject) => {
    axios.post(`${baseURL}/api/user`, newUser)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  }),
};
