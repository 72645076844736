const links = {
  guest: [
    { title: 'Home', icon: 'home', path: '/' },
    { title: 'Login', icon: 'touch_app', path: '/login' },
  ],
  auth: [
    { title: 'Settings', icon: 'account_box', path: '/settings/user/:uid/profile' },
    { title: 'Logout', icon: 'toggle_off', path: '/' },
  ],
  side: [
    { title: 'Projects', icon: 'assignment', path: '/projects/view/grid' },
    {
      title: 'Analytics',
      icon: 'bar_chart',
      path: '/analytics',
      children: [
        { title: 'Insights', path: '/analytics' },
        { title: 'Settings', path: '/analytics/settings/permissions' },
      ],
    },
    {
      title: 'Billing',
      icon: 'attach_money',
      children: [
        { title: 'Overview', path: '/billing/overview', props: true },
        { title: 'Subscriptions', path: '/billing/subscription-plans/access' },
        { title: 'Reports', path: '/billing/reports' },
        { title: 'Payment Methods', path: '/billing/payment_methods' },
        { title: 'Invoices', path: '/billing/invoices' },
      ],
    },
    { title: 'Company', icon: 'location_city', path: '' },
    { title: 'Help', icon: 'help', path: '/help/report' },
    { title: 'Admin', icon: 'admin_panel_settings', path: '/admin/instances' },
  ],
};

export default links;
