import axios from 'axios';
import { baseURL } from '@utils/endpoint';

const state = {
  test: false,
  verification: {},
  verifying: false,
  loading: false,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  testAuthEndpoint: (state) => state.test,
  currentVerification: (state) => state.verification,
  verifyingCode: (state) => state.verifying,
  loadingVerification: (state) => state.loading,
};

const actions = {
  testVerifications({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}/api/auth/two-factor/test`)
        .then((res) => {
          commit('test_verifications');
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  getVerification({ commit }, payload) {
    // Uses 'sid' to search for the verification details
    commit('loading_verification');
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}/api/auth/${payload.verification}/${payload.sid}`)
        .then((res) => {
          resolve(res);
          commit('fetch_verification', res.data);
        })
        .catch((err) => {
          reject(err);
          commit('finish_loading_verification');
        });
    });
  },
  createVerification({ commit }, payload) {
    // Uses 'uid' to search for user's phone number, verifies the number
    // and turns it into E.164 format. It then sends the verification code
    // through SMS or email.
    const { channel } = payload;

    return new Promise((resolve, reject) => {
      axios.post(`${baseURL}/api/auth/${payload.verification}/${payload.uid}`, { channel })
        .then((res) => {
          resolve(res);
          commit('set_verification', res.data);
        })
        .catch((err) => reject(err));
    });
  },
  updateVerification({ commit }, payload) {
    // Updates the status of the verification
    return new Promise((resolve, reject) => {
      axios.put(`${baseURL}/api/auth/${payload.verification}/${payload.uid}`)
        .then((res) => {
          resolve(res);
          commit('set_verification', res.data);
        })
        .catch((err) => reject(err));
    });
  },
  verifyPasswordResetCode({ commit }, payload) {
    commit('verifying_code', true);
    return new Promise((resolve, reject) => {
      axios.post(`${baseURL}/api/auth/${payload.verification}/verify/${payload.uid}`, { code: payload.code })
        .then((res) => {
          commit('verifying_code', false);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
};

const mutations = {
  test_verifications: (state) => { state.test = true; },
  loading_verification: (state) => { state.loading = true; },
  verifying_code: (state, verifying) => { state.verifying = verifying; },
  fetch_verification: (state, verification) => {
    state.verification = verification;
    state.loading = false;
  },
  set_verification: (state, verification) => {
    state.verification = verification;
    if (state.loading) state.loading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
