<template>
  <div class="user-settings">
    <v-container fluid>
      <v-row justify="center">
        <span class="green--text" v-if="isUpdated">{{updateStatus}}</span>
        <v-col cols="11">
          <v-text-field
            label="First Name"
            outlined
            v-model="firstName"
            id="first-name-field"
            :loading="loadingUser"
            class="mx-auto"
          ></v-text-field>
        </v-col>
        <v-col cols="11">
          <v-text-field
            label="Last Name"
            outlined
            v-model="lastName"
            :loading="loadingUser"
            id="last-name-field"
            class="mx-auto"
          ></v-text-field>
        </v-col>
        <v-col cols="11">
          <v-text-field
            label="Email"
            outlined
            v-model="email"
            :loading="loadingUser"
            id="email-field"
            class="mx-auto"
          ></v-text-field>
        </v-col>
        <v-col cols="11">
          <v-text-field
            label="Phone Number"
            outlined
            v-model="phone"
            :loading="loadingUser"
            type="phone"
            id="phone-field"
            class="mx-auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn color="primary" right @click="onSaveUserProfile">Save</v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UserSettings',
  props: ['uid'],
  data: () => ({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    updateStatus: '',
    isUpdated: false,
  }),
  methods: {
    ...mapActions(['updateUser', 'updateUserAuth']),
    onSaveUserProfile() {
      const updatedUser = this.currentUser;
      updatedUser.firstName = this.firstName;
      updatedUser.lastName = this.lastName;
      updatedUser.email = this.email;
      updatedUser.phone = this.phone;

      this.updateUserAuth(updatedUser)
        .then(() => {
          this.updateUser(updatedUser)
            .then((res) => {
              const { code, msg } = res;

              if (code === 'SUCCESS') {
                this.updateStatus = msg;
                this.isUpdated = true;
              }
            });
        });
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'loadingUser']),
  },
  mounted() {
    const { currentUser } = this;
    if (currentUser.firstName) this.firstName = currentUser.firstName;
    if (currentUser.lastName) this.lastName = currentUser.lastName;
    if (currentUser.email) this.email = currentUser.email;
    if (currentUser.phone) this.phone = currentUser.phone;
  },
};
</script>
