export default {
  general: [
    { header: 'Project', key: 'project' },
    { header: 'Site Name', key: 'site' },
    { header: 'Structure Number', key: 'structure' },
    { header: 'Image Name', key: 'filename' },
    { header: 'Fault Type', key: 'fault' },
    { header: 'Description', key: 'description' },
    { header: 'Image Caption', key: 'image_caption' },
    { header: 'Priority', key: 'priority' },
    { header: 'Severity', key: 'severity' },
    { header: 'Work Order', key: 'work_order' },
    { header: 'Work Order Number', key: 'work_order_number' },
    { header: 'Date Inspected', key: 'date_inspected' },
    { header: 'Date Processed', key: 'date_processed' },
    { header: 'Attachment', key: 'attachment' },
    { header: 'Images', key: 'images' },
    { header: 'Latitude', key: 'latitude' },
    { header: 'Longitude', key: 'longitude' },
    { header: 'Structure Notes', key: 'structure_notes' },
  ],

  nf: [
    { header: 'Pole # (a33527)', key: 'pole_number' },
    { header: 'WR Site ID', key: 'wr_site_id' },
    { header: 'Work Request', key: 'work_request' },
    { header: 'Entity', key: 'entity' },
    { header: 'Title', key: 'title' },
    { header: 'Date Inspected', key: 'date_inspected' },
    { header: 'Description', key: 'description' },
    { header: 'Image Caption', key: 'image_caption' },
    { header: 'Notes', key: 'folder_note' },
    { header: 'Requested On', key: 'requested_on' },
    { header: 'Requested By', key: 'requested_by' },
    { header: 'Work Type', key: 'work_type' },
    { header: 'Priority', key: 'priority' },
    { header: 'Start No Earlier Than', key: 'start' },
    { header: 'Finish No Later Than', key: 'finish' },
    { header: 'Estimated cost_amt', key: 'est_cost_amt' },
    { header: 'Estimated duration_amt', key: 'est_duration_amt' },
    { header: 'Estimated duration_uom', key: 'est_duration_uom' },
    { header: 'Planner', key: 'planner' },
    { header: 'Supervisor', key: 'supervisor' },
    { header: 'Approval Comment Text', key: 'approval_cmt_txt' },
    { header: 'Approval Status', key: 'approval_status' },
    { header: 'Approval Date', key: 'approval_date' },
    { header: 'Approved Employee SL - Work Classification (Cat 1)', key: 'approved_employee_sl' },
    { header: 'DIST Deficiencies (Cat 2)', key: 'dist_deficiencies' },
    { header: 'DL - Work Classification (Cat 3)', key: 'dl_work_classificiation' },
    { header: 'Keywords', key: 'keywords' },
    { header: 'Created on date', key: 'created_on_date' },
    { header: 'Attachment', key: 'attachment' },
    { header: 'Attachment Title', key: 'attachment_title' },
    { header: 'Structure Type (a33515)', key: 'structure_type' },
    { header: 'Conductor Type (a33516)', key: 'conductor_type' },
    { header: 'Truck Accessable (a33517)', key: 'truck_accessable' },
    { header: 'Flagman Required (a33518)', key: 'flagman' },
    { header: 'Outage Type (a33519)', key: 'outage_type' },
    { header: 'Risk (a33520)', key: 'risk' },
    { header: 'GPS Latitude (a33521)', key: 'latitude' },
    { header: 'GPS Longitude (a33522)', key: 'longitude' },
    { header: 'WR Source (a33523)', key: 'wr_source' },
    { header: 'Capital Budget Year (a33524)', key: 'capital_budget_year' },
    { header: 'Location (a33528)', key: 'location' },
    { header: 'Start Address (LOCNstaddr)', key: 'start_address' },
    { header: 'End Address (LOCNendaddr)', key: 'end_address' },
    { header: 'GIS Object ID (LOCNgis_oi)', key: 'gis_object_id' },
    { header: 'Start Longitude (LOCNstlng)', key: 'start_longitude' },
    { header: 'Start Latitude (LOCNstlng)', key: 'start_latitude' },
    { header: 'End Longitude (LOCNendlong)', key: 'end_longitude' },
    { header: 'End Latitude (LOCNendlat)', key: 'end_latitude' },
    { header: 'City (LOCNcity)', key: 'city' },
    { header: 'State (LOCNstate)', key: 'state' },
    { header: 'Zip Code (LOCNzpcode)', key: 'zip_code' },
    { header: 'Contact Person (LOCNcontpsn)', key: 'contact_person' },
    { header: 'Contact Email (LOCNconteml)', key: 'contact_email' },
    { header: 'Contact Phone (LOCNcontphn)', key: 'contact_phone' },
    { header: 'Start Elevation (LOCNSrt_Elv', key: 'start_elevation' },
    { header: 'End Elevation (LOCNEnd_Elv', key: 'end_elevation' },
    { header: '##DP Processed Flag##', key: 'dp_processed_flag' },
    { header: '##DP Processed Status##', key: 'dp_processed_status' },
    { header: '##DP Error Text##', key: 'dp_error_text' },
  ],
};
