<template>
  <div class="homePage">
    <div class="dark-overlay landing-inner">
      <div style="margin-top: 100px">
        <img src="../assets/buzz-icon-yellow.svg" alt="Buzz Solutions">
        <h1 class="white--text">Buzz Solutions</h1>
        <h3 class="white--text">PowerAI</h3>
        <span class="white--text">
          <p>
            AI-Powered Software Platform to Analyze your Energy Infrastructure
          </p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Landing',
};
</script>

<style scoped>
  .homePage {
    text-align: center;
    width: 100%;
    position: relative;
    background: url('../assets/washed-out-wires.png') no-repeat;
    background-size: cover;
    height: calc(100vh - 60px);
    margin-bottom: -50px;
    -o-backgorund-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
  }
  .dark-overlay {
    background-color: rgb(0,0,0,0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
