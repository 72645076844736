<template>
  <v-card>
    <v-card-title class="headline">Create a new Customer Instance</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col xs="12" sm="12" md="7" lg="7" xl="7">
            <v-text-field
              v-model="company.companyName"
              outlined
              label="Name of Company/Customer"
              :error-messages="errors.name"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="5" lg="5" xl="5">
            <v-text-field
              v-model="company.billingEmail"
              outlined
              label="Primary Billing Email"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="company.bucket"
              outlined
              label="Image Bucket Name"
              prefix="buzz-powerai-cbucket-"
              @input="reformat"
              :error-messages="errors.bucket"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="7" lg="7" xl="7">
            <v-text-field
              v-model="company.ml_model"
              outlined
              label="Model"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="close">Close</v-btn>
      <v-btn text color="primary" @click="save" :loading="creatingCustomer">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep, trim } from 'lodash';
import services from '@services';

export default {
  name: 'CreateInstanceForm',
  data: () => ({
    company: {
      companyName: '',
      billingEmail: '',
      bucket: ' ',
      ml_model: '',
    },
    errors: {
      name: '',
      bucket: '',
    },
    service: [],
    services: ['Bronze Plan - $0.95 USD / image', 'Silver Plan - $0.90 USD / image', 'Gold Plan - $0.80 USD / image', 'Dashboard - $150 USD / month'],
  }),
  methods: {
    ...mapActions(['createCompany', 'createStorageBucket', 'createCustomer']),
    async save() {
      // Checks if required fields are filled out. Will not make requests if empty.
      if (this.company.companyName === '') {
        this.errors.name = 'A company name is required';
        return;
      }

      if (this.company.bucket === '') {
        this.errors.bucket = 'A bucket name is required';
        return;
      }

      // Make a deep copy of the company property within `data`
      const newCompany = cloneDeep(this.company);

      // Set the copied company with the naming convention of our GCP Storage bucketes
      newCompany.bucket = `buzz-powerai-cbucket-${newCompany.bucket}`;

      // Initialize the new company with an empty list of employees
      newCompany.employees = [];

      // If ml_model is an empty string, set it as null
      newCompany.ml_model = (this.company.ml_model.length > 0) ? this.company.ml_model : null;

      // Create the GCP Storage bucket
      const bucketResponse = await this.createStorageBucket(newCompany.bucket);

      // If it is either the dev or stage environment,
      // just use the customer id in the env.local file
      if (process.env.VUE_APP_ENVIRONMENT === 'dev') newCompany.customerId = process.env.VUE_APP_DEV_CUSTOMER;
      if (process.env.VUE_APP_ENVIRONMENT === 'stage') newCompany.customerId = process.env.VUE_APP_STAGE_CUSTOMER;

      // If the bucket was successfully created, create the company document on Firestore
      if (bucketResponse.status === 200) {
        const companyResponse = await this.createCompany(newCompany);

        // If the company was successfully created, make a Stripe customer id for the client
        if (companyResponse.status === 200) {
          // If it is the production environment, create a new customer
          if (process.env.VUE_APP_ENVIRONMENT === 'main') {
            await this.createCustomer(companyResponse.data.cid);
          }

          services.storage.set_bucket_compression(companyResponse.data.cid)
            .then((res) => {
              console.info(res.data.message);
            })
            .catch((err) => {
              console.error('There was an error enabling image compression', err);
            });

          this.$nextTick(() => {
            // Resets the form v-models
            Object.keys(this.company).forEach((key) => { this.company[key] = ''; });

            // Close the dialog
            this.close();
          });
        }
      } else {
        // eslint-disable-next-line
        console.error('There was an error creating a bucket for the client');
      }
    },
    close() {
      this.$emit('close');
    },
    reformat() {
      const bucket = trim(this.company.bucket);
      this.company.bucket = bucket.split(' ').join('-');
    },
  },
  computed: {
    ...mapGetters(['creatingCustomer']),
  },
};
</script>
