<template>
  <v-container fluid>
    <EmployeesTable title="Employees" :employees="employeeData" :cid="cid" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import EmployeesTable from '@components/company/EmployeesTable.vue';

export default {
  name: 'Employees',
  props: ['cid'],
  components: {
    EmployeesTable,
  },
  computed: {
    ...mapGetters(['currentCompany', 'loadingCompany', 'allEmployees']),
    employeeData() {
      const existingEmployees = this.allEmployees.filter((e) => e != null);
      const employeeList = existingEmployees.map((employee) => ({
        firstName: employee.firstName,
        lastName: employee.lastName,
        phone: employee.phone,
        email: employee.email,
        adminStatus: (employee.adminStatus) ? 'Admin' : 'User',
        teams: employee.teams,
        uid: employee.uid,
      }));
      return employeeList;
    },
  },
};
</script>
