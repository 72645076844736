import axios from 'axios';
import { baseURL } from '@utils/endpoint';

const state = {
  secret: '',
  loading: false,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  clientSecret: (state) => state.secret,
  loadingSetupIntents: (state) => state.loading,
};

const actions = {
  createSetupIntent({ commit }, customerId) {
    return new Promise((resolve, reject) => {
      axios.post(`${baseURL}/api/setup_intents/${customerId}`)
        .then((res) => {
          const clientSecret = res.data.client_secret;
          commit('create_setup_intent', clientSecret);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  getSetupIntent({ commit }, retrieve) {
    commit('loading_setup_intent');
    const { clientSecret, retrieveSetupIntent } = retrieve;
    return new Promise((resolve, reject) => {
      retrieveSetupIntent(clientSecret)
        .then((result) => {
          const { setupIntent } = result;
          resolve(setupIntent);
        })
        .catch((err) => reject(err));
    });
  },
};

const mutations = {
  create_setup_intent: (state, clientSecret) => {
    state.secret = clientSecret;
  },
  loading_setup_intent: (state) => {
    state.loading = true;
  },
  finish_loading_setup_intent: (state) => {
    state.loading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
