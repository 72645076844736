<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        id="export-xlsx-btn"
        icon
        x-large
        color="primary"
        v-bind="attrs"
        v-on="on"
        @click="exportXlsx"
      >
        <v-icon>fas fa-file-excel</v-icon>
      </v-btn>
    </template>
    <span>Export Excel Spreadsheet</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import services from '@services';
import Excel from '@utils/excel';

export default {
  name: 'ExportXlsx',
  data: () => ({
    excel: null,
    DEFECTIVE_LABELS: ['High', 'Medium', 'Low'],
  }),
  methods: {
    ...mapActions(['setRetrieveImage', 'setRetrieveFolder']),
    // eslint-disable-next-line consistent-return
    async exportXlsx() {
      this.initializeExcel();
      const { pid } = this.currentProject;
      const { cid } = this.currentCompany;

      try {
        this.setRetrieveFolder(true);

        const folderRes = await services.folders.getByProject(cid, pid);

        if (folderRes.status === 200) {
          this.setRetrieveFolder(false);
          this.setRetrieveImage(true);

          const imageRes = await services.images.getByProject(cid, pid);
          if (imageRes.status === 200) {
            this.setRetrieveImage(false);

            const attachmentPrefix = this.companyConfig('attachmentPrefix');
            const attachmentSubprefix = this.companyConfig('attachmentSubprefix');
            this.excel.createRows(
              this.currentProject.name,
              folderRes.data,
              imageRes.data,
              attachmentPrefix,
              attachmentSubprefix,
            );

            // Styles the headers to distinguish easier for the user
            this.excel.styleHeaders();
            // Expand each cells to the widest length within the column
            this.excel.textWrap();
            // Freezes the top row headers
            this.excel.freezeViews();

            // Generate filename and write to file
            const filename = this.excel.getFilename();
            this.excel.write(filename, 'xlsx');
          }
        }
      } catch (err) {
        return err;
      }
    },
    initializeExcel() {
      this.excel = new Excel(this.currentProject.name, this.currentCompany.companyName);
      this.excel.createWorkbook(this.currentUser.name);
      this.excel.addWorksheet('sheet');
      this.$nextTick(() => {
        this.excel.setColumns();
      });
    },
  },
  computed: {
    ...mapGetters(['currentCompany', 'companyHas', 'companyConfig', 'currentProject', 'currentUser']),
  },

  mounted() {
    this.initializeExcel();
  },
};
</script>
