<template>
  <v-container fluid>
    <Loading v-if="loadingCurrentImage"/>
    <TabViews :vertical="false" :centered="false" :tabs="reactiveTabs" v-else />
    <SnackBar
      :snackbar="imageNotification.success"
      :text="imageNotification.message"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import Loading from '@components/common/Loading.vue';
import TabViews from '@components/common/TabViews.vue';
import SnackBar from '@components/common/SnackBar.vue';

export default {
  name: 'Images',
  props: ['pid', 'filename', 'tab'],
  components: { Loading, TabViews, SnackBar },
  data: () => ({
    imageTabs: [
      { tabId: 'original', title: 'Original Images' },
      { tabId: 'ai', title: 'AI Processing' },
    ],
  }),
  methods: {
    ...mapActions(['getProject', 'getImagesByProject', 'getFoldersByProject', 'setImagesLoading', 'setLoadingFolders', 'fetchLabelsForProject', 'setLoadingImage']),
    annotationRoute(tab) {
      // original tab: /project/:pid/images/original/:filename/:date/annotate
      // ai tab: /project/:pid/images/ai/:filename/processed/:date/edit
      if (tab === 'original') return 'annotate';
      return 'processed/edit';
    },
  },
  computed: {
    ...mapGetters([
      'loadingProject',
      'currentCompany',
      'imageNotification',
      'loadingImages',
      'allImages',
      'currentImage',
      'processingImage',
      'processTasks',
      'loadingFolders',
      'companyHas',
      'currentProject',
      'loadingCurrentImage',
    ]),
    currentRoute() { return this.$route.name; },
    reactiveTabs() {
      // Getting each tabs within the data
      const tabs = cloneDeep(this.imageTabs);
      let path;

      // Find the processed images within this image dataset
      const processedImages = this.allImages.filter((image) => image.processedImageUrl);

      // Iterate through and find the index of each tab's respective id (original/ai)
      this.imageTabs.forEach((t) => {
        const index = tabs.findIndex((tab) => tab.tabId === t.tabId);
        // If it's on the original tab and there are images found, set the tab
        if (t.tabId === 'original' && this.allImages.length > 0) tabs[index].route = `/project/${this.pid}/images/original/${this.allImages[0].filename}`;

        // If it's the 'ai' tab, then figure out if it has processed image
        // and set the route appropriately
        if (t.tabId === 'ai') {
          if (processedImages.length > 0) path = `/project/${this.pid}/images/${t.tabId}/${processedImages[0].filename}/processed`;
          else path = `/project/${this.pid}/images/${t.tabId}/${(t.tabId === 'original') ? 'no-images' : 'no-processed-images'}/processed`;
          tabs[index].route = path;
        }
      });
      return tabs;
    },
  },
  watch: {
    currentImage(newCurrent, oldCurrent) {
      if (newCurrent === undefined) return;
      // Define the current filename and the previous filename
      const newFileName = newCurrent.filename;
      const oldFileName = oldCurrent === undefined ? '' : oldCurrent.filename;

      const { tab } = this.$route.params;

      // If they are different then figure out if user is currently annotating
      if (newFileName !== oldFileName) {
        const isAnnotating = this.$route.name === 'HumanInTheLoopEdit' || this.$route.name === 'HumanInTheLoop';

        // If they aren't, push it back to either 'original' or 'processed' tab
        if (!isAnnotating) {
          const path = `/project/${this.pid}/images/${tab}/${newCurrent.filename}/${(tab === 'original') ? '' : 'processed'}`;

          // eslint-disable-next-line no-console
          this.$router.push({ path }).then(() => {})
            // eslint-disable-next-line no-console
            .catch((err) => console.log(err));
        } else {
          // Otherwise get the current route's tab param and push to it
          const path = `/project/${this.pid}/images/${tab}/${newCurrent.filename}/${newCurrent.date}/${this.annotationRoute(tab)}`;
          this.$router.push({ path }).catch();
        }
      }
    },
  },
  async created() {
    let project;
    this.setLoadingImage(true);
    if (Object.keys(this.currentProject).length < 1) {
      const res = await this.getProject(this.pid);
      project = res.data;
    } else project = this.currentProject;
    const { pid } = project;
    this.setImagesLoading(true);

    const { cid } = this.currentProject;
    const categorize = this.companyHas('categorize_labels') ? 'yes' : 'no';
    const custom = this.companyHas('custom_labels') ? 'yes' : 'no';
    const labels = await this.fetchLabelsForProject({
      pid, cid, categorize, custom,
    });
    const response = await this.getFoldersByProject({ pid, cid });
    const imageResponse = await this.getImagesByProject({ pid, cid });
    const images = imageResponse.data;

    if (response.status === 200 && labels.status === 200) {
      if (images.length === 0) this.$router.push({ path: `/project/${pid}/images/original/no-images` });
      else {
        this.imageTabs.forEach((tab, index) => {
          // If the tab is 'ai', set the tab route to include filename of the
          // first image that HAS BEEN processed
          // Otherwise go from 'ai' -> 'original', find the first image of allImages array
          if (tab.tabId === 'ai') {
            const processedImages = images.filter((image) => image.processedImageUrl);

            if (processedImages.length > 0) this.imageTabs[index].route = `/project/${pid}/images/${tab.tabId}/${processedImages[0].filename}/processed`;
            else this.imageTabs[index].route = `/project/${pid}/images/ai/no-processed-images/processed`;
          } else this.imageTabs[index].route = `/project/${pid}/images/${tab.tabId}/${images[0].filename}`;
        });
      }
      this.setLoadingFolders(false);
      this.setImagesLoading(false);
      this.setLoadingImage(false);
    }
  },
  mounted() {
    this.$forceUpdate();
  },
};
</script>
