<template>
  <div id="reports-details">
    <p class="text--primary" id="structure-defects">
      <span class="font-weight-bold text-sm-caption">Structure Defects: </span>
      <span class="font-weight-regular green--text">{{defections.structure}}</span>
    </p>
    <p class="text--primary" id="cross-defects">
      <span class="font-weight-bold text-sm-caption">Cross Defects: </span>
      <span class="font-weight-regular red--text text-sm-caption">{{defections.cross}}</span>
    </p>
    <p class="text--primary" id="equipment-defects">
      <span class="font-weight-bold text-sm-caption">Equipment Defects: </span>
      <span
        class="font-weight-regular yellow--text text-sm-caption"
      >{{defections.equipment}}</span>
    </p>
    <p class="text--primary" id="other-defects">
      <span class="font-weight-bold text-sm-caption">Other Defects: </span>
      <span class="font-weight-regular blue--text text-sm-caption">{{defections.other}}</span>
    </p>
    <p class="text--primary" id="total-processed">
      <span class="font-weight-bold text-sm-caption">Total Processed: </span>
      <span
        class="font-weight-regular blue--text text-sm-caption"
      >{{ totalImages.processed }}</span>
    </p>
    <p class="text--primary" id="total-images">
      <span class="font-weight-bold text-sm-caption">Total Images: </span>
      <span class="font-weight-regular blue--text text-sm-caption">
        {{ totalImages.original }}
      </span>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { structure, cross, equipment } from '@components/analytics/insights/filters/faults';

export default {
  name: 'ReportsDetails',
  computed: {
    ...mapGetters(['currentProject', 'allImages', 'currentImage']),
    defections() {
      const { length } = this.currentImage.process_tracking;
      const { labels } = this.currentImage.process_tracking[length - 1];

      const defects = {
        structure: 0,
        cross: 0,
        other: 0,
        equipment: 0,
      };

      labels.forEach((classType) => {
        if (structure.includes(classType)) defects.structure += 1;
        else if (cross.includes(classType)) defects.cross += 1;
        else if (equipment.includes(classType)) defects.equipment += 1;
        else defects.other += 1;
      });

      return defects;
    },
    totalImages() {
      const count = {
        original: this.allImages.length,
        processed: this.allImages.filter((image) => image.processedImageUrl).length,
      };
      return count;
    },
  },
};
</script>
