<template>
    <div class="create-project">
      <v-card>
        <v-container>
          <v-card-title class="headline">
            Create Project
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="newProject.name"
                  label="Project Name"
                  outlined
                  id="input-name"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  prepend-icon="mdi-map-marker"
                  v-model="newProject.location"
                  outlined
                   id="input-location"
                  label="Location"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-textarea
                      dense
                      v-model="newProject.description"
                      label="Project Description"
                      outlined
                      id="input-description"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="6">
                    <DatePicker
                      label="Inspection Date"
                      v-on:dateInput="getDate"
                      :iDate="newProject.date"
                      id="input-date"
                    />
                    <v-select
                      dense
                      :items="teamItems"
                      item-text="name"
                      item-value="tid"
                      id="input-list"
                      outlined
                      prepend-icon="mdi-account-multiple"
                      label="Teams"
                      multiple
                      chips
                      v-model="newProject.teams"
                      return-object
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <!-- make date picker -->
              <v-col cols="6">
                <v-select
                  outlined
                  dense
                  v-model="newProject.first_reviewer"
                  item-text="name"
                  return-object
                  label="Select First Reviewer"
                  :items="reviewers"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  outlined
                  dense
                  :items="['Transmission', 'Distribution', 'Other']"
                  label="Project Type"
                  v-model="newProject.project_type"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="close-btn"
              @click="close"
              text
            >Close</v-btn>
            <v-btn
              text
              color="primary"
              class="save-btn"
              @click="onCreateProject"
            >Create New Project</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import DatePicker from '@components/common/DatePicker.vue';
import helpers from '@methods/helpers';
import userHelper from '@methods/get-users';

export default {
  name: 'CreateProjectForm',
  props: ['cid', 'teams'],
  components: {
    DatePicker,
  },
  data: () => ({
    newProject: {
      name: '',
      description: '',
      location: '',
      teams: [],
      cid: '',
      date: '',
      first_reviewer: {},
      project_type: '',
    },
    reviewers: [],
    teamItems: [],
  }),
  methods: {
    ...mapActions(['createProject', 'updateTeam']),
    getDate(date) {
      this.newProject.date = date;
    },
    clearForm() {
      this.newProject.name = '';
      this.newProject.description = '';
      this.newProject.location = '';
      this.newProject.teams = [];
      this.newProject.date = '';
      this.newProject.first_reviewer = {};
    },
    close() {
      this.$emit('closeDialog', false);
    },
    displayProjectCreationSuccess(projectName) {
      const message = `${projectName} has been successfully created`;
      this.$emit('createProjectSuccess', message);
    },
    async onCreateProject() {
      this.newProject.cid = this.cid;
      const currentProject = cloneDeep(this.newProject);

      currentProject.createdAt = new Date();
      currentProject.isCompressed = true;
      const teamsList = currentProject.teams.map((team) => helpers.getDocReference('teams', team.tid));
      currentProject.teams = teamsList;

      const createdProject = await this.createProject(currentProject);
      const teamsInProject = createdProject.data.teams.map((team) => team.id);
      const teamsToUpdate = this.teamItems.filter((team) => teamsInProject.includes(team.tid));
      teamsToUpdate.forEach(async (team) => {
        team.projects.push(helpers.getDocReference('projects', createdProject.data.pid));
        await this.updateTeam(team);
      });
      if (createdProject.code === 'SUCCESS') {
        this.displayProjectCreationSuccess(createdProject.data.name);
        this.clearForm();
        this.close();
      }
    },
  },
  computed: {
    ...mapGetters(['allTeams', 'allProjects', 'currentUser', 'currentCompany', 'companyHas']),
  },
  mounted() {
    const { teams, adminStatus } = this.currentUser;

    if (adminStatus) this.teamItems = cloneDeep(this.allTeams);
    else {
      const userTids = teams.map((team) => team.id);
      this.newProject.cid = this.cid;
      this.teamItems = this.teams.filter((team) => userTids.includes(team.tid));
    }
  },
  async created() {
    this.reviewers = await userHelper
      .getFirstReviewer(this.currentCompany.employees, this.currentUser.uid);
  },
};
</script>
