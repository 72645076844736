<template>
  <div class="image-upload">
    <v-tabs
      v-model="tab"
      align-with-title
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
         File Uploader
      </v-tab>
      <v-tab>
        Folder Uploader
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <form>
          <vue-dropzone
            ref="directDropzone"
            id="drop"
            :options="dropzoneOptions"
            @vdropzone-upload-progress="onUploadProgress"
            @vdropzone-total-upload-progress="totalUploadProgress"
            @vdropzone-sending="beforeSend"
            @vdropzone-files-added="handleAddedFiles"
            @vdropzone-queue-complete="onQueueComplete"
            style="max-height: 500px; overflow: scroll;"
          ></vue-dropzone>
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="uploadingImages"
          ></v-progress-linear>
          <p class="text-center">
            {{ (uploadingImages) ?
              'Give us a moment to finish up the upload'
              : `${totalProgress.toFixed(2)} %`}}
          </p>
        </form>
      </v-tab-item>
      <v-tab-item>
          <div style="text-align:center;">
            <v-btn
              color="primary"
              class="ma-2 white--text"
              @click="selectFolder"
            >
              Select a Folder
              <v-icon
                right
                dark
              >
                mdi-folder
              </v-icon>
            </v-btn>
            <input style="display:none;" type="file"
                  name="fileList"
                  id="folderUploadInput"
                  @change="onFolderSelected"
                  webkitdirectory multiple />
          </div>
      </v-tab-item>
    </v-tabs-items>
    <v-overlay :value="showOverlay" style="text-align:center;" >
      <v-progress-circular
      :rotate="-90"
      :size="100"
      :width="15"
      :value="processedPercent"
      :indeterminate="indeterminate"
      color="white"
    >
      {{ processedPercent }}
    </v-progress-circular>
    <div>{{uploadStatus}}</div>
    </v-overlay>
    <DialogMessage
      :show="this.showMessage"
      @updateShow="this.updateShow"
      :title="'Maximum File Size Exceeded'"
      :description="'The total file size of the folder ' +
       'exceeded the size limit of 2GB. ' +
       'For large file upload, please contact us for assistance '" />
  </div>
</template>
<script>
import DialogMessage from '@components/common/DialogMessage.vue';
import vue2Dropzone from 'vue2-dropzone';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import helpers from '@methods/helpers';
import { baseURL } from '@utils/endpoint';

export default {
  name: 'DirectImageUploader',
  components: {
    DialogMessage,
    vueDropzone: vue2Dropzone,
  },
  props: ['cid', 'pid'],
  data() {
    return {
      tab: null,
      showMessage: false,
      showOverlay: false,
      indeterminate: false,
      processedPercent: 0,
      uploadStatus: 'Uploading, please wait...',
      cloudsvg: '../../../../../static/img/upload.svg',
      fillColor: 'LightSkyBlue',
      totalProgress: 0,
      fileListLength: 0,
      dropzoneOptions: {
        paramName: 'file',
        acceptedFiles: 'image/*',
        url: 'https://storage.googleapis.com/',
        thumbnailWidth: 200,
        addRemoveLinks: true,
        dictDefaultMessage: "<div class='v-list-item__icon'><i aria-hidden='true' class='v-icon notranslate material-icons theme--light light-blue--text text--lighten-3' style='font-size:200px;'>cloud_upload</i></div>",
        createImageThumbnails: false,
        autoProcessQueue: false,
        parallelUploads: 5,
        timeout: 3600 * 1000,
        maxFilesize: null,
      },
      filePolicies: {},
    };
  },
  methods: {
    ...mapActions([
      // 'updateProject',
      'setNewImageAmount',
      'setNotification',
      'setUploading',
      'getProject',
      'createFolder',
      'createImages',
      'addFolderProject',
    ]),
    updateShow(value) {
      this.showMessage = value;
    },
    // eslint-disable-next-line no-unused-vars
    totalUploadProgress(totaluploadprogress, totalBytes, totalBytesSent) {
      if (totaluploadprogress) this.totalProgress = totaluploadprogress;
    },
    // eslint-disable-next-line no-unused-vars
    onUploadProgress(file, progress, bytesSent) {
      this.uploadProgress = Number.parseFloat(progress).toFixed(2);
    },
    // eslint-disable-next-line no-unused-vars
    async beforeSend(file, xhr, formData) {
      this.preUploadProcess(file, formData);
    },
    preUploadProcess(file, formData) {
      const params = this.filePolicies[file.name].fields;
      formData.append('Content-Type', file.type);
      Object.keys(params).forEach((p) => {
        formData.append(p, params[p]);
      });
    },
    async handleAddedFiles() {
      this.$refs.directDropzone.setOption('url', this.uploadUrl);
      this.$refs.directDropzone.setOption('clickable', false);
      this.setUploading(true);
      await new Promise((r) => setTimeout(r, 1000));
      const dfiles = this.$refs.directDropzone.getAcceptedFiles();
      await this.handleUploadFiles(dfiles);
      this.$refs.directDropzone.processQueue();
      const elementsToModify = ['dz-image', 'dz-details', 'dz-progress', 'dz-success-mark'];
      elementsToModify.forEach((className) => this.noZIndex(className));
    },
    async onQueueComplete() {
      const uploadedFiles = this.$refs.directDropzone.getAcceptedFiles().filter((file) => file.status === 'success');
      await this.postUploadProcess(uploadedFiles);
      this.$refs.directDropzone.setOption('clickable', false);
    },
    noZIndex(className) {
      const elements = document.getElementsByClassName(className);
      elements.forEach((element) => element.setAttribute('style', 'z-index: 0'));
    },
    async handleUploadFiles(uploadFiles) {
      const files = [];
      uploadFiles.forEach((file) => {
        files.push({
          contentType: file.type,
          fileName: `${this.folderPath}/${file.name}`,
        });
      });
      const policies = await axios.post(`${baseURL}/api/upload/signed_url`, {
        bucketName: this.currentCompany.bucket,
        company: this.currentCompany.cid,
        project: this.currentProject.pid,
        uploadPath: 'Miscellaneous',
        files,
      });
      this.filePolicies = { ...this.filePolicies, ...policies.data };
    },
    async postUploadProcess(uploadedFiles, folderName = 'Miscellaneous') {
      this.indeterminate = true;
      this.uploadStatus = 'Processing files, please wait....';
      if (uploadedFiles.length === 0) {
        return;
      }
      const imageDocs = uploadedFiles.map(async (currentFile) => {
        const coordinates = await helpers.readFile(currentFile);
        const {
          lat, latRef, long, longRef,
        } = coordinates;

        const convertedCoordinatePoints = {};
        if (
          lat !== undefined
          || latRef !== undefined
          || long !== undefined
          || longRef !== undefined
        ) {
          convertedCoordinatePoints.lat = helpers.convertDMSToDD(
            lat[0],
            lat[1],
            lat[2],
            latRef,
          );

          convertedCoordinatePoints.long = helpers.convertDMSToDD(
            long[0],
            long[1],
            long[2],
            longRef,
          );
        }
        const location = (Object.keys(convertedCoordinatePoints).length > 0)
          ? helpers.getGeoCoordinates(convertedCoordinatePoints) : {};
        return {
          filename: currentFile.name,
          location,
          createdAt: helpers.getStringDate(),
          date: helpers.getStringDateTime(),
          caption: '',
          notes: [],
          pid: this.currentProject.pid,
          cid: this.currentCompany.cid,
          uid: this.currentUser.uid,
          bucket: this.currentCompany.bucket,
          url: `${this.uploadUrl}${this.folderPath}/${currentFile.name}`,
        };
      });

      Promise.all(imageDocs)
        .then(async (docs) => {
          const imageDocList = docs.map((doc) => {
            const currentDoc = doc;
            currentDoc.processedImage = null;
            return currentDoc;
          });

          const imageObjects = imageDocList.map((img) => ({
            filename: img.filename,
            companyId: img.cid,
            project_id: img.pid,
            originalImageUrl: img.url,
            userId: img.uid,
            location: [img.location.latitude, img.location.longitude],
            date: img.date,
            isDeleted: false,
            caption: '',
            notes: [],
            bucket: img.bucket,
            process_tracking: [],
            folder: folderName,
          }));

          const initialAmount = this.allImages.length;
          const response = await this.createImages({ imageObjects });

          if (response.status === 201) {
            const { data } = response;
            this.setNewImageAmount(data.length + initialAmount);
            this.setUploading(false);
            this.showOverlay = false;
            this.setNotification({
              success: true,
              message: `${data.length} images have been uploaded to the ${this.currentProject.name} project`,
            });
          }
        }).catch((err) => {
          console.error(err);
        });
    },
    async onFolderSelected(event) {
      try {
        const maxUploadSize = 2147483648;
        // convert FileList object to array,and filter out none-image files
        const files = Array.from(event.target.files)
          .filter((file) => file.type.indexOf('image/') === 0);
        // eslint-disable-next-line max-len
        const totalFileSize = files.reduce((totalSize, currentFile) => totalSize + currentFile.size, 0);
        // reset folder input value
        document.getElementById('folderUploadInput').value = null;
        if (totalFileSize > maxUploadSize) {
          this.showMessage = true;
          return;
        }
        this.setUploading(true);
        const folderName = files[0].webkitRelativePath.split('/')[0];
        await this.handleUploadFiles(files);
        // if folder does not exist, call create folder
        if (!this.allFolders || !this.allFolders.find((folder) => folder.path === folderName)) {
          const folder = {
            company: this.cid,
            image_count: files.length,
            path: folderName,
            project: this.pid,
            date: Date.now(),
          };
          const { data } = await this.createFolder(folder);
          await this.addFolderProject(data);
        }
        // upload files
        const processStatus = [];
        const updateStatus = setInterval(() => {
          const percentage = processStatus.reduce((a, b) => a + b) / processStatus.length;
          this.processedPercent = Math.round(percentage);
        }, 800);
        this.indeterminate = false;
        this.uploadStatus = 'Uploading, please wait...';
        this.showOverlay = true;
        const uploadProcess = files.map((file, index) => {
          const data = new FormData();
          this.preUploadProcess(file, data);
          data.append('file', file);
          const axiosForUpload = axios.create({});
          delete axiosForUpload.defaults.headers.common.Authorization;
          const aixosConfig = {
            onUploadProgress: (progressEvent) => {
              processStatus[index] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            },
          };
          return axiosForUpload.post(this.uploadUrl, data, aixosConfig);
        });
        await Promise.all(uploadProcess);
        clearInterval(updateStatus);
        await this.postUploadProcess(files, folderName);
      } catch (e) {
        this.setUploading(false);
        alert('upload failed');
        console.error(e);
      }
    },
    selectFolder() {
      document.getElementById('folderUploadInput').click();
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'currentProject', 'allImages', 'uploadingImages', 'currentCompany', 'companyConfig', 'allFolders']),
    folderPath() {
      return `original/${this.currentProject.pid}`;
    },
    uploadUrl() {
      return `https://storage.googleapis.com/${this.currentCompany.bucket}/`;
    },
  },
  created() {
    const { pid } = this;
    if (Object.keys(this.currentProject).length === 0) this.getProject(pid);
  },
};
</script>

<style scoped>
.display-none{
  display: none;
}
</style>
