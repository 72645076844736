/* eslint-disable no-param-reassign */
import ExcelJS from 'exceljs';
import hasLocation from '@utils/location';
import { saveAs } from 'file-saver';
import moment from 'moment';
import headers from '@utils/excel/headers';
import helpers from '@utils/excel/helpers';
import methodsHelpers from '@methods/helpers';

class Excel {
  constructor(project, client) {
    this.project = project;
    this.workbook = null;
    this.rows = [];
    this.headers = (client.includes('Newfoundland')) ? headers.nf : headers.general;
    this.client = client;
    this.emptyKeys = [
      'wr_site_id',
      'work_request',
      'entity',
      'requested_on',
      'requested_by',
      'work_type',
      'priority',
      'finish',
      'est_cost_amt',
      'est_duration_uom',
      'planner',
      'supervisor',
      'approval_cmt_txt',
      'approval_status',
      'approved_date',
      'approved_employee_sl',
      'dist_deficiencies',
      'dl_work_classificiation',
      'keywords',
      'attachment_title',
      'structure_type',
      'conductor_type',
      'truck_accessable',
      'flagman_required',
      'outage_type',
      'risk',
      'latitude',
      'longitude',
      'wr_source',
      'capital_budget_year',
      'location',
      'start_address',
      'gis_object_id',
      'end_latitude',
      'end_longitude',
      'city',
      'state',
      'zip_code',
      'contact_person',
      'contact_email',
      'contact_phone',
      'start_elevation',
      'end_elevation',
      'dp_processed_flag',
      'dp_processed_status',
      'dp_error_text',
    ];
  }

  createWorkbook(user) {
    this.workbook = new ExcelJS.Workbook();
    this.workbook.creator = user;
    this.workbook.created = new Date();
    this.workbook.modified = new Date();
  }

  getWorksheet(name) {
    return this.workbook.getWorksheet(name);
  }

  addWorksheet(name, options) {
    const sheet = (options) ? this.workbook.addWorksheet(name, options)
      : this.workbook.addWorksheet(name);
    return sheet;
  }

  freezeViews() {
    this.workbook.getWorksheet('sheet').views = [
      {
        state: 'frozen',
        xSplit: 2,
        ySplit: 1,
        activeCell: 'A1',
      },
    ];
  }

  createRows(projectName, folders, images, prefix, subprefix) {
    const isNf = this.client.includes('Newfoundland');

    if (isNf) this.generateNFTemplate(folders, images, projectName, prefix, subprefix);
    else {
      this.generalTemplate(projectName, folders, images);
    }
  }

  generateRow(image, projectName) {
    const isProcessed = image.process_tracking.length > 0;

    const row = {};
    const { folder } = image;
    row.project = projectName;
    row.site = folder.path;
    row.filename = image.filename;
    row.structure = ''; // Structure number should be blank if there is none
    row.priority = folder.overall_severity ? folder.overall_severity : 'N/A';
    row.work_order = folder.work_order ? 'Yes' : 'No';
    row.work_order_number = '';
    row.latitude = hasLocation(image) ? helpers.getStartLatitude(image) : 'N/A';
    row.longitude = hasLocation(image) ? helpers.getStartLongitude(image) : 'N/A';
    row.structure_notes = folder.note ? folder.note : '';

    // Get list of images within the current folder
    row.description = helpers.getDescription(image);
    row.image_caption = image.caption;
    row.fault = helpers.getTitle(image);
    row.date_inspected = helpers.getDateInspected(image);
    row.date_processed = helpers.getDateProcessed(image);

    // For every image, extract the severity and filename
    row.severity = isProcessed ? image.process_tracking.slice(-1)[0].severity : 'N/A';
    row.notes = isProcessed ? image.process_tracking.slice(-1)[0].notes : '';
    row.images = image.originalImageUrl;
    row.attachment = isProcessed ? image.processedImageUrl : 'N/A';

    this.rows.push(row);
  }

  generalTemplate(projectName, folders, images) {
    this.rows = [];

    if (folders.length > 0) {
      folders.forEach((folder) => {
        const folderImages = methodsHelpers.getImagesByFolder(images, folder);
        folderImages.forEach((image) => {
          image.folder = folder;
          this.generateRow(image, projectName);
        });
      });
    } else {
      images.forEach((image) => {
        this.generateRow(image, projectName);
      });
    }

    this.workbook.getWorksheet('sheet').addRows(this.rows);
  }

  generateNFTemplate(folders, images, project, prefix, subprefix) {
    this.rows = [];
    const row = {};
    this.emptyKeys.forEach((key) => {
      row[key] = '';
    });
    folders.forEach((folder) => {
      const folderImages = methodsHelpers.getImagesByFolder(images, folder);

      folderImages.forEach((image) => {
        row.requested_by = 'PowerAI';
        if (folder.path) row.pole_number = folder.path;
        if (folder.note) row.note = folder.note;
        row.title = helpers.getTitle(image);
        row.date_inspected = helpers.getDateInspected(image);
        row.description = helpers.getDescription(image);
        row.image_caption = image.caption;
        row.priority = folder.overall_severity ? folder.overall_severity : '';
        row.start = helpers.getDate(image);
        row.attachment = helpers.getAttachment(folder, image, project, prefix, subprefix);
        row.start_longitude = hasLocation(folder) ? helpers.getStartLongitude(folder) : '';
        row.start_latitude = hasLocation(folder) ? helpers.getStartLatitude(folder) : '';

        this.rows.push(row);
      });

      this.rows.push(row);
      this.workbook.getWorksheet('sheet').addRow(row);
    });
  }

  setColumns() {
    this.workbook.getWorksheet('sheet').columns = this.headers;
  }

  styleHeaders() {
    this.workbook.getWorksheet('sheet').getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'A5D6A7' },
    };
  }

  textWrap() {
    this.workbook.getWorksheet('sheet').columns.forEach((column) => {
      const currentColumn = column;
      let maxLength = 0;
      // The `includeEmpty` parameter allows us to iterate through empty cells
      // as well.
      currentColumn.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      currentColumn.width = Math.max(10, maxLength);
    });
  }

  getFilename() {
    return `${this.project}-${moment().format('MMMM-DD-YYYY')}`;
  }

  write(filename, type) {
    return new Promise((resolve, reject) => {
      this.workbook.xlsx.writeBuffer()
        .then((buffer) => {
          const fileExt = (type === 'xlsx') ? 'xlsx' : 'csv';
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, `${filename}.${fileExt}`);
          resolve();
        })
        .catch((err) => reject(err));
    });
  }
}

export default Excel;
