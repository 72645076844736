<template>
  <div id="first-reviewer-form">
    <v-select
      outlined
      dense
      :items="reviewers"
      clearable
      item-text="name"
      return-object
      label="Select a first reviewer"
      v-model="reviewer"
      @click:clear="setFirstReviewerCleared"
      @change="reviewerChange"
    ></v-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import userHelpers from '@methods/get-users.js';

export default {
  name: 'FirstReviewerForm',
  props: ['firstReviewer'],
  data: () => ({
    reviewers: [],
    reviewer: {
      assigner_uid: '',
      email: '',
      name: '',
      phone: '',
      uid: '',
    },
    updated: {
      assigner_uid: '',
      email: '',
      name: '',
      phone: '',
      uid: '',
    },
    reviewerFields: ['assigner_uid', 'email', 'name', 'phone', 'uid'],
  }),
  methods: {
    ...mapActions(['setFirstReviewer', 'clearFirstReviewerForm']),
    initReviewer() {
      const firstReviewer = cloneDeep(this.firstReviewer);

      if (Object.keys(firstReviewer).length > 0) {
        this.reviewerFields.forEach((field) => {
          this.reviewer[field] = firstReviewer[field];
          this.updated[field] = firstReviewer[field];
        });
      }
    },
    setFirstReviewerCleared() {
      this.clearFirstReviewerForm(true);
    },
    firstReviewerCleared() {
      this.clearFirstReviewerForm(true);
    },
    reviewerChange() {
      if (this.reviewer) {
        // Only modify these changes: assigner_uid, email, name, phone, uid
        const reviewerFields = ['assigner_uid', 'email', 'name', 'phone', 'uid'];
        reviewerFields.forEach((field) => {
          this.updated[field] = this.reviewer[field];
        });
      } else {
        this.reviewer = {
          assigner_uid: '',
          email: '',
          name: '',
          phone: '',
          uid: '',
        };

        this.updated = cloneDeep(this.reviewer);
      }

      this.setFirstReviewer(this.updated);
    },
  },
  computed: {
    ...mapGetters(['currentCompany', 'currentUser']),
    showReviewedField() {
      if (Object.keys(this.firstReviewer).length > 0) {
        return this.currentUser.uid === this.firstReviewer.uid;
      }
      return false;
    },
  },
  mounted() {
    this.initReviewer();
    this.setFirstReviewer(this.updated);
  },
  async created() {
    this.reviewers = await userHelpers
      .getFirstReviewer(this.currentCompany.employees, this.currentUser.uid);
  },
};
</script>
