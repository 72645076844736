<template>
  <v-container fluid id="image-location">
    <div id="location-display" v-if="!editingImage">
      <span class="text-caption">
        {{ toDecimalPlaces(coordinate, 3) }} {{ direction(coordinate) }}
      </span>
    </div>
    <div id="location-editor" v-else>
      <v-text-field
        v-model="gps"
        outlined
        dense
        :label="(coordinateType === 'latitude') ? 'Latitude' : 'Longitude'"
        @input="getCoordinateValue"
      >
      </v-text-field>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import helpers from '@methods/helpers';

export default {
  name: 'ImageLocation',
  props: ['coordinateType', 'coordinate'],
  data: () => ({
    coordinatePoint: 0,
    gps: '',
    edit: false,
  }),
  methods: {
    ...mapActions(['updateImage', 'setNotification', 'setModifiedImage']),
    toDecimalPlaces: (val, n) => helpers.fixDecimalPlaces(val, n),
    getNiceFilename: (filename) => ((filename.includes('+')) ? filename.split('+')[1] : filename),
    direction: (coordinate) => {
      const degreeIndex = coordinate.indexOf('°');
      const degrees = coordinate.slice(degreeIndex, coordinate.length);

      return degrees;
    },
    getCoordinateValue() {
      this.setModifiedImage({ type: this.coordinateType, value: parseFloat(this.gps) });
    },
  },
  computed: {
    ...mapGetters(['currentImage', 'editingImage']),
  },
  mounted() {
    this.coordinatePoint = (typeof this.coordinate === 'string') ? parseFloat(this.coordinate).toFixed(2) : this.coordinatePoint.toFixed(2);
    this.gps = (this.coordinateType === 'latitude') ? this.currentImage.location[0] : this.currentImage.location[1];
    this.setModifiedImage({ type: this.coordinateType, value: this.gps });
  },
};
</script>
