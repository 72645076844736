<template>
  <v-container fluid>
    <Loading v-if="loadingSubscriptions || loadingImages || loadingFolders || loadingLabels"/>
    <v-row v-else>
      <v-col cols="12" v-if="!hasProcessedImages">
        <NoProcessedImages :pid="pid" :cid="currentProject.cid" tab="ai"/>
      </v-col>
      <v-col cols="12" v-else>
        <ProcessedImagesContent :filename="filename"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { find } from 'lodash';
import { objectHasKey } from '@methods/helpers';
import Loading from '@components/common/Loading.vue';
import NoProcessedImages from '@views/image_tabs/NoProcessedImages.vue';
import ProcessedImagesContent from '@views/image_tabs/ProcessedImagesContent.vue';

export default {
  name: 'ProcessedImages',
  props: ['pid', 'filename'],
  data: () => ({
    initialAmount: 0,
  }),
  components: {
    NoProcessedImages,
    ProcessedImagesContent,
    Loading,
  },
  computed: {
    ...mapGetters([
      'currentProject',
      'loadingImages',
      'allImages',
      'currentCompany',
      'loadingSubscriptions',
      'currentCustomer',
      'currentSubscription',
      'imagesBeenProcessed',
      'loadingFolders',
      'loadingLabels',
    ]),
    hasService() {
      const { services } = this.currentCompany;

      return objectHasKey(services, 'gold') || objectHasKey(services, 'silver') || objectHasKey(services, 'bronze');
    },
    hasProcessedImages() {
      return this.allImages.filter((image) => image.processedImageUrl).length > 0;
    },
  },
  watch: {
    allImages(newList) {
      const processedImages = newList.filter((image) => image.processedImageUrl);
      if (processedImages.length < 1) this.$router.push(`/project/${this.pid}/images/ai/no-processed-images/processed`);
    },
  },
  methods: {
    ...mapActions(['loadPaidProducts', 'getSubscriptionById']),
  },
  async created() {
    const canAccess = await this.loadPaidProducts({
      customerId: this.currentCompany.customerId,
      product: 'Image Processing',
    });

    if (!canAccess) this.$router.push(`/project/${this.pid}/images/ai/no-access`);

    const { subscriptions } = this.currentCustomer;
    const processPlan = find(subscriptions.data,
      (subscription) => subscription.plan.product === 'prod_IUrhyebICPjpwU');

    await this.getSubscriptionById(processPlan.id);
  },
};
</script>
