<template>
  <v-card tile>
    <v-navigation-drawer
      v-model="drawer"
      clipped
      dark
      width="224"
      app
      :permanent="(isMobile) ? false : drawerShown"
      :bottom="isMobile"
      @input="drawerChange"
    >
      <v-list dense nav class="py-0">
        <v-list-item two-line class="px-0">
          <v-list-item-avatar color="primary">
            <span class="white--text headline">{{initials}}</span>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{currentUser.name}}
            </v-list-item-title>
            <v-list-item-subtitle>{{currentCompany.companyName}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <div v-for="item in sidebarLinks" :key="item.title">
          <v-list-item
            v-if="!Object.keys(item).includes('children')"
            :to="item.path"
            link
            class="sidebar-tabs"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="side-title">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <ListGroupItem v-else :icon="item.icon" :title="item.title" :children="item.children" />
        </div>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import links from '@components/layouts/links';
import ListGroupItem from '@components/common/ListGroupItem.vue';

export default {
  name: 'NewSideBar',
  props: ['drawerShown'],
  components: {
    ListGroupItem,
  },
  data: () => ({
    drawer: false,
    tabs: links.side,
  }),
  computed: {
    ...mapGetters(['currentUser', 'currentCompany', 'small', 'extraSmall', 'isMobile']),
    initials() {
      const { name } = this.currentUser;
      const nameSplit = name.split(' ');

      return `${nameSplit[0][0]}${nameSplit[1][0]}`;
    },
    isMobile() {
      return this.extraSmall || this.small;
    },
    sidebarLinks() {
      const { side } = links;
      // Find indices of the company and admin tab
      // const companyIndex = side.findIndex((tab) => tab.title === 'Company');
      // const adminIndex = side.findIndex((tab) => tab.title === 'Admin');

      // If the current user is the instance admin, then pass in
      // a dynamically created router path
      // Otherwise if companyIndex isn't -1 (not found), then remove it from array
      if (this.currentUser.adminStatus) side[3].path = `/company/${this.currentUser.cid}/employees`;
      else side.splice(3, 1);

      // Checks if the user is a buzzAdmin
      const isBuzzAdmin = Object.keys(this.currentUser).includes('buzzAdmin') && this.currentUser.buzzAdmin;

      // If user isn't a buzzAdmin, remove the admin tab from the array
      if (!isBuzzAdmin) side.splice(5, 1);

      // If the browser is on mobile device, concatenate the auth links into the side bar for it
      // to appear as a bottom bar
      const tabs = (this.isMobile) ? side.concat(links.auth) : side;

      return tabs;
    },
  },
  watch: {
    drawerShown(isDrawer) { this.drawer = isDrawer; },
  },
  methods: {
    drawerChange(tog) {
      this.$emit('drawerChange', tog);
    },
  },
};
</script>
