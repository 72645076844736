import axios from 'axios';
import { baseURL } from '@utils/endpoint';

// http://localhost:5000/api/customers

const setNotifications = (commit, notification) => {
  commit('notification_customer', notification);
  setTimeout(() => {
    commit('notification_customer', { success: false, message: '' });
  }, 3000);
};

const state = {
  customer: {},
  defaultPayment: {},
  customerSubscriptions: [],
  customerSubscription: {},
  loading: false,
  loadingPay: false,
  creating: false,
  notifications: {
    success: false,
    message: '',
  },
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  currentCustomer: (state) => state.customer,
  creatingCustomer: (state) => state.creating,
  customerDefaultPayment: (state) => state.defaultPayment,
  currentCustomerSubscription: (state) => state.customerSubscription,
  allCustomerSubscriptions: (state) => state.customerSubscriptions,
  loadingCustomer: (state) => state.loading,
  loadingCustomerPay: (state) => state.loadingPay,
  customerNotification: (state) => state.notifications,
};

const actions = {
  setLoadingCustomer({ commit }) {
    commit('load_customer');
  },
  setUpdatedDefaultPayment({ commit }, defaultPay) {
    commit('set_default_payment', defaultPay);
  },
  async getCustomer({ commit }, customerId) {
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}/api/customers/${customerId}`)
        .then((res) => {
          commit('fetch_customer', res.data);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  async getCustomerDefaultPayment({ commit }, paymentMethodId) {
    commit('load_default_payment');

    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}/api/payments/${paymentMethodId}`)
        .then((res) => {
          commit('fetch_customer_default_payment', res.data);
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  async updateCustomer({ commit }, payload) {
    const { data, customerId } = payload;

    return new Promise((resolve, reject) => {
      axios.put(`${baseURL}/api/customers/${customerId}`, data)
        .then((res) => {
          commit('update_customer', res.data);
          if (Object.keys(data).includes('invoice_settings')) setNotifications(commit, { success: true, message: 'Your default payment method has been changed' });
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  createCustomer({ commit }, uid) {
    commit('creating_customer', true);
    return new Promise((resolve, reject) => {
      axios.post(`${baseURL}/api/customers/${uid}`)
        .then((res) => {
          commit('creating_customer', false);
          resolve(res);
        })
        .catch((err) => {
          commit('creating_customer', false);
          reject(err);
        });
    });
  },
};

const mutations = {
  load_customer: (state) => {
    state.loading = true;
  },
  load_default_payment: (state) => {
    state.loadingPay = true;
  },
  set_default_payment: (state, defaultPay) => {
    state.defaultPayment = defaultPay;
  },
  creating_customer: (state, creating) => {
    state.creating = creating;
  },
  fetch_customer: (state, customer) => {
    state.customer = customer;
    state.loading = false;
  },
  fetch_customer_default_payment: (state, payment) => {
    state.defaultPayment = payment;
    state.loadingPay = false;
  },
  update_customer: (state, newCustomer) => {
    state.customer = newCustomer;
  },
  notification_customer: (state, notification) => {
    state.notifications = notification;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
