var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.allProjects,"items-per-page":10,"search":_vm.currentProjectSearch,"no-results-text":"Unable to find any projects with that name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Projects")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("add")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('CreateProjectForm',{attrs:{"cid":_vm.currentCompany.cid,"teams":_vm.allTeams},on:{"closeDialog":_vm.close}})],1),_c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',{staticClass:"delete-project-confirm"},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Are you sure you want to delete the project with name: "+_vm._s(_vm.project.name)+"? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.deleteProjectConfirm}},[_vm._v("OK")])],1)],1)],1)],1)]},proxy:true},{key:"item.teams",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.getTeams(item)),function(team,index){return _c('v-chip',{key:index,staticClass:"ma-2",attrs:{"small":"","color":"secondary"}},[_vm._v(" "+_vm._s(team)+" ")])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"view-project-btn",attrs:{"icon":"","color":"primary","to":("/project/" + (item.pid))}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("visibility")])],1)]}}],null,true)},[_vm._v(" View Project ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"delete-project-btn",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.onDeleteProject(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" Delete Project ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }