import axios from 'axios';
import { baseURL } from '@utils/endpoint';

export default {
  get: (id) => new Promise((resolve, reject) => {
    axios.get(`${baseURL}/api/projects/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  update: (pid, updates) => axios.put(`${baseURL}/api/projects/${pid}`, updates),
  getByCompany: (id) => new Promise((resolve, reject) => {
    axios.get(`${baseURL}/api/projects/company/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
};
