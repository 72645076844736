var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"pt-1",attrs:{"loading":_vm.loadingImages || _vm.loadingFolders || _vm.loadingLabels,"loading-height":"6","height":"100%"}},[_c('v-fade-transition',[_c('DisplayImage',{staticClass:"pa-4",attrs:{"image":_vm.currentImage,"sliderIndex":_vm.currentIndex,"imageType":_vm.type}})],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{attrs:{"align":"center","justify":"center","id":"original-image-utils"}},[_c('v-btn-toggle',{attrs:{"value":_vm.toolbarActiveButtons}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","id":"select-all-btn","disabled":_vm.processingImage},on:{"click":_vm.selectAll}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("select_all")])],1)]}}])},[_c('span',[_vm._v("Select All")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","id":"delete-image-btn","disabled":_vm.processingImage || _vm.disableDeleteProcess},on:{"click":_vm.confirmDelete}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}])},[_c('span',[_vm._v("Delete Image")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","id":"download-btn","disabled":_vm.processingImage || _vm.disableDeleteProcess},on:{"click":_vm.downloadSelectedImage}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("download")])],1)]}}])},[_c('span',[_vm._v("Download Image")])]),(_vm.hasDuplicates)?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [(_vm.originalCarousel)?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","disabled":_vm.processingImage || _vm.disableDeleteProcess,"id":"confirm-process-img-btn"}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v("transform")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Process Image")])])]}}],null,false,3736755032),model:{value:(_vm.duplicateWarningDialog),callback:function ($$v) {_vm.duplicateWarningDialog=$$v},expression:"duplicateWarningDialog"}},[_c('DuplicateImageProcessWarning',{on:{"closeWarning":_vm.closeWarningDialog,"confirm":_vm.confirmProcessing}})],1):_vm._e(),(_vm.originalCarousel && !_vm.hasDuplicates)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:(_vm.hasDuplicates) ? 'no-display' : '',attrs:{"small":"","icon":"","disabled":_vm.processingImage || _vm.disableDeleteProcess,"id":"process-img-btn"},on:{"click":_vm.confirmProcessing}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("transform")])],1)]}}],null,false,366951810)},[_c('span',[_vm._v("Process Image")])]):_vm._e(),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [(_vm.originalCarousel)?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","disabled":!_vm.processingImage,"id":"process-cancel-img-btn"}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v("mdi-cancel")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Cancel Processing")])])]}}]),model:{value:(_vm.cancelProcessD),callback:function ($$v) {_vm.cancelProcessD=$$v},expression:"cancelProcessD"}},[_c('CancelProcessingWarning',{on:{"closeCancel":_vm.closeCancel,"confirmCancel":_vm.confirmCancel}})],1),_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({ref:"imgViewBtn",attrs:{"small":"","icon":"","id":"enlarge-img-btn","disabled":_vm.processingImage || _vm.disableZoomer}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v("visibility")])],1)]}}],null,true)},[_vm._v(" View Image ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ImageZoomer',{attrs:{"images":_vm.images,"currentIndex":_vm.currentIndex,"type":_vm.type},on:{"closeZoomer":_vm.onCloseDialog,"annotate":_vm.onAnnotateImage}})],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","persistent":"","id":"hitl-dialog","content-class":"human-in-the-loop-dialog"},on:{"click:outside":_vm.closeAnnotation},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({ref:"annotateBtn",attrs:{"small":"","id":"human-in-loop-btn","icon":"","to":_vm.annotatorPath}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v("brush")])],1)]}}],null,true)},[_vm._v(" Annotate ")])]}}]),model:{value:(_vm.annotateDialog),callback:function ($$v) {_vm.annotateDialog=$$v},expression:"annotateDialog"}},[_c('v-card',[_c('router-view',{on:{"closeDialog":_vm.closeAnnotation}})],1)],1),(_vm.companyHas('poleByFolders'))?_c('v-dialog',{attrs:{"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [(!_vm.originalCarousel)?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","disabled":!_vm.canTransfer,"id":"pole-switch-btn"}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v("mdi-folder-swap")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Change Pole")])])]}}],null,false,243220149),model:{value:(_vm.changePole),callback:function ($$v) {_vm.changePole=$$v},expression:"changePole"}},[_c('PoleChangeManager',{attrs:{"show":_vm.changePole},on:{"cancel":_vm.closePoleTransfer}})],1):_vm._e(),_c('FullScreenButton',{attrs:{"url":(_vm.type === 'original')
              ? _vm.currentImage.originalImageUrl
              : _vm.currentImage.processedImageUrl,"buttonType":"icon","size":"small"}})],1)],1)],1),_c('v-divider'),_c('ImagePicker',{attrs:{"images":_vm.images},on:{"imageTransition":_vm.getImageIndex}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Delete dialog")]),_c('v-card-text',[_vm._v("Confirm you want to delete the selected image(s)?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("No")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.onDeleteImages}},[_vm._v("Yes")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }