<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="employees"
      class="elevation-1"
      :loading="loadingEmployees"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>{{title}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <!-- <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                id="add-user"
                icon
                v-on="on"
              ><v-icon>group_add</v-icon></v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  dark
                  icon
                  @click="close"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                <v-form ref="createForm">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.firstName"
                        label="First Name"
                        outlined
                        :rules="[requiredRule]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.lastName"
                        label="Last Name"
                        outlined
                        :rules="[requiredRule]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email Address"
                        outlined
                        :rules="[requiredRule]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.phone"
                        @input="formatPhone"
                        label="Phone Number"
                        outlined
                        :rules="[requiredRule]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-select
                        label="Role"
                        :items="roles"
                        v-model="editedItem.adminStatus"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> -->
        </v-toolbar>
      </template>
      <template v-slot:[`item.adminStatus`]="{ item }">
        <v-chip
         :color="chipColorPicker(item.adminStatus)"
         x-small
         dark
        >{{ item.adminStatus}}</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          @click="confirmDelete(item)"
          class="mx-auto"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete dialog</v-card-title>
        <v-card-text>Are you sure you want to delete this user?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">No</v-btn>
          <v-btn color="green darken-1" text @click="deleteItem">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar :color=snackcolor v-model="snackbar" timeout="-1">
      {{ snackmessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EmployeesTable',
  props: ['title', 'employees', 'cid'],
  data: () => ({
    snackbar: false,
    snackcolor: '',
    snackmessage: '',
    dialog: false,
    roles: ['Admin', 'User'],
    headers: [
      {
        text: 'First Name',
        align: 'start',
        sortable: true,
        value: 'firstName',
      },
      {
        text: 'Last Name',
        sortable: true,
        value: 'lastName',
      },
      { text: 'Phone Number', value: 'phone' },
      { text: 'Email', value: 'email' },
      { text: 'Admin', value: 'adminStatus' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      adminStatus: false,
    },
    defaultItem: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      adminStatus: false,
    },
    requiredRule: (v) => !!v || 'Field is required',
    itemToDelete: null,
    deleteDialog: false,
  }),
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
  },
  methods: {
    ...mapActions(['createUser', 'sendNewUserEmail', 'deleteUser', 'deleteEmployee', 'addEmployee']),
    formatPhone() {
      const number = this.editedItem.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.editedItem.phone = !number[2] ? number[1] : `(${number[1]}) ${number[2]}${number[3] ? `-${number[3]}` : ''}`;
    },
    chipColorPicker(item) {
      return (item === 'Admin') ? 'primary' : 'blue-grey darkened-4';
    },
    editItem(item) {
      this.editedIndex = this.allEmployees.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    setSnack(color = '', message = '') {
      this.snackcolor = color;
      this.snackmessage = message;
      this.snackbar = true;
    },
    async deleteItem() {
      this.setSnack('info', 'Deleting user.');
      if (!this.itemToDelete) {
        this.deleteDialog = false;
        return;
      }
      try {
        const deleted = await this.deleteUser(this.itemToDelete);
        this.itemToDelete = null;
        if (deleted.status) {
          this.setSnack('success', 'User successfully deleted.');
          this.deleteEmployee(deleted.uid);
        }
      } catch (err) {
        this.setSnack('error', 'Failed to delete user.');
      }
      this.deleteDialog = false;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    save() {
      this.loading = true;
      const isFormValid = this.$refs.createForm.validate();
      if (!isFormValid) {
        return;
      }
      if (this.editedIndex > -1) {
        // Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        // The create functionality
        // const passwordOptions = {
        //   length: 6,
        //   numbers: true,
        // };
        this.setSnack('info', 'Creating new user.');
        const { firstName, lastName } = this.editedItem;
        const displayName = `${firstName} ${lastName}`;
        const newUserData = {
          displayName,
          email: this.editedItem.email,
          emailVerified: false,
          adminStatus: this.editedItem.adminStatus,
        };
        const companyId = this.currentCompany.cid;
        const { adminStatus } = this.currentUser;
        this.createUser({ newUserData, adminStatus, companyId })
          .then((newUser) => {
            const data = {
              credentials: {
                email: newUser.email,
                name: newUser.displayName,
                uid: newUser.uid,
                url: window.location.href,
              },
              adminStatus,
            };
            this.setSnack('success', 'User successfully created. Sending email.');
            const userToAppend = {
              firstName,
              lastName,
              phone: '',
              email: newUser.email,
              adminStatus: this.editedItem.adminStatus,
              teams: [],
              uid: newUser.uid,
            };
            this.addEmployee(userToAppend);
            this.sendNewUserEmail(data).then(() => {
              this.setSnack('success', 'Email sent.');
            }).catch(() => {
              this.setSnack('error', 'Failed to send email.');
            });
          });
      }
      this.close();
    },
  },
  computed: {
    ...mapGetters(['loadingEmployees', 'currentUser', 'currentCompany']),
    formTitle() {
      return this.editedIndex === -1 ? 'New User' : 'Edit User';
    },
  },
};
</script>
