import axios from 'axios';
import { baseURL } from '@utils/endpoint';

export default {
  get: (payload) => new Promise((resolve, reject) => {
    axios.get(`${baseURL}/api/image/${payload.filename}/project/${payload.pid}/company/${payload.cid}/${payload.date}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  getByCompany: (cid) => new Promise((resolve, reject) => {
    axios.get(`${baseURL}/api/image/${cid}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  getByProject: (cid, pid) => new Promise((resolve, reject) => {
    axios.get(`${baseURL}/api/image/company/${cid}/project/${pid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  }),
  getByFolder: (cid, pid, folder) => new Promise((resolve, reject) => {
    axios.get(`${baseURL}/api/image/company/${cid}/project/${pid}/folder/${folder}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  update: (image) => new Promise((resolve, reject) => {
    axios.put(`${baseURL}/api/image`, image)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  delete: (payload) => new Promise((resolve, reject) => {
    axios.delete(`${baseURL}/api/image/${payload.type}`, { data: payload.images })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),

  // TODO: Find a way to refactor this endpoint or see if we even need it
  getByProjects: (payload) => new Promise((resolve, reject) => {
    axios.post(`${baseURL}/api/image/images_from_projects/${payload.cid}`, payload.projects)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  getByProjectAndFolder: (cid, pid, folderPath) => new Promise((resolve, reject) => {
    axios.get(`${baseURL}/api/image/company/${cid}/project/${pid}/folder/${folderPath}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  getProcessedByProject: (payload) => new Promise((resolve, reject) => {
    axios.post(`${baseURL}/api/image/project/company/${payload.cid}/processed`, payload.projects)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  updateAiFeedback: (query, feedback) => new Promise((resolve, reject) => {
    axios.put(`${baseURL}/api/image/feedback`, { ai_feedback: feedback }, { params: query })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
};
