<template>
  <v-container fluid>
    <TableSkeletonLoader v-if="loadingProjects"/>
    <TeamsTable :teams="formatTeams(allTeams)" v-else/>
    <SnackBar
      :snackbar="teamsNotification.success"
      :text="teamsNotification.message"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TableSkeletonLoader from '@components/common/loaders/TableSkeletonLoader.vue';
import TeamsTable from '@components/company/TeamsTable.vue';
import SnackBar from '@components/common/SnackBar.vue';

export default {
  name: 'Teams',
  data: () => ({
    teams: [],
  }),
  components: {
    TableSkeletonLoader,
    TeamsTable,
    SnackBar,
  },
  methods: {
    ...mapActions(['getAllProjectsByCompanyId']),
    formatTeams(teams) {
      const teamsList = [...teams];
      const projects = [...this.allProjects];
      const employees = [...this.allEmployees];

      const companyTeams = teamsList.map((team) => {
        const currentTeam = { ...team };

        if (currentTeam.projects.length > 0) {
          const projectIds = currentTeam.projects.map((project) => project.id);
          currentTeam.projects = projects.filter((project) => projectIds.includes(project.pid));
        }

        if (currentTeam.members.length > 0) {
          const memberIds = currentTeam.members.map((member) => member.id);
          currentTeam.members = employees.filter((member) => memberIds.includes(member.uid));
        }

        return currentTeam;
      });
      return companyTeams;
    },
  },
  computed: {
    ...mapGetters(['allTeams', 'allEmployees', 'allProjects', 'currentCompany', 'loadingProjects', 'teamsNotification']),
  },
  mounted() {
  },
  created() {
    if (this.allProjects.length === 0) {
      const { cid } = this.currentCompany;
      this.getAllProjectsByCompanyId(cid);
    }
  },
};
</script>
